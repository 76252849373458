import { create } from "zustand";
import { combine } from "zustand/middleware";
import { UserDetails } from "../api/generated";
import { persist } from "./persist";

type UserType = "admin" | "user" | "doctor";

const defaultState: {
  userInfo?: UserDetails;
  userType?: UserType;
  doctorKey?: string;
} = {};

/**
 * Store manager for user authentication and profile
 */
export const useAuthState = create(
  persist(
    {
      key: "auth",
    },
    combine(defaultState, (set) => ({
      setUserInfo: (userInfo?: UserDetails) => set((state) => ({ ...state, userInfo })),
      setUserType: (userType: UserType | undefined) => set((state) => ({ ...state, userType })),
      setDoctorKey: (doctorKey: string | undefined) => set((state) => ({ ...state, doctorKey })),
      reset: () =>
        set({
          userInfo: undefined,
          userType: undefined,
          doctorKey: undefined,
        }),
    })),
  ),
);
