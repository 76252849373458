import { v4 as uuid } from "uuid";
import { ReactComponent as ArrowUnsorted } from "../../assets/icons/caret-arrow-unsorted.svg";
import { ReactComponent as ArrowSortedUp } from "../../assets/icons/arrow-sorted-up.svg";
import { ReactComponent as ArrowSortedDown } from "../../assets/icons/arrow-sorted-down.svg";
import { ReactComponent as SortAscending } from "../../assets/icons/sort-ascending.svg";
import { ReactComponent as SortDescending } from "../../assets/icons/sort-descending.svg";
import { TableHeadProps } from "./types";
import { DropdownAccordion } from "../Dropdown";
import { useTranslation } from "react-i18next";
import { SortDirection } from "@gruene-brise/data-access/api/generated";

const TableHead = <T,>({
  columns,
  headerClassName,
  handleSort,
  sortedColumn,
}: TableHeadProps<T>) => {
  const { t } = useTranslation();
  return (
    <thead className='capitalize'>
      <tr>
        {columns.map((column) => {
          const { minWidth, maxWidth } = column;
          const dropdownSortId = uuid();
          return (
            <th
              scope='col'
              className={`p-4 font-semibold text-primary border border-b-none border-gray-600 text-[13px] leading-[20px] ${
                headerClassName || ""
              } ${column.headerClassName || ""}
              ${column.sortable ? "cursor-pointer" : ""}`}
              style={{ minWidth, maxWidth }}
            >
              <div
                className={`flex items-center ${headerClassName ?? ""} ${
                  column.headerClassName || ""
                }`}
              >
                {column.sortable && column.sortField ? (
                  <DropdownAccordion
                    dropdownClassName='!rounded-[6px] !shadow-button_sm !top-[130%]'
                    menuDirection={column.moveRight ? "bottom-right" : "bottom-left"}
                    triggerButton={
                      <button
                        className={`h-full w-full flex items-center font-semibold text-inherit ${column.headerClassName}`}
                      >
                        {typeof column.header === "function" ? column.header() : column.header}
                        <span className='ml-2 inline'>
                          {sortedColumn.field === column.sortField &&
                            sortedColumn.direction === "asc" && (
                              <ArrowSortedUp
                                title='Arrow sorted up'
                                className='text-primary-grey w-[8px] h-[10px]'
                              />
                            )}
                          {sortedColumn.field === column.sortField &&
                            sortedColumn.direction === "desc" && (
                              <ArrowSortedDown
                                title='Arrow sorted down'
                                className='text-primary-grey w-[8px] h-[10px]'
                              />
                            )}
                          {sortedColumn.field !== column.sortField && (
                            <ArrowUnsorted
                              title='Unsorted arrow'
                              className='text-primary-grey w-[8px] h-[10px]'
                            />
                          )}
                        </span>
                      </button>
                    }
                    items={[
                      {
                        title: t("Sort by"),
                        content: (
                          <div className='flex flex-col'>
                            <button
                              onClick={() =>
                                handleSort?.(column.sortField as string, SortDirection.Asc)
                              }
                              className={`flex items-center space-x-2 font-semibold text-md px-2 py-3 border-b border-solid border-grey ${
                                sortedColumn.direction === "asc" ? "text-primary" : "text-grey"
                              }`}
                            >
                              <SortAscending className='text-inherit' />
                              <span className='text-inherit'>{t("Ascending")}</span>
                            </button>
                            <button
                              onClick={() =>
                                handleSort?.(column.sortField as string, SortDirection.Desc)
                              }
                              className={`flex items-center space-x-2 font-semibold text-xs px-2 py-3 ${
                                sortedColumn.direction === "desc" ? "text-primary" : "text-grey"
                              }`}
                            >
                              <SortDescending className='text-inherit' />
                              <span className='text-inherit'>{t("Descending")}</span>
                            </button>
                          </div>
                        ),

                        id: dropdownSortId,
                      },
                    ]}
                    titleClassName='!text-primary-50'
                    defaultActiveItem={dropdownSortId}
                  />
                ) : typeof column.header === "function" ? (
                  column.header()
                ) : (
                  column.header
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
