import { useMemo } from "react";
import { ReusableTableProps } from "./types";

export interface CollapsibleProps<T extends Record<string, any>>
  extends Pick<ReusableTableProps<T>, "rowClassName" | "onRowClick"> {
  numberOfColumns: number;
  isCollapsed?: boolean;
  collapsibleView: React.ReactNode;
}

function CollapsibleRow<T extends Record<string, any>>({
  numberOfColumns,
  isCollapsed,
  collapsibleView,
}: CollapsibleProps<T>) {
  const memoizedComponent = useMemo(() => {
    if (!isCollapsed) {
      return null;
    }

    return (
      <tr className={`${!isCollapsed ? "hidden" : ""}`}>
        <td colSpan={numberOfColumns}>{collapsibleView}</td>
      </tr>
    );
  }, [numberOfColumns, isCollapsed, collapsibleView]);

  return memoizedComponent;
}

export default CollapsibleRow;
