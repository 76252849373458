import {
  FilterComparator as FilterComparatorEnum,
  FilterOperator,
  PageInfo,
  SortDirection,
} from "./generated";

export type ComparatorValue = string;

export interface FilterResponse<T> {
  result?: T[];
  pageInfo: PageInfo;
}

export interface FilterComparator<T> {
  field: keyof T;
  comparator: FilterComparatorEnum;
  value: ComparatorValue;
}

export interface FilterExpression<T> {
  edges?: FilterExpression<T>[];
  operator?: FilterOperator;
  nodes?: FilterComparator<T>[];
}

export interface Sorting<T> {
  field: keyof T;
  direction: SortDirection;
}

export interface Paging {
  pageSize: number;
  cursor?: string;
}

export interface FilterRequest<T> {
  filter?: FilterExpression<T>;
  pagination: Paging;
  sort?: Sorting<T>[];
}

export class PagingUtils {
  public static all(): Paging {
    return {
      cursor: "0",
      pageSize: -1,
    };
  }

  public static build(cursor = "0", pageSize = 10): Paging {
    return {
      cursor,
      pageSize,
    };
  }
}

export class FilterComparatorBuilder<T> {
  constructor(
    public field: keyof T,
    public comparatorType: FilterComparatorEnum,
    public value: ComparatorValue,
  ) {}

  build(): FilterComparator<T> {
    return {
      field: this.field,
      comparator: this.comparatorType,
      value: this.value,
    };
  }
}

export class FilterExpressionBuilder<T> {
  public constructor(
    public comparators: FilterComparatorBuilder<T>[] | undefined,
    public expressions: FilterExpressionBuilder<T>[] | undefined,
    public operator: FilterOperator | undefined,
  ) {
    this.comparators = comparators;
    this.expressions = expressions;
    this.operator = operator;
  }

  public and(...expressions: FilterExpressionBuilder<T>[]): FilterExpressionBuilder<T> {
    return new FilterExpressionBuilder<T>(undefined, [...expressions, this], FilterOperator.And);
  }

  public copyFrom(ex: FilterExpressionBuilder<T>) {
    this.comparators = ex.comparators;
    this.expressions = ex.expressions;
    this.operator = ex.operator;
  }

  public andComparators(...comparators: FilterComparatorBuilder<T>[]): FilterExpressionBuilder<T> {
    return new FilterExpressionBuilder<T>(comparators, [this], FilterOperator.And);
  }

  build(): FilterExpression<T> {
    return {
      nodes: this.comparators?.map((c) => c?.build()),
      operator: this.operator,
      edges: this.expressions?.map((e) => e.build()),
    };
  }
}

export class FilterOperatorFactory {
  public static of<T>(...comparators: FilterComparatorBuilder<T>[]): FilterExpressionBuilder<T> {
    return new FilterExpressionBuilder(comparators, undefined, undefined);
  }

  public static and<T>(
    expressions?: FilterExpressionBuilder<T>[],
    ...comparators: FilterComparatorBuilder<T>[]
  ): FilterExpressionBuilder<T> {
    return new FilterExpressionBuilder(comparators, expressions, FilterOperator.And);
  }

  public static or<T>(...comparators: FilterComparatorBuilder<T>[]): FilterExpressionBuilder<T> {
    return new FilterExpressionBuilder(comparators, undefined, FilterOperator.Or);
  }
}

export class FilterComparatorFactory {
  public static isEqual<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Eq, value);
  }

  public static isNotEqual<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Ne, value);
  }

  public static isGreaterThan<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Gt, value);
  }

  public static isGreaterThanOrEqual<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Gte, value);
  }

  public static isLessThan<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Lt, value);
  }

  public static isLessThanOrEqual<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Lte, value);
  }

  public static isLike<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Like, value);
  }

  public static isNotLike<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Nlike, value);
  }

  public static isIn<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.In, value);
  }

  public static isNotIn<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Nin, value);
  }

  public static isNull<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Isnull, value);
  }

  public static isNotNull<TModel>(
    field: keyof TModel,
    value: ComparatorValue,
  ): FilterComparatorBuilder<TModel> {
    return new FilterComparatorBuilder(field, FilterComparatorEnum.Isnotnull, value);
  }
}
