import { create } from "zustand";
import { combine } from "zustand/middleware";
import { Pharmacy } from "../api/generated";
import { persist } from "./persist";

const defaultState: {
  pharmacy?: Pharmacy;
} = {};

/**
 * Store manager for user authentication and profile
 */
export const useUserPharmacyState = create(
  persist(
    {
      key: "userPharmacy",
    },
    combine(defaultState, (set) => ({
      setPharmacy: (pharmacy?: Pharmacy) => set((state) => ({ ...state, pharmacy })),
      reset: () => set((state) => ({ ...state, pharmacy: undefined })),
    })),
  ),
);
