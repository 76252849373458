/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import TableRow from "./TableRow";
import { ReusableTableColumnProps, ReusableTableGroupProps, ReusableTableProps } from "./types";
import { Button, Label } from "@gruene-brise/common-ui";
import { ReactComponent as CollapseDown } from "@gruene-brise/common-ui/assets/icons/collapse-down.svg";
import { ReactComponent as CollapseUp } from "@gruene-brise/common-ui/assets/icons/collapse-up.svg";
import { useGraphql } from "@gruene-brise/data-access/hooks/useGraphql";
import { useMutation } from "react-query";
import CollapsibleRow from "./CollapsibleRow";

/**
 * @description Displays a table with the given columns and data.
 */
function TableGroup<T extends Record<string, any>>({
  name,
  data,
  minDate,
  maxDate,
  columns,
  groupStatus,
  groupId,
  refetchGroupOrderList,
  rowClassName,
  onRowClick,
  shouldRowUpdate,
  specialRowIdentity,
  collapsibleView,
}: ReusableTableProps<T> & ReusableTableGroupProps<T>): JSX.Element {
  const { t } = useTranslation();
  const { processBulkESignature, cancelWorkflowRequest } = useGraphql();
  const processESignature = useMutation(() => {
    return processBulkESignature({
      input: { checkoutIds: data?.map((item) => item.id) ?? [] },
    });
  });

  const cancelWorkFlow = useMutation(() =>
    cancelWorkflowRequest({
      input: { userGroupId: groupId, cancellationReason: "canceled by user" },
    }),
  );

  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <tr>
        <td colSpan={columns.length} className='p-0'>
          <div className='flex flex-row items-center gap-10 pl-[15px] pr-6 bg-primary-alpha h-[70px] border-b-[1px] border-b-grey'>
            <div className='flex flex-row gap-2  items-center w-[133px]'>
              <div
                className='flex items-center justify-center'
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(!visible);
                }}
              >
                {visible ? <CollapseUp /> : <CollapseDown />}
              </div>
              <div className='text-grey font-bold'>{name}</div>
            </div>
            <div className='bg-white rounded-full flex items-center w-[150px] py-1 px-2 justify-center text-black'>{`${minDate} - ${maxDate}`}</div>
            <div className='bg-white rounded-full flex items-center w-[50px] py-1 px-2 justify-center text-black'>
              {data?.length}/64
            </div>
            <div className='grow'></div>
            <Label
              variant={"warning"}
              text={groupStatus}
              className='font-semibold w-[150px] md:auto whitespace-nowrap'
              size='sm'
            />
            {groupStatus === "started" && (
              <Button
                className='w-[150px] !py-[5px]'
                text='Cancel Signature'
                variant='outlined'
                brand='primary'
                onClick={async (e) => {
                  e.preventDefault();
                  const response = await cancelWorkFlow.mutateAsync();
                  if (response.cancelWorkflowRequest.success) {
                    refetchGroupOrderList?.();
                  }
                }}
                textClass='text-md font-bold'
                size='sm'
              />
            )}
            <Button
              className='w-[150px] !py-[5px]'
              text={groupStatus === "started" ? "Complete Signature" : "Signieren"}
              variant='contained'
              brand='primary'
              onClick={async (e) => {
                e.preventDefault();
                if (data?.length && data?.[0]?.workflowurl) {
                  window.open(data[0].workflowurl, "_blank");
                } else {
                  const responseData = await processESignature.mutateAsync();
                  if (responseData.processBulkESignature.checkout?.workflowurl) {
                    refetchGroupOrderList?.();
                    window.open(responseData.processBulkESignature.checkout?.workflowurl, "_blank");
                  }
                }
              }}
              textClass='text-md font-bold'
              size='sm'
            />
          </div>
        </td>
      </tr>
      {data &&
        data.map((val) => {
          const PreviewTable = collapsibleView;
          return (
            <>
              {visible && (
                <React.Fragment
                  key={specialRowIdentity ? val[specialRowIdentity] : val.id || uuidv4()}
                >
                  <TableRow
                    data={val}
                    columns={columns}
                    shouldRowUpdate={shouldRowUpdate?.(val)}
                    onRowClick={onRowClick}
                    rowClassName={rowClassName}
                  />

                  {val.tableUncollapsed && (
                    <CollapsibleRow
                      numberOfColumns={columns.length}
                      isCollapsed={val.tableUncollapsed}
                      collapsibleView={PreviewTable ? <PreviewTable data={val} /> : <></>}
                    />
                  )}
                </React.Fragment>
              )}
            </>
          );
        })}
    </React.Fragment>
  );
}

export default TableGroup;
