import { Address } from "../api/generated";

export const getConcatFirstLastNameFromAddress = (address: Address) => {
  let fullName = "";

  if (!address) return fullName;

  fullName = address?.metaFields?.find((i) => i.name === "full_name")?.value || "";

  if (fullName) return fullName;

  const firstName = address?.metaFields?.find((i) => i.name === "first_name")?.value || "";
  const lastName = address?.metaFields?.find((i) => i.name === "last_name")?.value || "";

  fullName = firstName.concat(" ", lastName);

  return fullName;
};

export const splitNameWithoutLosingValue = (name: string) => {
  let firstName = "";
  let lastName = "";

  if (!name) return { firstName, lastName };

  const userName = name?.split(/\s+/g);
  firstName =
    userName
      ?.slice(0, userName?.length - 1)
      ?.join(" ")
      ?.trim() || "";
  lastName = userName?.[userName?.length - 1]?.trim() || "";

  return { firstName, lastName };
};
