export enum Permissions {
  AUDIT_READ = "audit:read",

  CHECKOUT_READ_ALL = "checkout:read_all",
  CHECKOUT_CREATE = "checkout:create",
  CHECKOUT_EXPORT = "checkout:export",
  CHECKOUT_DELETE = "checkout:delete",
  CHECKOUT_READ_FROM_PHARMACY = "checkout:read_from_pharmacy",
  CHECKOUT_UPDATE_ALL = "checkout:update_all",
  CHECKOUT_UPDATE_FROM_PHARMACY = "checkout:update_from_pharmacy",

  CUSTOMER_READ_ALL = "customer:read_all",
  CUSTOMER_READ_FROM_PHARMACY = "customer:read_from_pharmacy",
  CUSTOMER_UPDATE_ALL = "customer:update_all",

  HUBSPOT_SYNC = "hubspot:sync",
  KPIS_READ = "kpis:read",

  LABELS_CREATE = "labels:create",
  LABELS_READ = "labels:read",
  LABELS_UPDATE = "labels:update",
  LABELS_DELETE = "labels:delete",

  METAFIELDS_READ = "metafields:read",

  NCM_READ_ALL = "ncm:read_all",
  NCM_UPDATE_ALL = "ncm:update_all",

  NOTIFICATION_READ = "notification:read",
  NOTIFICATION_READ_OWN = "notification:read_own",

  PHARMACY_CREATE = "pharmacy:create",
  PHARMACY_READ = "pharmacy:read",
  PHARMACY_READ_OWN = "pharmacy:read_own",
  PHARMACY_UPDATE = "pharmacy:update",
  PHARMACY_UPDATE_ALL = "pharmacy:update_all",
  PHARMACY_UPDATE_OWN = "pharmacy:update_own",

  PRODUCT_READ = "product:read",
  PRODUCT_CREATE = "product:create",
  PRODUCT_UPDATE = "product:update",
  PRODUCT_DELETE = "product:delete",
  PRODUCT_UPDATE_PHARMACY_PRODUCT_DETAILS = "product:update_pharmacy_product_details",

  TICKET_READ_FROM_PHARMACY = "ticket:read_from_pharmacy",
  TICKET_UPDATE_ALL = "ticket:update_all",
  TICKET_READ = "ticket:read_all",
  TICKET_UPDATE_FROM_PHARMACY = "ticket:update_from_pharmacy",

  ZUGANGSCODE_CREATE = "zugangsCode:create",
  ZUGANGSCODE_UPDATE = "zugangsCode:update",
  ZUGANGSCODE_READ = "zugangsCode:read",
}
