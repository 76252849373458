import { UserRole } from "libs/data-access/src/lib/api/generated";
import { useAuthState } from "libs/data-access/src/lib/state/useAuthState";
import { useRouter } from "next/router";
import UnauthorizationError from "./UnauthorizationError";

export * from "./type";

interface Props {
  role?: UserRole;
  permissions?: string[];
  redirectUrl?: string;
  shouldRenderErrorComponent?: boolean;
}

export const renderComponentWithPermission =
  ({ role, permissions, redirectUrl, shouldRenderErrorComponent = false }: Props) =>
  <T extends object>(WrappedComponent: React.ComponentType<T>) => {
    const Component: React.FC<T> = (props): JSX.Element | null => {
      return (
        <PermissionValidationComponent
          redirectUrl={redirectUrl}
          permissions={permissions}
          shouldRenderErrorComponent={shouldRenderErrorComponent}
          component={WrappedComponent}
          role={role}
          {...props}
        />
      );
    };
    return Component;
  };

const PermissionValidationComponent = <T extends object>({
  redirectUrl,
  permissions,
  shouldRenderErrorComponent,
  role,
  component: WrappedComponent,
  ...rest
}: Props & T & { component: React.ComponentType<T> }): JSX.Element | null => {
  const router = useRouter();
  const userInfo = useAuthState()?.userInfo;
  if (!userInfo) {
    if (redirectUrl && typeof window !== "undefined") {
      router.push(redirectUrl);
      return null;
    }
    return null;
  }
  if (permissions && !permissions.some((val) => userInfo?.permissions.includes(val))) {
    return shouldRenderErrorComponent ? <UnauthorizationError /> : null;
  }
  if (role && userInfo?.roles?.indexOf(role) === -1) {
    return shouldRenderErrorComponent ? <UnauthorizationError /> : null;
  }

  return <WrappedComponent {...(rest as T)} />;
};

export const PermissionValidation = ({
  permissions,
  role,
  shouldRenderErrorComponent,
  redirectUrl,
  children,
}: React.PropsWithChildren<Props>) => {
  const router = useRouter();
  const userInfo = useAuthState()?.userInfo;
  if (!userInfo) {
    if (redirectUrl && typeof window !== "undefined") {
      router.push(redirectUrl);
      return null;
    }
    return null;
  }
  if (permissions && !permissions.some((val) => userInfo?.permissions.includes(val))) {
    return shouldRenderErrorComponent ? <UnauthorizationError /> : null;
  }
  if (role && userInfo?.roles?.indexOf(role) === -1) {
    return shouldRenderErrorComponent ? <UnauthorizationError /> : null;
  }

  return <>{children}</>;
};

export const AdminAppGuard = ({ children }: React.PropsWithChildren) => {
  const userInfo = useAuthState()?.userInfo;
  if (!userInfo) return null;
  return <>{children}</>;
};
