import { toast } from "react-toastify";
import environment from "../config/environment";
import { DateFormatter } from "./date-formatter";
import { FilterExpression, Sorting } from "../api/filter";
import { useState } from "react";
import { FilterComparator } from "../api/generated";
import { format } from "date-fns";
type GenericDataType = { [key: string]: any };
type FormatType = { accessor: string; header: string }[];

export const convertArrayOfObjectsToCSV = <T extends GenericDataType>(
  data: T[],
  formats: FormatType,
) => {
  let result: string;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  formats = formats.filter((column) => column.accessor !== undefined);
  const keys = formats.map((column) => column.header);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach((item) => {
    let ctr = 0;
    formats.forEach((column) => {
      if (ctr > 0) result += columnDelimiter;
      const accessors = column.accessor.split(".");
      let value: T | string | undefined = undefined;
      accessors.forEach((key) => {
        value = value && typeof value === "object" ? value[key] : item[key];
      });
      if (value) {
        result += column.accessor.includes("date")
          ? `"${DateFormatter.format(value as string)}"`
          : `"${value}"`;
      }
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
};

export const downloadConvertedCSV = <T extends GenericDataType>(
  data: T[],
  formats: FormatType,
  filename?: string,
) => {
  if (!data || data.length === 0) return;
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(data, formats);
  if (csv == null) return;

  const formatFileName = filename ? `${filename}.csv` : "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", formatFileName);
  link.click();
};

export type ResourceType =
  | "pharmacies"
  | "products"
  | "order_product_aggregate"
  | "pharmacyproducts"
  | "checkouts"
  | "checkouts_datev"
  | "customers"
  | "dashboard"
  | "ncms"
  | "product_analytics";

function resourceNameMapping(resource: ResourceType) {
  switch (resource) {
    case "checkouts":
      return "orders";
    case "checkouts_datev":
      return "orders-datev";
  }

  return resource;
}

export type FilterType<T> = {
  filter: {
    pagination: {
      cursor: string;
      pageSize: number;
    };
    filter: FilterExpression<T>;
    sort: Sorting<T>[];
  };
};

export const exportResourceAsCSV = async <T>(resource: ResourceType, filter: T) => {
  try {
    const response = await fetch(`${environment.apiUrl}/exports/${resource}`, {
      body: JSON.stringify(filter),
      method: "POST",
      credentials: "include",
    });

    if (response.status !== 200) {
      throw await response.json();
    }
    const data = await response.blob();
    const anchorLink = document.createElement("a");
    anchorLink.href = window.URL.createObjectURL(data);
    const dateFormatted = format(new Date(), "dd-MM-yyyy-HHmm");
    anchorLink.download = `${resourceNameMapping(resource)}-${dateFormatted}.csv`;
    anchorLink.click();
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      toast.error("There was a SyntaxError");
    } else {
      toast.error(`Failed to download requested resource: ${resource}`);
    }
  }
};

export const useCSVExport = <T>(identityField = "id") => {
  const [ids, setIds] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const exportCSV = async (resource: ResourceType, filters: T) => {
    setIsLoading(true);

    try {
      if (ids && ids.length >= 1) {
        const filter: FilterType<any> = {
          filter: {
            pagination: {
              pageSize: ids.length,
              cursor: "",
            },
            sort: [],
            filter: {
              nodes: [
                {
                  field: identityField as keyof T,
                  value: ids.join(","),
                  comparator: FilterComparator.In,
                },
              ],
            },
          },
        };

        return await exportResourceAsCSV(resource, filter);
      }

      await exportResourceAsCSV(resource, filters);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { exportCSV, setIds, isExportLoading: isLoading, setIsLoading };
};
