import { create } from "zustand";
import { combine } from "zustand/middleware";
import { persist } from "./persist";

export type LanguageType = "de" | "en";

const defaultState: {
  sideBarIsMinimized: boolean;
  language: LanguageType;
  adminLanguage: LanguageType;
} = {
  sideBarIsMinimized: true,
  language: "de",
  adminLanguage: "de",
};

/**
 * Store manager for user authentication and profile
 */
export const useGlobalState = create(
  persist(
    {
      key: "global",
    },
    combine(defaultState, (set) => ({
      setLanguage: (language: LanguageType) => set((state) => ({ ...state, language })),
      setAdminLanguage: (language: LanguageType) =>
        set((state) => ({ ...state, adminLanguage: language })),
    })),
  ),
);
