import { GraphQLClient } from "graphql-request";
import { useRouter } from "next/router";
import * as API from "../api/generated";
import environment from "../config/environment";
import { useAuthState } from "../state/useAuthState";

/**
 *The useGraphqlClient function returns a new instance of the GraphQLClient class for making GraphQL requests to the specified API URL.
@returns A GraphQLClient instance with the specified API URL and headers set for JSON requests with credentials included.
 */
export function useGraphqlClient() {
  const { doctorKey } = useAuthState();
  const { push } = useRouter();

  return new GraphQLClient(`${environment.apiUrl}`, {
    fetch: (input: RequestInfo, info: RequestInit) => {
      //Since cannot convert formData to JSON, we use regex to get the operation name value
      const regex = /"operationName":"(.*?)"/;
      const match = info.body?.toString().match(regex);

      const x = new URL("/query" + `?query=${match?.[1]}`, input as string);

      const v = fetch(x as any, {
        ...info,
        headers: {
          Accept: "application/json",
          ...info.headers,
          ...(doctorKey && { "x-doccheck": doctorKey }),
        },
        credentials: "include",
      });

      return v;
    },
  });
}

/**

The useGraphql function returns an SDK for making GraphQL requests using the useGraphqlClient function.
  @returns An SDK object with methods for making GraphQL requests.
*/
export function useGraphql() {
  const sdk = API.getSdk(useGraphqlClient(), async (action, name) => {
    console.debug("operation name", name);

    return action();
  });

  return {
    ...sdk,
  };
}
