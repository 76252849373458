interface ToggleProps {
  defaultValue: boolean;
  label?: string;
  disabledValue?: boolean;
  handleChange: () => void;
  labelClassName?: string;
}

const Toggle = ({
  defaultValue,
  label,
  labelClassName,
  disabledValue,
  handleChange,
}: ToggleProps) => {
  return (
    <label className='inline-flex items-center cursor-pointer'>
      <span
        className={`relative border-2 border-primary-25 inline-block w-[70px] h-[40px] rounded-full transition-colors duration-300 bg-${
          defaultValue ? "primary-50" : "white"
        }`}
      >
        <span
          className={`absolute w-[36px] h-[36px] bg-${
            defaultValue ? "white" : "primary-25"
          } rounded-full shadow transform ${defaultValue ? "translate-x-[30px]" : "translate-x-0"}`}
        ></span>
        <input
          type='checkbox'
          className='opacity-0 w-0 h-0'
          checked={defaultValue}
          disabled={disabledValue ? disabledValue : false}
          onChange={handleChange}
        />
      </span>
      <span className={`${labelClassName ? labelClassName : ""} ml-[50px] text-sm font-medium`}>
        {label}
      </span>
    </label>
  );
};
export default Toggle;
