import { ReactNode } from "react";

interface DrawerSubtitleProps {
  rightAddon?: ReactNode;
  text: string;
}

/**
 *
 * @param rightAddon {DrawerSubtitleProps['rightAddon']}
 * @param text {String}
 * @description A simple title utility for the drawer component
 * @returns {JSX.Element}
 */

export const DrawerSubtitle: React.FC<DrawerSubtitleProps> = ({
  rightAddon,
  text,
}): JSX.Element => {
  return (
    <div className='flex items-center justify-between bg-transparent py-3 border-b border-primary-10 border-solid mb-5'>
      <div className='flex-1 mr-4'>
        <h2 className='text-primary text-xl font-semibold capitalize'>{text}</h2>
      </div>
      {rightAddon && <div>{rightAddon}</div>}
    </div>
  );
};
