/* eslint-disable-next-line */

import { useEffect, useRef, useState } from "react";
import { ReactComponent as CaretDown } from "../../../assets/icons/caret-down.svg";
import { ReactComponent as CaretUp } from "@gruene-brise/common-ui/assets/icons/caret-up-simple.svg";
import { DropdownMenuDirection, positionStyle } from "../DropdownAccordion";
import { DropdownProps } from "../types";

export interface GeneralDropdownProps
  extends Pick<
    DropdownProps,
    | "placeholder"
    | "onMenuToggle"
    | "isLoading"
    | "disabled"
    | "containerClassName"
    | "menuClassName"
    | "menuPosition"
    | "removeButtonBorder"
    | "buttonClassName"
  > {
  menuDirection?: DropdownMenuDirection;
  placeholderIcon?: JSX.Element;
  placeholderColor?: string;
  indicator?: JSX.Element;
}

export const GeneralDropdown = ({
  disabled,
  isLoading,
  containerClassName,
  menuClassName,
  children,
  removeButtonBorder,
  buttonClassName,
  placeholderIcon,
  menuDirection = "bottom-right",
  menuPosition = "absolute",
  placeholder = "Choose...",
  placeholderColor = "text-grey",
  indicator,
  onMenuToggle,
}: React.PropsWithChildren<GeneralDropdownProps>): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);

  const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (dropdownBtnRef.current && dropdownBtnRef.current.contains(e.target as Node)) {
        return;
      }

      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(e.target as Node)) {
        setShowMenu(false);
        onMenuToggle?.(false);
      }
    };

    window.addEventListener("click", handler, true);
    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, []);

  const handleMenuToggle = () => {
    setShowMenu((prev) => !prev);
    onMenuToggle?.(!showMenu);
  };

  const getIcon = () => {
    if (showMenu) {
      if (placeholderIcon) {
        return placeholderIcon;
      } else {
        return <CaretUp title='Caret up' className='text-inherit' />;
      }
    } else {
      if (placeholderIcon) {
        return placeholderIcon;
      } else {
        return <CaretDown title='Caret down' className='text-inherit' />;
      }
    }
  };

  return (
    <div className={`${containerClassName ?? ""} rounded-md relative`}>
      <button
        data-testid='dropdown-btn'
        id='dropdown-btn'
        ref={dropdownBtnRef}
        disabled={disabled}
        onClick={handleMenuToggle}
        className={`py-3 px-4 flex justify-between items-center gap-2 active:bg-tertiary-25 bg-tertiary-10 w-full !border-[0.5px] border-solid ${
          !removeButtonBorder || showMenu ? "border-primary" : "border-transparent"
        } rounded-md shadow-button_sm transition text-grey text-base ${buttonClassName ?? ""}`}
      >
        <span className={` ${placeholderColor ?? "text-grey text-inherit"}`}>{placeholder}</span>
        {indicator && <span className='-ml-1'>{indicator}</span>}
        <div className=''>{getIcon()}</div>
      </button>

      <div
        ref={dropdownMenuRef}
        className={`${
          positionStyle[menuDirection]
        } bg-tertiary-10 mt-1.5 p-0 bg-inherit m-0 cursor-pointer w-auto overflow-auto transition-all ${menuPosition} rounded-md shadow-button_sm border-[0.5px] border-primary-50 border-solid z-10 ${
          !showMenu ? "hidden" : ""
        } ${menuClassName ?? ""}`}
      >
        {children}
      </div>
    </div>
  );
};

export default GeneralDropdown;
