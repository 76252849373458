import { useEffect } from "react";
import { ReusableTableColumnProps, ReusableTableProps } from "./types";

type TableDataProps<T> = Pick<ReusableTableProps<T>, "onRowClick"> &
  ReusableTableColumnProps<T> & { setRowClassNameState: (val: string) => void; data: T };

const TableData = <T,>({
  minWidth,
  maxWidth,
  dataClassName,
  rowClassName,
  data,
  cell,
  onRowClick,
  disableRowClick,
  setRowClassNameState,
}: TableDataProps<T>) => {
  useEffect(() => {
    if (rowClassName) {
      setRowClassNameState(rowClassName as string);
    }
  }, [rowClassName]);

  return (
    <td
      style={{
        minWidth,
        maxWidth,
      }}
      onClick={onRowClick && !disableRowClick ? () => onRowClick(data) : undefined}
      className={`px-2 py-4 gap-8 text-base leading-[14px] ${
        typeof dataClassName === "function" ? dataClassName(data) : dataClassName || ""
      }`}
    >
      <div>{cell(data)}</div>
    </td>
  );
};

export default TableData;
