import { isEmpty } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export const useMarkItems = <T extends { [key: string]: any }>(
  setIds?: Dispatch<SetStateAction<string[] | undefined>>,
  identityField = "id",
): UseMarkItemsReturnType<T> => {
  const [items, setItems] = useState<T[]>([]);
  const [all, setAll] = useState<boolean>(false);

  const addItem = useCallback((item: T | T[]) => {
    setItems((prev) => {
      if (Array.isArray(item)) {
        return [...prev, ...item];
      }
      return [...prev, item];
    });
  }, []);

  const removeItem = useCallback((item: T | T[]) => {
    if (Array.isArray(item)) {
      setItems((prev) => {
        const itemsTobeDeleted = new Set(item);
        return prev.filter((item) => !itemsTobeDeleted.has(item));
      });
      return;
    }
    setItems((prev) => prev.filter((el) => el !== item));
  }, []);

  const toggleAll = useCallback((items?: T[]) => {
    setAll((prev) => !prev);
    if (items) {
      setItems((prev) => {
        if (prev.length !== 0) {
          if (items.every((val) => prev.find((i) => JSON.stringify(i) === JSON.stringify(val)))) {
            return [];
          }
          items.forEach((val) => prev.push(val));
          return Array.from(prev);
        }
        return items;
      });
    }
  }, []);

  useEffect(() => {
    if (setIds) {
      setIds(items.map((item) => item[identityField]));
    }
  }, [items.length]);

  const emptyItems = useCallback(() => {
    setItems([]);
  }, []);

  const hasItem = useCallback(
    (item: T, itemsArr = items) => {
      return !isEmpty(itemsArr.find((i) => JSON.stringify(i) === JSON.stringify(item)));
    },
    [items],
  );

  const toggleItem = useCallback(
    (item: T) => {
      setItems((prev) => {
        const hasItemAlready = hasItem(item, prev);
        if (hasItemAlready) {
          return prev.filter((i) => JSON.stringify(i) !== JSON.stringify(item));
        } else {
          return [...prev, item];
        }
      });
    },
    [hasItem],
  );

  return { removeItem, addItem, emptyItems, hasItem, items, toggleItem, toggleAll, all };
};

export type UseMarkItemsReturnType<T> = {
  removeItem: (item: T | T[]) => void;
  addItem: (item: T | T[]) => void;
  emptyItems: () => void;
  hasItem: (item: T, itemsArr?: T[]) => boolean;
  items: T[];
  all: boolean;
  toggleItem: (item: T) => void;
  toggleAll: (items?: T[]) => void;
};
