/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useGlobalState } from "@gruene-brise/data-access/state/useGlobalState";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus, BiMinus } from "react-icons/bi";

export interface AccordionProps {
  items: AccordionItemProps[];
  allowMultiple?: boolean;
  defaultActiveItem?: string;
  expandAllByDefault?: boolean;
  titleClassName?: string;
  showActiveTitleIndicator?: boolean;
  onClick?(): void;
  /**
   * List of classnames to apply to an active accordion content
   */
  activeClassName?: string[];
  itemContainerClassName?: string;
}

interface AccordionItemProps {
  itemContentClassName?: string;
  title: string | React.ReactNode;
  callbackOnActive?: (isActive?: boolean) => void;
  content?: React.ReactNode;
  id: string;
  rightAddon?: JSX.Element;
}

export const useAccordionState = (
  allowMultiple?: boolean,
  defaultActiveItem?: AccordionProps["defaultActiveItem"],
  expandAllByDefault?: AccordionProps["expandAllByDefault"],
  items?: AccordionProps["items"],
) => {
  const { t } = useTranslation();
  const { adminLanguage } = useGlobalState();
  const [activeItem, setActiveItem] = useState<string | string[]>(allowMultiple ? [] : "");

  const itemIsActive = useCallback(
    (id: string): boolean => {
      if (allowMultiple && Array.isArray(activeItem)) {
        return activeItem.indexOf(id) !== -1;
      }
      return activeItem === id;
    },
    [activeItem, allowMultiple, t, adminLanguage],
  );

  useEffect(() => {
    if (defaultActiveItem) {
      setActiveItem(allowMultiple ? [defaultActiveItem] : defaultActiveItem);
    }
  }, [defaultActiveItem, allowMultiple, t, adminLanguage]);

  useEffect(() => {
    if (expandAllByDefault && items) {
      setActiveItem(items.map((item) => item.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAllByDefault, items, t, adminLanguage]);

  const handleToggle = useCallback(
    (id: string, callbackOnActive?: AccordionItemProps["callbackOnActive"]) => {
      if (allowMultiple && Array.isArray(activeItem)) {
        return setActiveItem((prev) => {
          const prevState = [...prev];
          const itemIndex = prevState.indexOf(id);
          const wasPreviouslyActive = itemIndex !== -1;
          if (wasPreviouslyActive) {
            (prevState as string[]).splice(itemIndex, 1);
          } else {
            (prevState as string[]).push(id);
          }
          callbackOnActive?.(!wasPreviouslyActive);
          return prevState;
        });
      }
      setActiveItem((prev) => {
        const wasPreviouslyActive = (prev as string) === id;
        callbackOnActive?.(!wasPreviouslyActive);
        if (wasPreviouslyActive) return "";
        return id;
      });
    },
    [activeItem, allowMultiple, t, adminLanguage],
  );

  return { handleToggle, itemIsActive };
};

export const Accordion: React.FC<AccordionProps> = ({
  allowMultiple,
  showActiveTitleIndicator = true,
  titleClassName,
  itemContainerClassName,
  items,
  activeClassName = ["bg-tertiary-25"],
  defaultActiveItem,
  expandAllByDefault,
  onClick,
}) => {
  const { itemIsActive, handleToggle } = useAccordionState(
    allowMultiple,
    defaultActiveItem,
    expandAllByDefault,
    items,
  );

  return (
    <div data-testid='accordion' className='bg-transparent'>
      {items
        .filter((item) => Boolean(item))
        .map((item) => {
          const isActive = itemIsActive(item.id);
          return (
            <div
              data-testid='accordion-item-container'
              key={item.id}
              className={itemContainerClassName ?? ""}
            >
              <button
                data-testid='accordion-item-button'
                className={`flex items-center justify-between bg-transparent w-full px-4 sm:px-8 py-3 text-primary text-xl font-semibold border-b border-primary-10 border-solid mb-5 ${
                  titleClassName ?? ""
                }`}
                onClick={() => {
                  handleToggle(item.id);
                  onClick?.();
                }}
              >
                <div className='flex items-center flex-1 mr-4'>
                  {showActiveTitleIndicator &&
                    (isActive ? (
                      <BiMinus size={20} className='text-inherit' />
                    ) : (
                      <BiPlus size={20} className='text-inherit' />
                    ))}
                  <h3 className={showActiveTitleIndicator ? "ml-2" : "text-left"}>{item.title}</h3>
                </div>
                {item.rightAddon && <div>{item.rightAddon}</div>}
              </button>
              <div
                data-testid='accordion-item-content'
                className={`transition-all duration-500 px-4 sm:px-8 py-4 ${
                  isActive ? "h-auto" : "h-0 hidden"
                } ${activeClassName.join(" ")} ${item.itemContentClassName ?? ""}`}
              >
                {item.content}
              </div>
            </div>
          );
        })}
    </div>
  );
};
