import { useEffect, useRef, useState } from "react";
import { ReactComponent as CaretUp } from "../../../assets/icons/caret-up.svg";
import { ReactComponent as CaretDown } from "../../../assets/icons/caret-down-solid.svg";
import { AccordionProps, useAccordionState } from "../../Accordion";

export type DropdownMenuDirection = "top-right" | "top-left" | "bottom-right" | "bottom-left";

interface DropdownAccordionProps extends AccordionProps {
  header?: JSX.Element;
  footer?: JSX.Element;
  triggerButton: JSX.Element;
  dropdownClassName?: string;
  titleClassName?: string;
  menuDirection: DropdownMenuDirection;
  externalControl?: {
    showMenu: boolean;
    setShowMenu: (show: boolean) => void;
  };
  fullClassName?: string;
}

export const positionStyle: { [key in DropdownAccordionProps["menuDirection"]]: string } = {
  "bottom-left": "top-full left-0",
  "bottom-right": "top-full right-0",
  "top-left": "bottom-full left-0",
  "top-right": "bottom-full right-0",
};

export const DropdownAccordion: React.FC<DropdownAccordionProps> = ({
  items,
  allowMultiple,
  activeClassName,
  titleClassName,
  dropdownClassName,
  triggerButton,
  header,
  footer,
  menuDirection = "bottom-right",
  defaultActiveItem,
  externalControl,
  fullClassName,
}) => {
  const dropdownMenuRef = useRef<HTMLUListElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const { itemIsActive, handleToggle } = useAccordionState(allowMultiple, defaultActiveItem);

  useEffect(() => {
    if (externalControl) {
      setShowMenu(externalControl?.showMenu);
    }
  }, [externalControl?.showMenu]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(e.target as Node)) {
        setShowMenu(false);
        externalControl?.setShowMenu(false);
        if (externalControl) {
          setShowMenu(externalControl?.showMenu);
        }
      }
    };

    window.addEventListener("click", handler, true);
    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, []);
  return (
    <div className={`relative ${fullClassName ? fullClassName : ""}`}>
      <button
        aria-describedby='button'
        data-testid='dropdown-accordion-trigger-btn'
        aria-label='button'
        onClick={(e) => {
          e.preventDefault();
          setShowMenu(!showMenu);
          externalControl?.setShowMenu(!showMenu);
        }}
        className='cursor-pointer w-full'
      >
        {triggerButton}
      </button>
      <ul
        data-testid={`position-${menuDirection}`}
        ref={dropdownMenuRef}
        className={`p-0 mx-0 mb-0 mt-1 cursor-pointer transition-all absolute ${
          positionStyle[menuDirection]
        } ${
          dropdownClassName ?? ""
        } rounded-b-sm z-20 w-[200px] bg-tertiary-10 border border-solid !border-grey shadow ${
          !showMenu ? "hidden" : ""
        }`}
      >
        {header && (
          <li
            data-testid='dropdown-accordion-header'
            className='list-none px-4 py-2.5 border-b border-solid border-grey'
          >
            {header}
          </li>
        )}

        {items
          .filter((item) => Boolean(item))
          .map((item) => {
            const isActive = itemIsActive(item.id);
            return (
              <li
                data-testid='dropdown-accordion-item-container'
                key={item.id}
                className='list-none border-b border-solid border-grey'
              >
                <button
                  data-testid='dropdown-accordion-item-button'
                  className={`${
                    titleClassName ?? ""
                  } transiton-all flex items-center justify-between px-4 py-2.5 bg-transparent w-full font-semibold`}
                  onClick={() => handleToggle(item.id, item.callbackOnActive)}
                >
                  <div className=''>
                    {typeof item.title === "string" ? (
                      <h3 className={`${titleClassName ?? ""} text-black text-md`}>{item.title}</h3>
                    ) : (
                      item.title
                    )}
                  </div>
                  <div className='flex space-x-4 items-center'>
                    {item.rightAddon && item.rightAddon}
                    {item.content &&
                      (isActive ? (
                        <CaretUp title='dropdown option is active' className='text-grey' />
                      ) : (
                        <CaretDown title='dropdown option is not active' className='text-grey' />
                      ))}
                  </div>
                </button>
                {item.content && (
                  <div
                    data-testid='dropdown-accordion-item-content'
                    className={`transition-all duration-500 ${
                      isActive
                        ? "h-auto border-t border-primary-10 border-solid px-4 py-2.5 "
                        : "h-0 hidden"
                    } overflow-hidden ${activeClassName?.join(" ") ?? ""} ${
                      item.itemContentClassName ?? ""
                    }`}
                  >
                    {item.content}
                  </div>
                )}
              </li>
            );
          })}
        {footer && (
          <li
            data-testid='dropdown-accordion-footer'
            className='list-none border-b border-solid border-primary-25'
          >
            {footer}
          </li>
        )}
      </ul>
    </div>
  );
};
