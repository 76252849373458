import auth0 from "auth0-js";
import { useMutation } from "react-query";
import { Pharmacy, UserDetails, UserRole } from "../api/generated";
import environment from "../config/environment";
import { useAuthState } from "../state/useAuthState";
import { useUserPharmacyState } from "../state/useUserPharmacy";
import useCartManager from "./useCartManager";
import { useGraphql } from "./useGraphql";

/**
The useAuth0 function provides methods for handling authentication with Auth0.
@returns An object containing the following methods:
loginWithRedirect: A method that redirects the user to the Auth0 login page for authentication.
logOut: A method that logs the user out of their current session.
changePassword: A method that initiates a password change request for the given email.
isAuthenticated: A method that returns an object containing the isLoggedIn boolean and the result, which could either be an empty object or the UserDetails object.
 */

const useAuth0 = (app: "admin" | "webshop") => {
  const auth0ClientId =
    app === "admin"
      ? environment.adminAuth0ClientId
      : app === "webshop"
      ? environment.webshopAuth0ClientId
      : "";
  const auth0client = new auth0.WebAuth({
    domain: environment.auth0Issuer,
    clientID: auth0ClientId,
    redirectUri: environment.auth0RedirectUrl,
    audience: environment.auth0Audience,
    scope: environment.auth0Scope,
    responseType: "code",
    state: environment.auth0State,
  });

  const graphql = useGraphql();
  const authState = useAuthState();
  const pharmacyState = useUserPharmacyState();
  const { emptyCart } = useCartManager();
  const { setPharmacy } = useUserPharmacyState();

  const profileMutation = useMutation(() => graphql.userDetails({ input: {} }));
  const isLoggedIn = !!authState.userInfo;

  const logOut = async (app: "admin" | "webshop" = "admin") => {
    if (typeof window !== "undefined") {
      const redirectUrls: Record<typeof app, string> = {
        admin: environment.adminBaseUrl,
        webshop: environment.webshopBaseUrl,
      };
      const redirectUrl = redirectUrls[app];
      const url = `${environment.apiUrl}/auth/logout?redirect_url=${encodeURIComponent(
        `${environment.auth0Issuer}/v2/logout?client_id=${auth0ClientId}&returnTo=${redirectUrl}`,
      )}`;
      window.location.href = url;
      emptyCart();
      pharmacyState.reset();
      authState.reset();
    }
  };

  const isAuthenticated: () => Promise<{
    isLoggedIn: boolean;
    result: undefined | UserDetails | null;
  }> = async () => {
    const userInfo = authState.userInfo;
    if (!userInfo) {
      return {
        isLoggedIn: false,
        result: undefined,
      };
    }

    try {
      if (userInfo?.roles?.includes(UserRole.Pharmacist)) {
        const pharmacy = (await graphql.getUserPharmacy({ input: { default: true } }))
          .getUserPharmacy?.result as Pharmacy;
        setPharmacy(pharmacy);
      }
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.extensions?.code === "ERROR_UNAUTHORIZED") {
        emptyCart();
      }
    }

    return {
      isLoggedIn: !!userInfo,
      result: userInfo,
    };
  };

  function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      const lastPart = parts.pop();
      if (lastPart) {
        return lastPart.split(";").shift() || null;
      }
    }

    return null;
  }

  const setUserAuthenticationIfNotSet = async (app?: "admin" | "webshop") => {
    const tokenValue = getCookie("gb-token");
    if (!authState.userInfo || !tokenValue) {
      return await setUserAuthentication(app);
    } else {
      return {
        isLoggedIn: true,
        result: authState.userInfo,
      };
    }
  };

  const setUserAuthentication = async (app?: "admin" | "webshop") => {
    try {
      const checkAuth = await profileMutation.mutateAsync();
      const result = checkAuth.userDetails.user;
      if (result) {
        authState.setUserInfo(result);
      } else {
        // don't overwrite the doctor key here
        authState.setUserInfo(undefined);
        authState.setUserType(undefined);
      }

      return {
        isLoggedIn: !!result,
        result,
      };
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.extensions?.code === "ERROR_UNAUTHORIZED") {
        emptyCart();
        logOut(app);
      }

      return {
        isLoggedIn: false,
        result: undefined,
      };
    }
  };

  return {
    /**
     * Log out a user/admin
     */
    logOut,
    /**
     * Handles reset password with Auth0
     * @param email
     * @returns
     */
    changePassword: async (email: string) => {
      return new Promise((resolve, reject) => {
        auth0client.changePassword(
          {
            email,
            connection: "Username-Password-Authentication",
          },
          (err, response) => {
            if (err) {
              reject(err);
              return;
            }

            resolve(response);
          },
        );
      });
    },

    isDoctor: authState.userType === "doctor",

    /**
     * Checks if user is authenticated
     * @returns boolean
     */
    isAuthenticated,
    setUserAuthenticationIfNotSet,
    setUserAuthentication,
    isLoggedIn,
    isLoading: profileMutation.isLoading,
  };
};

export default useAuth0;

export const openRoutes = [
  "/",
  "/forgotpassword",
  "/resetpassword",
  "/signup",
  "/signin",
  "/ncm",
  "/doccheck",
];
