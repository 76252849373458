/**
 * @export
 * @interface LabelProps
 *
 */

import { CSSProperties } from "react";
import { LabelVariant } from "./type";

export * from "./type";
export interface LabelProps {
  size: "xs" | "sm" | "md" | "lg";
  variant: LabelVariant;
  icon?: React.ReactElement;
  iconDir?: "left" | "right";
  text?: JSX.Element | string;
  className?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
}

export type LabelStyle = {
  padding: {
    [key in LabelProps["size"]]: string;
  };

  textSize: {
    [key in LabelProps["size"]]: string;
  };
  variant: {
    [key in LabelProps["variant"]]: {
      background: string;
      textColor: string;
    };
  };
};

export const LabelStyles: LabelStyle = {
  padding: {
    lg: "py-3.5 px-[25px]",
    md: "py-[13px] px-5",
    sm: "py-[5px] px-[16px]",
    xs: "py-1 px-2",
  },
  textSize: {
    lg: "text-xl",
    md: "text-base",
    sm: "text-xs",
    xs: "text-[8px] leading-[12px]",
  },
  variant: {
    gray: {
      background: "bg-primary-alpha",
      textColor: "",
    },
    primary: {
      background: "bg-primary",
      textColor: "text-white",
    },
    green: {
      background: "bg-primary",
      textColor: "",
    },
    primary25: {
      background: "bg-primary-25",
      textColor: "black",
    },
    success: {
      background: "bg-success-alpha",
      textColor: "text-success",
    },
    warning: {
      background: "bg-warning-alpha",
      textColor: "text-warning",
    },

    none: {
      background: "bg-transparent",
      textColor: "",
    },
    fail: {
      background: "bg-secondary-50",
      textColor: "text-fail",
    },
  },
};

/**
 *
 * @param size {sm | md | lg}
 *  @param variant {'gray' | 'green' | 'none'}
 *  @param text {JSX.Element | String}
 *  @param iconDir {'left' | 'right} - optional
 *  @param icon {JSX.Element} optional
 * @example
 * <Label
        size="sm"
        variant='gray'
        text='Button text'
        icon={<Icon className='text-inherit' />}
    />
    @description This reusable label component provides the default styles for the
    variants (gray,green,none) and sizes (sm, md, lg).
    It also accepts SVG icons which has already been pre-defiend to have the same colour as the button text
 * @returns {JSX.Element}
 */
export function Label({
  size,
  variant,
  text,
  iconDir,
  className,
  icon,
  children,
  style,
  onClick,
}: LabelProps): JSX.Element {
  return (
    <div
      className={`
        inline-flex flex-col items-center rounded-3xl cursor-pointer
        ${LabelStyles.padding[size]}
        ${LabelStyles.variant[variant].background}
        ${LabelStyles.variant[variant].textColor}
        ${className || ""}
    `}
      style={{ ...style }}
      onClick={onClick}
    >
      <div className='flex flex-row items-center'>
        {icon && (
          <div
            className={`${
              iconDir === "left" ? "order-first mr-2" : "order-last ml-2"
            } flex items-center text-inherit p-0 m-0`}
          >
            {icon}
          </div>
        )}
        <span className={`font-gellix ${LabelStyles.textSize[size]} p-0 m-0`}>{text}</span>
      </div>

      {children}
    </div>
  );
}

export default Label;
