import { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";

export const useDropdown = <T extends HTMLElement = HTMLDivElement>(
  setIsDropDownOpen?: Dispatch<SetStateAction<boolean>>,
) => {
  const ref = useRef<T>(null);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setShowMenu(false);
        setIsDropDownOpen && setIsDropDownOpen(false);
      }
    };

    window.addEventListener("click", handler, true);
    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, []);
  return { ref, showMenu, setShowMenu };
};
