import { useMemo, useState } from "react";
import styles from "./table.module.css";
import TableData from "./TableData";
import { TableRowProps } from "./types";

const TableRow = <T,>({
  rowClassName,
  onRowClick,
  columns,
  data,
  shouldRowUpdate,
}: TableRowProps<T>): JSX.Element => {
  const [rowClassNameState, setRowClassNameState] = useState<string>();

  const stringifiedData = JSON.stringify(data);
  const tableRowElement = useMemo(() => {
    return (
      <tr
        className={`${styles["table-row"]} p-0 leading-[14px] ${
          data?.tableUncollapsed && "bg-secondary-10"
        } border-[0.5px] border-solid border-grey hover:bg-tertiary-alpha ${
          onRowClick ? "cursor-pointer" : ""
        } ${rowClassName || ""} ${rowClassNameState || ""}`}
      >
        {columns.map((col) => {
          const { rowClassName, id, ...rest } = col;
          return (
            <TableData
              key={id}
              {...rest}
              rowClassName={typeof rowClassName === "function" ? rowClassName(data) : rowClassName}
              setRowClassNameState={setRowClassNameState}
              data={data}
              onRowClick={onRowClick}
            />
          );
        })}
      </tr>
    );
  }, [stringifiedData, shouldRowUpdate]);

  return tableRowElement;
};

export default TableRow;
