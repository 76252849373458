import { format } from "date-fns";

export class DateFormatter {
  public static formatToBackend(e: Date | string | undefined) {
    return format(new Date(e ?? new Date()), "yyyy-MM-dd");
  }

  public static format(
    date: Date | string | undefined,
    size:
      | "date"
      | "time"
      | "shortDate"
      | "mediumDate"
      | "mediumDateYear"
      | "dateTime"
      | "shortDateYear"
      | "fullDateYear" = "dateTime",
  ) {
    if (!date) {
      return "";
    }
    try {
      date = date instanceof Date ? date : new Date(date);
      switch (size) {
        case "date":
          return format(date, "dd.MM.yyyy");
        case "shortDate":
          return format(date, "dd.MM");
        case "mediumDate":
          return format(date, "dd' 'MMM'. '");
        case "mediumDateYear":
          return format(date, "dd' 'MMM'. 'yyyy");
        case "time":
          return format(date, "HH:mm");
        case "shortDateYear":
          return format(date, "dd.MM.yy");
        case "fullDateYear":
          return format(date, "dd MMMM, yyyy");
      }
      return format(date, "dd.MM.yyyy HH:mm");
    } catch {
      return "invalid date";
    }
  }

  public static lastTimeAgo(date: Date | string) {
    date = date instanceof Date ? date : new Date(date);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate.valueOf() - date.valueOf();
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInDays / 30);
    const millisecondsPerSecond = 1000;
    const millisecondsPerMinute = 60 * millisecondsPerSecond;
    const millisecondsPerHour = 60 * millisecondsPerMinute;
    const millisecondsPerDay = 24 * millisecondsPerHour;
    const millisecondsPerWeek = 7 * millisecondsPerDay;
    const millisecondsPerMonth = 30 * millisecondsPerDay;
    const millisecondsPerYear = 365 * millisecondsPerDay;

    if (diffInMinutes < 2) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    } else if (diffInDays < 7) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    } else if (diffInDays < 14) {
      return "last week";
    } else if (diffInMilliseconds < millisecondsPerMonth) {
      const weeksAgo = Math.floor(diffInMilliseconds / millisecondsPerWeek);
      return `${weeksAgo} weeks ago`;
    } else if (diffInMonths < 2) {
      return "last month";
    } else if (diffInMilliseconds < millisecondsPerYear) {
      return format(date, "MMM dd");
    } else {
      const yearsAgo = Math.floor(diffInMilliseconds / millisecondsPerYear);
      return `${yearsAgo} years ago`;
    }
  }
}
