import environment, { Environment } from "@gruene-brise/data-access/config/environment";
import React from "react";

/**
 * EnvironmentIndicator is a component that displays the current environment
 * as a bar at the top of the screen.
 *
 * By default, it is very small. When hovered, it will expand to show the
 * environment name.
 *
 * On production, it will not be displayed.
 */
const EnvironmentIndicator = () => {
  const state: {
    barClass: string;
    text?: string;
  } = {
    [Environment.Dev]: {
      barClass: "bg-red-500 text-white",
      text: "Development Environment",
    },
    [Environment.Staging]: {
      barClass: "bg-yellow-500 text-black",
      text: "Staging Environment",
    },
    [Environment.Production]: {
      barClass: "hidden",
    },
  }[environment.environment];

  return (
    <div
      className={`${state.barClass} text-xs fixed top-0 left-0 right-0 p-1 text-center -translate-y-[22px] hover:translate-y-0 transition-all duration-500 ease-in-out z-50 overflow-hidden`}
    >
      {state.text}
    </div>
  );
};

export default EnvironmentIndicator;
