import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  NewValues: any;
  Snowflake: any;
  Time: any;
  Upload: any;
};

export type AcceptCheckoutChangesInput = {
  checkoutId: Scalars["Snowflake"];
  payment?: InputMaybe<CheckoutPaymentInput>;
};

export type AcceptCheckoutChangesResponse =
  | AcceptCheckoutChangesSuccessResponse
  | CheckoutPaymentRedirectResponse;

export type AcceptCheckoutChangesSuccessResponse = {
  __typename?: "AcceptCheckoutChangesSuccessResponse";
  success: Scalars["Boolean"];
};

export type AccountAnonymizationInput = {
  userID: Scalars["ID"];
};

export type AccountAnonymizationResponse = {
  __typename?: "AccountAnonymizationResponse";
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type AddCheckoutNoteInput = {
  attachments?: InputMaybe<Array<Scalars["Upload"]>>;
  orderId: Scalars["Snowflake"];
  richText: Scalars["String"];
};

export type AddCheckoutNoteResponse = {
  __typename?: "AddCheckoutNoteResponse";
  success: Scalars["Boolean"];
};

export type AddNoteInput = {
  attachments?: InputMaybe<Array<Scalars["Upload"]>>;
  checkoutId?: InputMaybe<Scalars["Snowflake"]>;
  richText: Scalars["String"];
  userId?: InputMaybe<Scalars["ID"]>;
};

export type AddNoteResponse = {
  __typename?: "AddNoteResponse";
  success: Scalars["Boolean"];
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  country: Scalars["String"];
  createdAt: Scalars["Time"];
  houseNumber: Scalars["String"];
  id: Scalars["Snowflake"];
  metaFields: Array<MetaField>;
  state: Scalars["String"];
  street: Scalars["String"];
  updatedAt: Scalars["Time"];
  zip: Scalars["String"];
};

export type AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  houseNumber: Scalars["String"];
  metaFields: Array<MetaFieldInput>;
  state?: InputMaybe<Scalars["String"]>;
  street: Scalars["String"];
  zip: Scalars["String"];
};

export type AlgeaCareCheckoutInput = {
  billingAddress: AddressInput;
  doctor?: InputMaybe<DoctorInput>;
  epWorkflowId?: InputMaybe<Scalars["String"]>;
  isPharmacySignatureNeeded: Scalars["Boolean"];
  pharmacyId: Scalars["Snowflake"];
  portfolioId?: InputMaybe<Scalars["String"]>;
  prescriptions: Array<PrescriptionInput>;
  products: Array<CheckoutProductInput>;
  shippingAddress?: InputMaybe<AddressInput>;
  userInput: CreateAccountRequest;
};

export type AmountOfOrders = {
  __typename?: "AmountOfOrders";
  adjustments: Scalars["Int"];
  cancelled: Scalars["Int"];
  completed: Scalars["Int"];
  fromAlgaeCare: Scalars["Int"];
  open: Scalars["Int"];
  orderApproved: Scalars["Int"];
  pending: Scalars["Int"];
  processing: Scalars["Int"];
  shippedPickup: Scalars["Int"];
  total: Scalars["Int"];
  waitingForApproval: Scalars["Int"];
  waitingForProcessing: Scalars["Int"];
};

export type AuditLog = {
  __typename?: "AuditLog";
  date: Scalars["Time"];
  details?: Maybe<Scalars["String"]>;
  itemId: Scalars["ID"];
  itemType: Scalars["String"];
  newValues?: Maybe<Scalars["NewValues"]>;
  title: Scalars["String"];
  userEmail?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["ID"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type AuditLogInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type AuditLogResponse = {
  __typename?: "AuditLogResponse";
  pageInfo: PageInfo;
  results: Array<AuditLog>;
};

export type AvailableDeliveryOptionsInput = {
  checkoutId: Scalars["Snowflake"];
};

export type AvailableDeliveryOptionsResponse = {
  __typename?: "AvailableDeliveryOptionsResponse";
  deliveryOptions: Array<DeliveryOption>;
};

export type AvailableOffersInput = {
  filter?: InputMaybe<Filter>;
  hasCoverage?: InputMaybe<Scalars["Boolean"]>;
  insuranceType?: InputMaybe<InsuranceType>;
  products: Array<CheckoutProductInput>;
};

export type AvailableOffersResponse = {
  __typename?: "AvailableOffersResponse";
  lowestPrice: Scalars["Int"];
  offers: Array<ProviderOffer>;
  pageInfo: PageInfo;
};

export type AvailablePaymentMethodsInput = {
  checkoutId: Scalars["Snowflake"];
};

export type AvailablePaymentMethodsResponse = {
  __typename?: "AvailablePaymentMethodsResponse";
  paymentMethods: Array<PaymentMethod>;
  unzerBaseUrl?: Maybe<Scalars["String"]>;
  unzerPublicKey?: Maybe<Scalars["String"]>;
};

export type BankInput = {
  bank?: InputMaybe<Scalars["String"]>;
  bic?: InputMaybe<Scalars["String"]>;
  iban: Scalars["String"];
  owner: Scalars["String"];
};

export type BankResponse = {
  __typename?: "BankResponse";
  bank?: Maybe<Scalars["String"]>;
  bic?: Maybe<Scalars["String"]>;
  iban: Scalars["String"];
  owner: Scalars["String"];
};

export type BulkESignatureInput = {
  checkoutIds: Array<Scalars["Snowflake"]>;
};

export type BulkESignatureResponse = {
  __typename?: "BulkESignatureResponse";
  checkout?: Maybe<Checkout>;
};

export type CsvMetaData = {
  dateFormat?: InputMaybe<Scalars["String"]>;
  decimalSeparator?: InputMaybe<Scalars["String"]>;
  delimiter?: InputMaybe<Scalars["String"]>;
  fileEncoding?: InputMaybe<FileEncoding>;
  quoteCharacter?: InputMaybe<Scalars["String"]>;
};

export type CalculatePharmacyCapacityInput = {
  pharmacyID: Scalars["Snowflake"];
};

export type CalculatePharmacyCapacityResponse = {
  __typename?: "CalculatePharmacyCapacityResponse";
  success: Scalars["Boolean"];
};

export type CancelWorkflowRequestInput = {
  cancellationReason: Scalars["String"];
  userGroupId: Scalars["Int"];
};

export type CancelWorkflowResponse = {
  __typename?: "CancelWorkflowResponse";
  success: Scalars["Boolean"];
};

export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordResponse = {
  __typename?: "ChangePasswordResponse";
  success: Scalars["Boolean"];
};

export type CheckOpenOrdersInput = {
  userID: Scalars["ID"];
};

export type CheckOpenOrdersResponse = {
  __typename?: "CheckOpenOrdersResponse";
  count: Scalars["Int"];
  openOrders: Scalars["Boolean"];
};

export type Checkout = {
  __typename?: "Checkout";
  InternalInvoiceNumber?: Maybe<Scalars["String"]>;
  Prescriptions: Array<Prescription>;
  areProductsOrdered: Scalars["Boolean"];
  billingAddress?: Maybe<Address>;
  cancellationInvoiceLink?: Maybe<Scalars["String"]>;
  cancellationInvoiceNumber?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
  dateDeliveredOn?: Maybe<Scalars["Time"]>;
  dateOrderedOn?: Maybe<Scalars["Time"]>;
  dateShippedOn?: Maybe<Scalars["Time"]>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliverySlipLink?: Maybe<Scalars["String"]>;
  deliveryStatus: DeliveryStatus;
  documentSignatureStatus?: Maybe<EsignatureStatus>;
  documentationStatus: CheckoutDocumentationStatus;
  doubleSignedPortfolioID?: Maybe<Scalars["Int"]>;
  ePrescriptionDownloadedAt?: Maybe<Scalars["Time"]>;
  ePrescriptionDownloadedByUser?: Maybe<Scalars["String"]>;
  extras: Array<CheckoutExtra>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Snowflake"];
  invoiceBillingDate?: Maybe<Scalars["Time"]>;
  invoiceLink?: Maybe<Scalars["String"]>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  isPharmacySignatureNeeded?: Maybe<Scalars["Boolean"]>;
  isWaitingForDoctorApproval: Scalars["Boolean"];
  lastChangeReason?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  nextStep: CheckoutNextStep;
  noteCount?: Maybe<Scalars["Int"]>;
  orderGroupId?: Maybe<Scalars["Int"]>;
  orderNumber: Scalars["String"];
  originalCheckoutDetails?: Maybe<OriginalCheckoutDetails>;
  outstandingAmount: Scalars["Int"];
  payLink?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentStatus: PaymentStatus;
  payments: Array<CheckoutPayment>;
  pharmacy?: Maybe<PublicPharmacy>;
  prescriptionData: PrescriptionData;
  prescriptionIssues?: Maybe<Array<PrescriptionIssue>>;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
  productLabelLink?: Maybe<Scalars["String"]>;
  products: Array<CheckoutProduct>;
  productsStatus: CheckoutProductsStatus;
  proposedCheckoutDetails?: Maybe<OriginalCheckoutDetails>;
  proposedProductsStatus?: Maybe<CheckoutProposedProductsStatus>;
  secSignPortfolioID?: Maybe<Scalars["Int"]>;
  shipmentId?: Maybe<Scalars["String"]>;
  shipmentWeightInGrams: Scalars["Int"];
  shippingAddress?: Maybe<Address>;
  shippingLabelLink?: Maybe<Scalars["String"]>;
  shouldDisableChangeLink?: Maybe<Scalars["Boolean"]>;
  status: CheckoutStatus;
  subtotal: Scalars["Int"];
  subtotalNet: Scalars["Int"];
  total: Scalars["Int"];
  totalNet: Scalars["Int"];
  trackingLink?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Time"];
  userEmail?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["Snowflake"]>;
  userInsuranceType?: Maybe<InsuranceType>;
  userName?: Maybe<Scalars["String"]>;
  workflowurl?: Maybe<Scalars["String"]>;
};

export type CheckoutCancelInput = {
  checkoutId: Scalars["ID"];
  message: Scalars["String"];
};

export type CheckoutCancelResponse = {
  __typename?: "CheckoutCancelResponse";
  checkout: Checkout;
};

export type CheckoutCapturePaymentInput = {
  checkoutId: Scalars["Snowflake"];
};

export type CheckoutCapturePaymentResponse = {
  __typename?: "CheckoutCapturePaymentResponse";
  checkout: Checkout;
};

export type CheckoutCompleteLateProductChangeInput = {
  checkoutId: Scalars["Snowflake"];
  confirmChange: Scalars["Boolean"];
  doctor?: InputMaybe<DoctorInput>;
  epWorkflowId?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  newProducts?: InputMaybe<Array<CheckoutProductInput>>;
  portfolioId?: InputMaybe<Scalars["String"]>;
  prescriptions: Array<PrescriptionInput>;
};

export type CheckoutCompleteLateProductChangeResponse = {
  __typename?: "CheckoutCompleteLateProductChangeResponse";
  checkout: Checkout;
};

export type CheckoutCustomerDetailsStatusUpdateInput = {
  checkoutId: Scalars["Snowflake"];
  toStatus: CustomerDetailsStatus;
};

export type CheckoutCustomerDetailsStatusUpdateResponse = {
  __typename?: "CheckoutCustomerDetailsStatusUpdateResponse";
  checkout: Checkout;
};

export type CheckoutDeliveryStatusUpdateInput = {
  checkoutId: Scalars["ID"];
  informOnly?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  toStatus: DeliveryStatus;
};

export type CheckoutDeliveryStatusUpdateResponse = {
  __typename?: "CheckoutDeliveryStatusUpdateResponse";
  checkout: Checkout;
};

export enum CheckoutDocumentationStatus {
  Completed = "completed",
  Pending = "pending",
}

export type CheckoutDocumentationStatusUpdateInput = {
  checkoutId: Scalars["Snowflake"];
  toStatus: CheckoutDocumentationStatus;
};

export type CheckoutDocumentationStatusUpdateResponse = {
  __typename?: "CheckoutDocumentationStatusUpdateResponse";
  checkout: Checkout;
};

export type CheckoutExtra = {
  __typename?: "CheckoutExtra";
  description: Scalars["String"];
  name: Scalars["String"];
  price: Scalars["Int"];
  quantity: Scalars["Int"];
  type?: Maybe<CheckoutExtraType>;
};

export enum CheckoutExtraType {
  BtmFee = "btmFee",
  ShippingFee = "shippingFee",
}

export enum CheckoutNextStep {
  ChangeApproval = "changeApproval",
  Delivery = "delivery",
  EsignatureCompleted = "esignatureCompleted",
  EsignatureReady = "esignatureReady",
  EsignatureStarted = "esignatureStarted",
  InitialPayment = "initialPayment",
  NewOfflinePrescription = "newOfflinePrescription",
  NewOnlinePrescription = "newOnlinePrescription",
  None = "none",
  OfflinePrescriptionArrival = "offlinePrescriptionArrival",
  OtherReason = "otherReason",
  Packing = "packing",
  Payment = "payment",
  Pickup = "pickup",
  ProductsAreOrdered = "productsAreOrdered",
  ProposeProducts = "proposeProducts",
  Shipment = "shipment",
  SubmitCheckout = "submitCheckout",
  Verification = "verification",
  WaitingForDoctorApproval = "waitingForDoctorApproval",
  WaitingForDocumentation = "waitingForDocumentation",
  WaitingForOnlinePrescriptionVerification = "waitingForOnlinePrescriptionVerification",
  WaitingForPharmacistApproval = "waitingForPharmacistApproval",
  WaitingForProducts = "waitingForProducts",
  WaitingForUpload = "waitingForUpload",
}

export type CheckoutNote = {
  __typename?: "CheckoutNote";
  attachments: Array<HubspotAttachment>;
  createdAt: Scalars["Time"];
  id: Scalars["String"];
  richText: Scalars["String"];
  sender: OrderNoteSender;
  updatedAt: Scalars["Time"];
};

export type CheckoutNotesInput = {
  orderId: Scalars["Snowflake"];
};

export type CheckoutNotesResponse = {
  __typename?: "CheckoutNotesResponse";
  pageInfo: PageInfo;
  results: Array<CheckoutNote>;
};

export type CheckoutPayment = {
  __typename?: "CheckoutPayment";
  amount: Scalars["Int"];
  id: Scalars["ID"];
  method: Scalars["String"];
  status: PaymentStatus;
};

export type CheckoutPaymentInput = {
  checkoutId: Scalars["Snowflake"];
  paymentMethod: Scalars["String"];
  paymentTypeId?: InputMaybe<Scalars["String"]>;
  redirectUrl: Scalars["String"];
  threatMetrixId?: InputMaybe<Scalars["String"]>;
};

export type CheckoutPaymentRedirectResponse = {
  __typename?: "CheckoutPaymentRedirectResponse";
  redirectUrl: Scalars["String"];
};

export type CheckoutPaymentResponse =
  | CheckoutPaymentRedirectResponse
  | CheckoutPaymentSuccessResponse;

export type CheckoutPaymentStatusUpdateInput = {
  checkoutId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  toStatus: PaymentStatus;
};

export type CheckoutPaymentStatusUpdateResponse = {
  __typename?: "CheckoutPaymentStatusUpdateResponse";
  checkout: Checkout;
};

export type CheckoutPaymentSuccessResponse = {
  __typename?: "CheckoutPaymentSuccessResponse";
  success: Scalars["Boolean"];
};

export type CheckoutPrescriptionStatusUpdateInput = {
  checkoutId: Scalars["ID"];
  informOnly?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  prescriptionIssues?: InputMaybe<Array<PrescriptionIssueInput>>;
  prescriptions?: InputMaybe<Array<InputMaybe<PrescriptionInput>>>;
  toPrescriptionOfflineStatus?: InputMaybe<PrescriptionOfflineStatus>;
  toPrescriptionOnlineStatus?: InputMaybe<PrescriptionOnlineStatus>;
};

export type CheckoutPrescriptionStatusUpdateResponse = {
  __typename?: "CheckoutPrescriptionStatusUpdateResponse";
  checkout: Checkout;
};

export type CheckoutPreviewNewCartInput = {
  checkoutId: Scalars["Snowflake"];
  products: Array<CheckoutProductInput>;
};

export type CheckoutPreviewNewCartResponse = {
  __typename?: "CheckoutPreviewNewCartResponse";
  checkout: Checkout;
};

export type CheckoutProduct = {
  __typename?: "CheckoutProduct";
  id: Scalars["Int"];
  price: Scalars["Int"];
  product: PublicProduct;
  quantity: Scalars["Int"];
  replacesProductWithId?: Maybe<Scalars["Int"]>;
};

export type CheckoutProductInput = {
  id: Scalars["Snowflake"];
  quantity: Scalars["Int"];
  replacesProductWithId?: InputMaybe<Scalars["Int"]>;
};

export type CheckoutProductStatusUpdateInput = {
  checkoutId: Scalars["Snowflake"];
  toStatus: CheckoutProductsStatus;
};

export type CheckoutProductStatusUpdateResponse = {
  __typename?: "CheckoutProductStatusUpdateResponse";
  checkout: Checkout;
};

export enum CheckoutProductsStatus {
  Invalid = "invalid",
  Pending = "pending",
  Verified = "verified",
}

export type CheckoutProposeLateProductChangeInput = {
  checkoutId: Scalars["Snowflake"];
  message?: InputMaybe<Scalars["String"]>;
  newProducts: Array<CheckoutProductInput>;
};

export type CheckoutProposeLateProductChangeResponse = {
  __typename?: "CheckoutProposeLateProductChangeResponse";
  checkout: Checkout;
};

export enum CheckoutProposedProductsStatus {
  ApprovedByDoctor = "approvedByDoctor",
  ApprovedByDoctorWithChanges = "approvedByDoctorWithChanges",
  Declined = "declined",
  WaitingForDoctorApproval = "waitingForDoctorApproval",
}

export type CheckoutShippingWeightInGramsUpdateInput = {
  checkoutId: Scalars["Snowflake"];
  shipmentWeightInGrams: Scalars["Int"];
};

export type CheckoutShippingWeightInGramsUpdateResponse = {
  __typename?: "CheckoutShippingWeightInGramsUpdateResponse";
  checkout: Checkout;
};

export enum CheckoutStatus {
  Adjusting = "adjusting",
  Cancelled = "cancelled",
  Completed = "completed",
  Created = "created",
  Draft = "draft",
  Esignature = "esignature",
  Pending = "pending",
  Processing = "processing",
  Verification = "verification",
}

export type CheckoutUpdateAddressesInput = {
  billingAddress?: InputMaybe<AddressInput>;
  checkoutId: Scalars["Snowflake"];
  shippingAddress: AddressInput;
};

export type CheckoutUpdateAddressesResponse = {
  __typename?: "CheckoutUpdateAddressesResponse";
  checkout: Checkout;
};

export type CheckoutUpdateDeliveryOptionAdminInput = {
  checkoutId: Scalars["Snowflake"];
  deliveryOptionId: Scalars["Snowflake"];
  informOnly?: InputMaybe<Scalars["Boolean"]>;
  message: Scalars["String"];
};

export type CheckoutUpdateDeliveryOptionAdminResponse = {
  __typename?: "CheckoutUpdateDeliveryOptionAdminResponse";
  checkout: Checkout;
};

export type CheckoutUpdateDeliveryOptionInput = {
  checkoutId: Scalars["Snowflake"];
  deliveryOptionId: Scalars["Snowflake"];
};

export type CheckoutUpdateDeliveryOptionResponse = {
  __typename?: "CheckoutUpdateDeliveryOptionResponse";
  checkout: Checkout;
};

export type CheckoutUpdatePaymentMethodAdminInput = {
  checkoutId: Scalars["Snowflake"];
  informOnly?: InputMaybe<Scalars["Boolean"]>;
  message: Scalars["String"];
  paymentMethod: Scalars["String"];
};

export type CheckoutUpdatePaymentMethodAdminResponse = {
  __typename?: "CheckoutUpdatePaymentMethodAdminResponse";
  checkout: Checkout;
};

export type CheckoutUpdatePrescriptionDataInput = {
  checkoutId: Scalars["Snowflake"];
  prescriptionData?: InputMaybe<PrescriptionDataInput>;
  prescriptions: Array<InputMaybe<PrescriptionInput>>;
};

export type CheckoutUpdatePrescriptionDataResponse = {
  __typename?: "CheckoutUpdatePrescriptionDataResponse";
  checkout: Checkout;
};

export type CloseTicketInput = {
  ticketId: Scalars["String"];
};

export type CloseTicketResponse = {
  __typename?: "CloseTicketResponse";
  success: Scalars["Boolean"];
};

export type CompleteNcmInput = {
  id: Scalars["Snowflake"];
};

export type CompleteNcmResponse = {
  __typename?: "CompleteNCMResponse";
  result: NcmDetails;
};

export type CreateAccountRequest = {
  billingAddress?: InputMaybe<AddressInput>;
  coverage: Scalars["Boolean"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  insuranceType: InsuranceType;
  lastName: Scalars["String"];
  newsletter: Scalars["Boolean"];
  shippingAddress?: InputMaybe<AddressInput>;
  zId?: InputMaybe<Scalars["String"]>;
};

export type CreateAccountResponse = {
  __typename?: "CreateAccountResponse";
  success: Scalars["Boolean"];
};

export type CreateAlgeaAuthAccountInput = {
  coverage?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  insuranceType?: InputMaybe<InsuranceType>;
  newsletter?: InputMaybe<Scalars["Boolean"]>;
  zId?: InputMaybe<Scalars["String"]>;
};

export type CreateAlgeaAuthAccountResponse = {
  __typename?: "CreateAlgeaAuthAccountResponse";
  success: Scalars["Boolean"];
};

export type CreateAlgeaCareCheckoutInput = {
  data: AlgeaCareCheckoutInput;
};

export type CreateAlgeaCareCheckoutResponse = {
  __typename?: "CreateAlgeaCareCheckoutResponse";
  checkout: Checkout;
};

export type CreateCheckoutInput = {
  ncmId?: InputMaybe<Scalars["Snowflake"]>;
  products: Array<CheckoutProductInput>;
  providerOfferId: Scalars["Snowflake"];
};

export type CreateCheckoutResponse = {
  __typename?: "CreateCheckoutResponse";
  checkout: Checkout;
};

export type CreateLabelInput = {
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  namespace: Scalars["String"];
};

export type CreateLabelResponse = {
  __typename?: "CreateLabelResponse";
  label: Label;
};

export type CreateManualCheckoutInput = {
  checkout: ManualCheckoutInput;
};

export type CreateManualCheckoutResponse = {
  __typename?: "CreateManualCheckoutResponse";
  checkout: Checkout;
};

export type CreateMedicalEffectInput = {
  medicalEffect: MedicalEffectInput;
};

export type CreateMedicalEffectResponse = {
  __typename?: "CreateMedicalEffectResponse";
  result: MedicalEffect;
};

export type CreateNcmInput = {
  Prescriptions: Array<PrescriptionNcmInput>;
  createAccount?: InputMaybe<CreateAccountRequest>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  prescription?: InputMaybe<Scalars["Upload"]>;
};

export type CreateNcmResponse = {
  __typename?: "CreateNcmResponse";
  result: Scalars["Boolean"];
};

export type CreatePharmacyInput = {
  pharmacy: PharmacyInput;
};

export type CreatePharmacyResponse = {
  __typename?: "CreatePharmacyResponse";
  result: Pharmacy;
};

export type CreateProductInput = {
  product: ProductInput;
};

export type CreateProductResponse = {
  __typename?: "CreateProductResponse";
  result: Product;
};

export type CreateTerpeneInput = {
  terpene: TerpeneInput;
};

export type CreateTerpeneResponse = {
  __typename?: "CreateTerpeneResponse";
  result: Terpene;
};

export type CreateZugangsCodeInput = {
  code: ZugangsCodeInput;
};

export type CreateZugangsCodeResponse = {
  __typename?: "CreateZugangsCodeResponse";
  result: ZugangsCode;
};

export type CustomShippingDetails = {
  __typename?: "CustomShippingDetails";
  createdAt: Scalars["Time"];
  flatRate?: Maybe<Scalars["Float"]>;
  id: Scalars["Snowflake"];
  minPriceToFreeShipment?: Maybe<Scalars["Float"]>;
  pharmacyID: Scalars["Snowflake"];
  updatedAt: Scalars["Time"];
};

export type CustomShippingDetailsInput = {
  flatRate?: InputMaybe<Scalars["Float"]>;
  minPriceToFreeShipment?: InputMaybe<Scalars["Float"]>;
};

export type Customer = {
  __typename?: "Customer";
  billingAddress?: Maybe<Address>;
  coverage: Scalars["Boolean"];
  createdAt: Scalars["Time"];
  dateOfBirth?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  insuranceType: Scalars["String"];
  isAlgeaCare?: Maybe<Scalars["Boolean"]>;
  lastKnownCoverageFilePath?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  numOrders: Scalars["Int"];
  outstandingOrders: Array<OrderInfo>;
  phoneNumber?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
};

export enum CustomerDetailsStatus {
  Invalid = "invalid",
  Pending = "pending",
  Verified = "verified",
}

export type Customers = {
  __typename?: "Customers";
  new: Scalars["Int"];
  total: Scalars["Int"];
};

export type DashboardData = {
  __typename?: "DashboardData";
  amountOfOrders: AmountOfOrders;
  customers: Customers;
  flowersSold: FlowersSold;
  revenue: Revenue;
};

export type DashboardDataInput = {
  deliveredFrom?: InputMaybe<Scalars["Time"]>;
  deliveredTo?: InputMaybe<Scalars["Time"]>;
  deliveryStatus?: InputMaybe<Array<Scalars["String"]>>;
  from: Scalars["Time"];
  to: Scalars["Time"];
};

export type DashboardDataPerPharmacy = {
  __typename?: "DashboardDataPerPharmacy";
  data: DashboardData;
  pharmacy: Pharmacy;
  previousData: DashboardData;
};

export type DashboardDataPerProduct = {
  __typename?: "DashboardDataPerProduct";
  data: DashboardProductData;
  previousData: DashboardProductData;
  product: PublicProduct;
};

export type DashboardDataResponse = {
  __typename?: "DashboardDataResponse";
  data: Array<DashboardDataPerPharmacy>;
  previousTotal: DashboardData;
  productData: Array<DashboardDataPerProduct>;
  total: DashboardData;
};

export type DashboardProductData = {
  __typename?: "DashboardProductData";
  amountOfOrders: Scalars["Int"];
  averageOrderQuantity: Scalars["Float"];
  averageOrderRevenue: Scalars["Float"];
  revenue: Scalars["Int"];
  totalVolume: Scalars["Int"];
};

export type DeleteAccountInput = {
  id: Scalars["String"];
};

export type DeleteAccountResponse = {
  __typename?: "DeleteAccountResponse";
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export type DeleteCheckoutInput = {
  checkoutIds: Array<Scalars["Snowflake"]>;
};

export type DeleteCheckoutResponse = {
  __typename?: "DeleteCheckoutResponse";
  success: Scalars["Boolean"];
};

export type DeleteLabelInput = {
  id: Scalars["Int"];
};

export type DeleteLabelResponse = {
  __typename?: "DeleteLabelResponse";
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export type DeleteNcmInput = {
  id: Scalars["Snowflake"];
};

export type DeleteNcmResponse = {
  __typename?: "DeleteNcmResponse";
  result: NcmDetails;
};

export type DeleteProductInput = {
  id: Scalars["Snowflake"];
};

export type DeleteProductResponse = {
  __typename?: "DeleteProductResponse";
  success: Scalars["Boolean"];
};

export type DeleteeCheckoutInput = {
  checkoutIds: Scalars["Snowflake"];
};

export type DeliveryOption = {
  __typename?: "DeliveryOption";
  id: Scalars["Snowflake"];
  name: Scalars["String"];
  price: Scalars["Int"];
  type: DeliveryOptionType;
};

export enum DeliveryOptionStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum DeliveryOptionType {
  Dhl = "dhl",
  Go = "go",
  Pickup = "pickup",
}

export enum DeliveryStatus {
  Delivered = "delivered",
  Packed = "packed",
  Pending = "pending",
  Shipped = "shipped",
}

export type DhlInput = {
  apiKey?: InputMaybe<Scalars["String"]>;
  billingNumber?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type DhlResponse = {
  __typename?: "DhlResponse";
  apiKey?: Maybe<Secret>;
  billingNumber?: Maybe<Scalars["String"]>;
  password?: Maybe<Secret>;
  username?: Maybe<Scalars["String"]>;
};

export type DoctorInput = {
  dcId?: InputMaybe<Scalars["String"]>;
  employeeNo?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type Document = {
  __typename?: "Document";
  contextId?: Maybe<Scalars["Int"]>;
  docPatternId?: Maybe<Scalars["Int"]>;
  documentId?: Maybe<Scalars["Int"]>;
  documentName?: Maybe<Scalars["String"]>;
  documentSize?: Maybe<Scalars["Int"]>;
  signatureName?: Maybe<Scalars["String"]>;
  signatureSize?: Maybe<Scalars["Int"]>;
  toBeSigned?: Maybe<Scalars["Boolean"]>;
  workFlowDocAccess?: Maybe<Scalars["String"]>;
};

export enum EsignatureStatus {
  Completed = "completed",
  Pending = "pending",
  Ready = "ready",
  Started = "started",
}

export type EsignatureStatusInput = {
  portfolioId: Scalars["Int"];
  signatureStatus: Scalars["Int"];
};

export type EsignatureStatusResponse = {
  __typename?: "EsignatureStatusResponse";
  success: Scalars["Boolean"];
};

export type Event = {
  __typename?: "Event";
  date: Scalars["Time"];
  description: Scalars["String"];
  error?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  status: EventStatus;
};

export enum EventStatus {
  Critical = "CRITICAL",
  Info = "INFO",
  Success = "SUCCESS",
  Warning = "WARNING",
}

export type EventsInput = {
  filter: Filter;
};

export type EventsResponse = {
  __typename?: "EventsResponse";
  nextCursor?: Maybe<Scalars["String"]>;
  result: Array<Event>;
};

export type FetchPortfolioDetailsInput = {
  __typename?: "FetchPortfolioDetailsInput";
  portfolioId: Scalars["String"];
};

export type FetchPortfolioDetailsResponse = {
  __typename?: "FetchPortfolioDetailsResponse";
  portfolio?: Maybe<Portfolio>;
};

export type FetchSignedPrescriptionInput = {
  checkoutId: Scalars["Snowflake"];
  documentId: Scalars["String"];
  ePrescriptionDownloadedByUser: Scalars["String"];
};

export type FetchSignedPrescriptionResponse = {
  __typename?: "FetchSignedPrescriptionResponse";
  signedPrescription: Scalars["String"];
};

export enum FileEncoding {
  Ansi = "ANSI",
  Utf8 = "UTF8",
}

export type Filter = {
  filter?: InputMaybe<FilterEdge>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortNode>>;
};

export type FilterCheckoutGroupsResponse = {
  __typename?: "FilterCheckoutGroupsResponse";
  pageInfo: PageInfo;
  results: Array<OrderGroup>;
};

export type FilterCheckoutsCountInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
  statusList: Array<Scalars["String"]>;
};

export type FilterCheckoutsCountResponse = {
  __typename?: "FilterCheckoutsCountResponse";
  results: Array<StatusCounts>;
};

export type FilterCheckoutsInput = {
  filter: Filter;
  productsSearch?: InputMaybe<FilterCheckoutsProductSearchInput>;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterCheckoutsProductSearchInput = {
  ids?: InputMaybe<Array<Scalars["Snowflake"]>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterCheckoutsResponse = {
  __typename?: "FilterCheckoutsResponse";
  pageInfo: PageInfo;
  results: Array<Checkout>;
};

export enum FilterComparator {
  Eq = "eq",
  Gt = "gt",
  Gte = "gte",
  In = "in",
  Isnotnull = "isnotnull",
  Isnull = "isnull",
  Like = "like",
  Lt = "lt",
  Lte = "lte",
  Ne = "ne",
  Nin = "nin",
  Nlike = "nlike",
}

export type FilterCustomersInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterCustomersResponse = {
  __typename?: "FilterCustomersResponse";
  pageInfo: PageInfo;
  results: Array<Customer>;
};

export type FilterEdge = {
  edges?: InputMaybe<Array<FilterEdge>>;
  nodes?: InputMaybe<Array<FilterNode>>;
  operator?: InputMaybe<FilterOperator>;
};

export type FilterLabelInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterLabelResponse = {
  __typename?: "FilterLabelResponse";
  pageInfo: PageInfo;
  results: Array<Label>;
};

export type FilterNcmsInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterNcmsResponse = {
  __typename?: "FilterNcmsResponse";
  pageInfo: PageInfo;
  results: Array<NcmDetails>;
};

export type FilterNode = {
  comparator: FilterComparator;
  field: Scalars["String"];
  value: Scalars["String"];
};

export enum FilterOperator {
  And = "and",
  Or = "or",
}

export type FilterPharmacyInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterPharmacyProductDetailsInput = {
  filter: Filter;
  includeEmpty?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterPharmacyProductDetailsResponse = {
  __typename?: "FilterPharmacyProductDetailsResponse";
  pageInfo: PageInfo;
  results: Array<PharmacyProductDetails>;
};

export type FilterPharmacyResponse = {
  __typename?: "FilterPharmacyResponse";
  pageInfo: PageInfo;
  results: Array<Pharmacy>;
};

export type FilterProductInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterProductResponse = {
  __typename?: "FilterProductResponse";
  pageInfo: PageInfo;
  results: Array<Product>;
};

export type FilterPublicPharmacyInput = {
  filter: Filter;
};

export type FilterPublicPharmacyResponse = {
  __typename?: "FilterPublicPharmacyResponse";
  pageInfo: PageInfo;
  results: Array<PublicPharmacy>;
};

export type FilterPublicProductInput = {
  filter: Filter;
  pharmacyId?: InputMaybe<Scalars["Snowflake"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterPublicProductResponse = {
  __typename?: "FilterPublicProductResponse";
  pageInfo: PageInfo;
  results: Array<PublicProduct>;
};

export type FilterResponse = {
  pageInfo: PageInfo;
};

export type FilterUserPharmacyInput = {
  default: Scalars["Boolean"];
};

export type FilterUserPharmacyResponse = {
  __typename?: "FilterUserPharmacyResponse";
  result: Pharmacy;
};

export type FilterZugangsCodeInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type FilterZugangsCodeResponse = {
  __typename?: "FilterZugangsCodeResponse";
  pageInfo: PageInfo;
  results: Array<ZugangsCode>;
};

export type FlowersSold = {
  __typename?: "FlowersSold";
  averageOrderValue: Scalars["Int"];
  averageOrderValueIlios: Scalars["Int"];
  total: Scalars["Int"];
  totalIlios: Scalars["Int"];
};

export type GetAvailableDeliveryOptionsInput = {
  pharmacyId: Scalars["String"];
  subTotal?: InputMaybe<Scalars["Int"]>;
};

export type GetAvailableDeliveryOptionsResponse = {
  __typename?: "GetAvailableDeliveryOptionsResponse";
  results?: Maybe<Array<Maybe<DeliveryOption>>>;
};

export type GetCheckoutInput = {
  id: Scalars["Snowflake"];
};

export type GetCheckoutResponse = {
  __typename?: "GetCheckoutResponse";
  checkout: Checkout;
};

export type GetMedicalEffectByIdInput = {
  id: Scalars["ID"];
};

export type GetMedicalEffectByIdResponse = {
  __typename?: "GetMedicalEffectByIdResponse";
  result: MedicalEffect;
};

export type GetTerpeneByIdInput = {
  id: Scalars["ID"];
};

export type GetTerpeneByIdResponse = {
  __typename?: "GetTerpeneByIdResponse";
  result: Terpene;
};

export type GoInput = {
  customerID?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  passwordTracking?: InputMaybe<Scalars["String"]>;
  pickupTimeFrom?: InputMaybe<Scalars["String"]>;
  pickupTimeTo?: InputMaybe<Scalars["String"]>;
  responsibleStation?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  usernameTracking?: InputMaybe<Scalars["String"]>;
};

export type GoResponse = {
  __typename?: "GoResponse";
  customerID?: Maybe<Scalars["String"]>;
  password?: Maybe<Secret>;
  passwordTracking?: Maybe<Secret>;
  pickupTimeFrom?: Maybe<Scalars["String"]>;
  pickupTimeTo?: Maybe<Scalars["String"]>;
  responsibleStation?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  usernameTracking?: Maybe<Scalars["String"]>;
};

export type HubspotAttachment = {
  __typename?: "HubspotAttachment";
  expiresAt: Scalars["Time"];
  extension: Scalars["String"];
  height?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  url: Scalars["String"];
  width?: Maybe<Scalars["Int"]>;
};

export type Info = {
  __typename?: "Info";
  description: Scalars["String"];
  lastSupportedAppVersion?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  version: Scalars["String"];
};

export type InfoInput = {
  default?: InputMaybe<Scalars["String"]>;
};

export enum InsuranceType {
  Private = "private",
  Public = "public",
}

export type InternalInvoiceNumberInput = {
  checkoutId: Scalars["ID"];
  internalInvoiceNumber?: InputMaybe<Scalars["String"]>;
};

export type InternalInvoiceNumberResponse = {
  __typename?: "InternalInvoiceNumberResponse";
  checkout: Checkout;
};

export type IxosInput = {
  password?: InputMaybe<Scalars["String"]>;
  pharmacyEmail?: InputMaybe<Scalars["String"]>;
  pharmacyNr?: InputMaybe<Scalars["String"]>;
};

export type IxosResponse = {
  __typename?: "IxosResponse";
  idf?: Maybe<Scalars["String"]>;
  password?: Maybe<Secret>;
  pharmacyEmail?: Maybe<Scalars["String"]>;
  pharmacyNr?: Maybe<Scalars["String"]>;
};

export type Label = {
  __typename?: "Label";
  id: Scalars["Int"];
  imageURL?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  namespace: Scalars["String"];
};

export type LabelInput = {
  id: Scalars["Int"];
};

export type LocalAccountAnonymizationInput = {
  email: Scalars["String"];
  userID: Scalars["ID"];
};

export type LoginRequest = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  success: Scalars["Boolean"];
};

export type LogoutRequest = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  success: Scalars["Boolean"];
};

export type ManualCheckoutInput = {
  billingAddress?: InputMaybe<AddressInput>;
  deliveryOptionId?: InputMaybe<Scalars["Snowflake"]>;
  paymentMethod?: InputMaybe<Scalars["String"]>;
  prescriptionData?: InputMaybe<PrescriptionDataInput>;
  prescriptions?: InputMaybe<Array<InputMaybe<PrescriptionInput>>>;
  products?: InputMaybe<Array<CheckoutProductInput>>;
  providerOfferId?: InputMaybe<Scalars["Snowflake"]>;
  shippingAddress?: InputMaybe<AddressInput>;
  userId?: InputMaybe<Scalars["ID"]>;
  userInput?: InputMaybe<CreateAccountRequest>;
};

export type ManualCheckoutResponse = {
  __typename?: "ManualCheckoutResponse";
  checkout: Checkout;
};

export type MedicalEffect = {
  __typename?: "MedicalEffect";
  id: Scalars["ID"];
  label: Label;
  labelId?: Maybe<Scalars["ID"]>;
  productId: Scalars["ID"];
};

export type MedicalEffectInput = {
  labelId: Scalars["ID"];
  productId: Scalars["ID"];
};

export type MetaField = {
  __typename?: "MetaField";
  label: Scalars["String"];
  name: Scalars["String"];
  type: MetaFieldType;
  value: Scalars["String"];
};

export type MetaFieldDefinition = {
  __typename?: "MetaFieldDefinition";
  label: Scalars["String"];
  name: Scalars["String"];
  owner: MetaFieldOwner;
  type: MetaFieldType;
};

export type MetaFieldDefinitionResponse = {
  __typename?: "MetaFieldDefinitionResponse";
  results: Array<MetaFieldDefinition>;
};

export type MetaFieldDefinitionsInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type MetaFieldInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export enum MetaFieldOwner {
  Addresses = "addresses",
  Checkouts = "checkouts",
  Products = "products",
}

export enum MetaFieldType {
  Boolean = "boolean",
  CountryCode = "country_code",
  Date = "date",
  Datetime = "datetime",
  Decimal = "decimal",
  Integer = "integer",
  Phone = "phone",
  Text = "text",
}

export enum MissingProperties {
  MissingAddress = "missingAddress",
  MissingDeliveryOption = "missingDeliveryOption",
  MissingPaymentOption = "missingPaymentOption",
  MissingShortId = "missingShortID",
  MissingStockOption = "missingStockOption",
  MissingTaxNumber = "missingTaxNumber",
}

export type Mutation = {
  __typename?: "Mutation";
  acceptCheckoutChanges: AcceptCheckoutChangesResponse;
  /** @deprecated No longer supported */
  addCheckoutNote: AddCheckoutNoteResponse;
  addNote: AddNoteResponse;
  anonymizeAuth0User: AccountAnonymizationResponse;
  anonymizeHubspotData: AccountAnonymizationResponse;
  anonymizeUserData: AccountAnonymizationResponse;
  anonymizeUserFiles: AccountAnonymizationResponse;
  calculatePharmacyCapacity: CalculatePharmacyCapacityResponse;
  cancelAlgeaCareCheckout: CheckoutCancelResponse;
  cancelCheckout: CheckoutCancelResponse;
  cancelWorkflowRequest: CancelWorkflowResponse;
  changePassword: ChangePasswordResponse;
  checkoutCapturePayment: CheckoutCapturePaymentResponse;
  checkoutPayment: CheckoutPaymentResponse;
  checkoutUpdateAddresses: CheckoutUpdateAddressesResponse;
  checkoutUpdateDeliveryOption: CheckoutUpdateDeliveryOptionResponse;
  checkoutUpdatePrescriptionData: CheckoutUpdatePrescriptionDataResponse;
  closeTicket: CloseTicketResponse;
  completeNCM: CompleteNcmResponse;
  createAccount: CreateAccountResponse;
  createAlgeaAuthAccount: CreateAlgeaAuthAccountResponse;
  createAlgeaCareCheckout: CreateAlgeaCareCheckoutResponse;
  createCheckout: CreateCheckoutResponse;
  createLabel: CreateLabelResponse;
  createManualCheckout: CreateCheckoutResponse;
  createNcm: CreateNcmResponse;
  createPharmacy: CreatePharmacyResponse;
  createProduct: CreateProductResponse;
  createZugangsCode: CreateZugangsCodeResponse;
  deleteAccount: DeleteAccountResponse;
  deleteCheckout: DeleteCheckoutResponse;
  deleteLabel: DeleteLabelResponse;
  deleteNCM: DeleteNcmResponse;
  dummy?: Maybe<Scalars["Boolean"]>;
  handleDeleteProduct: DeleteProductResponse;
  importPharmacyProducts: PharmacyProductImportResponse;
  login: LoginResponse;
  logout: LogoutResponse;
  populatePharmacyUserGroups: PopulatePharmacyUserGroupsResponse;
  postEsignatureStatus: EsignatureStatusResponse;
  processBulkESignature: BulkESignatureResponse;
  refresh: RefreshTokenResponse;
  rejectNCM: RejectNcmResponse;
  sendTicketMessage: SendTicketMessageResponse;
  submitManualCheckout: CreateCheckoutResponse;
  syncDataHubspot?: Maybe<SyncDataResponse>;
  updateAccount: UpdateAccountResponse;
  updateCheckout: UpdateCheckoutResponse;
  updateCheckoutCompleteLateProductChange: CheckoutCompleteLateProductChangeResponse;
  /** @deprecated use `updateCheckoutDocumentationStatus` instead */
  updateCheckoutCustomerDetailsStatus: CheckoutCustomerDetailsStatusUpdateResponse;
  updateCheckoutDeliveryOption: CheckoutUpdateDeliveryOptionAdminResponse;
  updateCheckoutDeliveryStatus: CheckoutDeliveryStatusUpdateResponse;
  updateCheckoutDocumentationStatus: CheckoutDocumentationStatusUpdateResponse;
  updateCheckoutPaymentMethod: CheckoutUpdatePaymentMethodAdminResponse;
  updateCheckoutPaymentStatus: CheckoutPaymentStatusUpdateResponse;
  updateCheckoutPrescriptionStatus: CheckoutPrescriptionStatusUpdateResponse;
  updateCheckoutProductStatus: CheckoutProductStatusUpdateResponse;
  updateCheckoutProposeLateProductChange: CheckoutProposeLateProductChangeResponse;
  updateCheckoutShippingWeight: CheckoutShippingWeightInGramsUpdateResponse;
  /** @deprecated No longer supported */
  updateCheckoutWaitingForDoctorApproval: ToggleWaitingForDoctorApprovalResponse;
  updateInternalInvoiceNumber: InternalInvoiceNumberResponse;
  updateLabel: UpdateLabelResponse;
  updateManualCheckout: CreateCheckoutResponse;
  updateNcm: UpdateNcmResponse;
  updateNotification: UpdateNotificationResponse;
  updateNotificationPreferences: UpdateNotificationPreferencesResponse;
  updatePharmacy: UpdatePharmacyResponse;
  updatePharmacyDHLSettings: UpdatePharmacyDhlSettingsResponse;
  updatePharmacyDeliveryOptions: UpdatePharmacyDeliveryOptionsResponse;
  updatePharmacyNotificationPreferences: UpdatePharmacyNotificationPreferencesResponse;
  updatePharmacyPaymentOptions: UpdatePharmacyPaymentOptionsResponse;
  updatePharmacyProductBulk: UpdatePharmacyProductBulkResponse;
  updatePharmacyProductDetails: UpdatePharmacyProductDetailsResponse;
  updatePharmacyStatus: UpdatePharmacyStatusResponse;
  updatePharmacyStockManagement: UpdatePharmacyStockManagementResponse;
  updateProduct: UpdateProductResponse;
  updateProductBulk: UpdateProductBulkResponse;
  updateProductStatus: UpdateProductStatusResponse;
  updateUpdateCheckoutProductsOrdered: ProductsOrderedResponse;
  updateZugangsCode: UpdateZugangsCodeResponse;
};

export type MutationAcceptCheckoutChangesArgs = {
  input: AcceptCheckoutChangesInput;
};

export type MutationAddCheckoutNoteArgs = {
  input: AddCheckoutNoteInput;
};

export type MutationAddNoteArgs = {
  input: AddNoteInput;
};

export type MutationAnonymizeAuth0UserArgs = {
  input: AccountAnonymizationInput;
};

export type MutationAnonymizeHubspotDataArgs = {
  input: AccountAnonymizationInput;
};

export type MutationAnonymizeUserDataArgs = {
  input: LocalAccountAnonymizationInput;
};

export type MutationAnonymizeUserFilesArgs = {
  input: AccountAnonymizationInput;
};

export type MutationCalculatePharmacyCapacityArgs = {
  input: CalculatePharmacyCapacityInput;
};

export type MutationCancelAlgeaCareCheckoutArgs = {
  input: CheckoutCancelInput;
};

export type MutationCancelCheckoutArgs = {
  input: CheckoutCancelInput;
};

export type MutationCancelWorkflowRequestArgs = {
  input: CancelWorkflowRequestInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCheckoutCapturePaymentArgs = {
  input: CheckoutCapturePaymentInput;
};

export type MutationCheckoutPaymentArgs = {
  input: CheckoutPaymentInput;
};

export type MutationCheckoutUpdateAddressesArgs = {
  input: CheckoutUpdateAddressesInput;
};

export type MutationCheckoutUpdateDeliveryOptionArgs = {
  input: CheckoutUpdateDeliveryOptionInput;
};

export type MutationCheckoutUpdatePrescriptionDataArgs = {
  input: CheckoutUpdatePrescriptionDataInput;
};

export type MutationCloseTicketArgs = {
  input: CloseTicketInput;
};

export type MutationCompleteNcmArgs = {
  input: CompleteNcmInput;
};

export type MutationCreateAccountArgs = {
  input: CreateAccountRequest;
};

export type MutationCreateAlgeaAuthAccountArgs = {
  input: CreateAlgeaAuthAccountInput;
};

export type MutationCreateAlgeaCareCheckoutArgs = {
  input: CreateAlgeaCareCheckoutInput;
};

export type MutationCreateCheckoutArgs = {
  input: CreateCheckoutInput;
};

export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};

export type MutationCreateManualCheckoutArgs = {
  input: CreateManualCheckoutInput;
};

export type MutationCreateNcmArgs = {
  input: CreateNcmInput;
};

export type MutationCreatePharmacyArgs = {
  input: CreatePharmacyInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateZugangsCodeArgs = {
  input: CreateZugangsCodeInput;
};

export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};

export type MutationDeleteCheckoutArgs = {
  input: DeleteCheckoutInput;
};

export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput;
};

export type MutationDeleteNcmArgs = {
  input: DeleteNcmInput;
};

export type MutationHandleDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationImportPharmacyProductsArgs = {
  input: PharmacyProductImportInput;
};

export type MutationLoginArgs = {
  input: LoginRequest;
};

export type MutationLogoutArgs = {
  input: LogoutRequest;
};

export type MutationPopulatePharmacyUserGroupsArgs = {
  input: PopulatePharmacyUserGroupsInput;
};

export type MutationPostEsignatureStatusArgs = {
  input: EsignatureStatusInput;
};

export type MutationProcessBulkESignatureArgs = {
  input: BulkESignatureInput;
};

export type MutationRefreshArgs = {
  input: RefreshTokenInput;
};

export type MutationRejectNcmArgs = {
  input: RejectNcmInput;
};

export type MutationSendTicketMessageArgs = {
  input: SendTicketMessageInput;
};

export type MutationSubmitManualCheckoutArgs = {
  input: SubmitManualCheckoutInput;
};

export type MutationSyncDataHubspotArgs = {
  input: SyncDataInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateCheckoutArgs = {
  input: UpdateCheckoutInput;
};

export type MutationUpdateCheckoutCompleteLateProductChangeArgs = {
  input: CheckoutCompleteLateProductChangeInput;
};

export type MutationUpdateCheckoutCustomerDetailsStatusArgs = {
  input: CheckoutCustomerDetailsStatusUpdateInput;
};

export type MutationUpdateCheckoutDeliveryOptionArgs = {
  input: CheckoutUpdateDeliveryOptionAdminInput;
};

export type MutationUpdateCheckoutDeliveryStatusArgs = {
  input: CheckoutDeliveryStatusUpdateInput;
};

export type MutationUpdateCheckoutDocumentationStatusArgs = {
  input: CheckoutDocumentationStatusUpdateInput;
};

export type MutationUpdateCheckoutPaymentMethodArgs = {
  input: CheckoutUpdatePaymentMethodAdminInput;
};

export type MutationUpdateCheckoutPaymentStatusArgs = {
  input: CheckoutPaymentStatusUpdateInput;
};

export type MutationUpdateCheckoutPrescriptionStatusArgs = {
  input: CheckoutPrescriptionStatusUpdateInput;
};

export type MutationUpdateCheckoutProductStatusArgs = {
  input: CheckoutProductStatusUpdateInput;
};

export type MutationUpdateCheckoutProposeLateProductChangeArgs = {
  input: CheckoutProposeLateProductChangeInput;
};

export type MutationUpdateCheckoutShippingWeightArgs = {
  input: CheckoutShippingWeightInGramsUpdateInput;
};

export type MutationUpdateCheckoutWaitingForDoctorApprovalArgs = {
  input: ToggleWaitingForDoctorApprovalInput;
};

export type MutationUpdateInternalInvoiceNumberArgs = {
  input: InternalInvoiceNumberInput;
};

export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput;
};

export type MutationUpdateManualCheckoutArgs = {
  input: UpdateManualCheckoutInput;
};

export type MutationUpdateNcmArgs = {
  input: UpdateNcmInput;
};

export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};

export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdateNotificationPreferencesInput;
};

export type MutationUpdatePharmacyArgs = {
  input: UpdatePharmacyInput;
};

export type MutationUpdatePharmacyDhlSettingsArgs = {
  input: UpdatePharmacyDhlSettingsInput;
};

export type MutationUpdatePharmacyDeliveryOptionsArgs = {
  input: UpdatePharmacyDeliveryOptionsInput;
};

export type MutationUpdatePharmacyNotificationPreferencesArgs = {
  input: UpdatePharmacyNotificationPreferencesInput;
};

export type MutationUpdatePharmacyPaymentOptionsArgs = {
  input: UpdatePharmacyPaymentOptionsInput;
};

export type MutationUpdatePharmacyProductBulkArgs = {
  input: UpdatePharmacyProductBulkInput;
};

export type MutationUpdatePharmacyProductDetailsArgs = {
  input: PharmacyProductDetailsInput;
};

export type MutationUpdatePharmacyStatusArgs = {
  input: UpdatePharmacyStatusInput;
};

export type MutationUpdatePharmacyStockManagementArgs = {
  input: UpdatePharmacyStockManagementInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProductBulkArgs = {
  input: UpdateProductBulkInput;
};

export type MutationUpdateProductStatusArgs = {
  input: UpdateProductStatusInput;
};

export type MutationUpdateUpdateCheckoutProductsOrderedArgs = {
  input: ProductsOrderedInput;
};

export type MutationUpdateZugangsCodeArgs = {
  input: UpdateZugangsCodeInput;
};

export type Ncm = {
  __typename?: "Ncm";
  id: Scalars["Snowflake"];
  products: Array<NcmProduct>;
};

export type NcmDetails = {
  __typename?: "NcmDetails";
  Prescriptions: Array<PrescriptionNcm>;
  billingAddress?: Maybe<Address>;
  completedDate?: Maybe<Scalars["Time"]>;
  createdAt: Scalars["Time"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Snowflake"];
  insuranceType?: Maybe<InsuranceType>;
  isAlgeaCare?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  prescriptionMetaFields: Array<MetaField>;
  products: Array<NcmProduct>;
  status: NcmStatus;
  statusUser: NcmStatusUser;
  updatedAt: Scalars["Time"];
  userId?: Maybe<Scalars["String"]>;
};

export type NcmInput = {
  id: Scalars["Snowflake"];
};

export type NcmProduct = {
  __typename?: "NcmProduct";
  product: PublicProduct;
  quantity: Scalars["Int"];
};

export type NcmProductInput = {
  id: Scalars["Snowflake"];
  quantity: Scalars["Int"];
};

export type NcmResponse = {
  __typename?: "NcmResponse";
  result: Ncm;
};

export enum NcmStatus {
  Completed = "completed",
  Created = "created",
  Deleted = "deleted",
  Draft = "draft",
  OrderPlaced = "orderPlaced",
  Rejected = "rejected",
}

export enum NcmStatusUser {
  Awaiting = "awaiting",
  Cancelled = "cancelled",
  Deleted = "deleted",
  NotApplicable = "notApplicable",
  OrderPlaced = "orderPlaced",
}

export type Note = {
  __typename?: "Note";
  attachments: Array<HubspotAttachment>;
  createdAt: Scalars["Time"];
  id: Scalars["String"];
  richText: Scalars["String"];
  sender: NoteSender;
  updatedAt: Scalars["Time"];
};

export type NoteSender = {
  __typename?: "NoteSender";
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type NotesInput = {
  checkoutId?: InputMaybe<Scalars["Snowflake"]>;
  userID?: InputMaybe<Scalars["ID"]>;
};

export type NotesResponse = {
  __typename?: "NotesResponse";
  pageInfo: PageInfo;
  results: Array<Note>;
};

export type Notification = {
  __typename?: "Notification";
  date: Scalars["Time"];
  id: Scalars["String"];
  name: Scalars["String"];
  relatedObjects: Array<NotificationRelatedObject>;
  status: NotificationStatus;
  task?: Maybe<Scalars["String"]>;
  type: NotificationName;
};

export enum NotificationName {
  InvalidErpConnection = "invalid_erp_connection",
  LowStock = "low_stock",
  MissingProductDetails = "missing_product_details",
  NewCustomer = "new_customer",
  NewCustomerRequest = "new_customer_request",
  NewOrder = "new_order",
  NewOrderInquiry = "new_order_inquiry",
  NewProduct = "new_product",
  OrderInquiryUpdate = "order_inquiry_update",
  OrderStatusUpdate = "order_status_update",
  TicketUpdate = "ticket_update",
}

export enum NotificationObject {
  Checkout = "checkout",
  Ncm = "ncm",
  Pharmacy = "pharmacy",
  PharmacyProduct = "pharmacyProduct",
  Product = "product",
  Ticket = "ticket",
  User = "user",
}

export type NotificationPreferences = {
  __typename?: "NotificationPreferences";
  newsletter: Scalars["Boolean"];
  notificationType: Array<NotificationType>;
  orderStatus: Scalars["Boolean"];
  ticketStatus: Scalars["Boolean"];
};

export type NotificationPreferencesInput = {
  newsletter: Scalars["Boolean"];
  notificationType: Array<NotificationType>;
  orderStatus: Scalars["Boolean"];
  ticketStatus: Scalars["Boolean"];
};

export type NotificationRelatedObject = {
  __typename?: "NotificationRelatedObject";
  id: Scalars["String"];
  type: NotificationObject;
};

export enum NotificationStatus {
  Seen = "seen",
  Unseen = "unseen",
}

export enum NotificationType {
  Email = "email",
  Phone = "phone",
}

export type NotificationsInput = {
  filter: Filter;
  search?: InputMaybe<Scalars["String"]>;
};

export type NotificationsResponse = {
  __typename?: "NotificationsResponse";
  pageInfo: PageInfo;
  results: Array<Notification>;
};

export type OrderGroup = {
  __typename?: "OrderGroup";
  checkouts?: Maybe<Array<Maybe<Checkout>>>;
  createdAt: Scalars["Time"];
  groupStatus: EsignatureStatus;
  id: Scalars["Int"];
  isLocked?: Maybe<Scalars["Boolean"]>;
  ordersCount?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["Time"];
};

export type OrderInfo = {
  __typename?: "OrderInfo";
  areProductsOrdered: Scalars["Boolean"];
  createdAt: Scalars["Time"];
  dateDeliveredOn?: Maybe<Scalars["Time"]>;
  dateOrderedOn?: Maybe<Scalars["Time"]>;
  dateShippedOn?: Maybe<Scalars["Time"]>;
  deliveryStatus: DeliveryStatus;
  documentationStatus: CheckoutDocumentationStatus;
  id: Scalars["Snowflake"];
  isWaitingForDoctorApproval: Scalars["Boolean"];
  nextStep: CheckoutNextStep;
  orderNumber: Scalars["String"];
  outstandingAmount: Scalars["Int"];
  paymentStatus: PaymentStatus;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
  productsStatus: CheckoutProductsStatus;
  shipmentId?: Maybe<Scalars["String"]>;
  status: CheckoutStatus;
  subtotal: Scalars["Int"];
  subtotalNet: Scalars["Int"];
  total: Scalars["Int"];
  totalNet: Scalars["Int"];
  trackingLink?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Time"];
  userEmail?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["Snowflake"]>;
  userInsuranceType?: Maybe<InsuranceType>;
  userName?: Maybe<Scalars["String"]>;
};

export type OrderNoteSender = {
  __typename?: "OrderNoteSender";
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type OriginalCheckoutDetails = {
  __typename?: "OriginalCheckoutDetails";
  deliveryFee: Scalars["Int"];
  extras: Array<CheckoutExtra>;
  products: Array<CheckoutProduct>;
  subtotal: Scalars["Int"];
  total: Scalars["Int"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type Pagination = {
  cursor?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  allowsForAuthorization: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export enum PaymentStatus {
  Authorized = "authorized",
  Failed = "failed",
  Paid = "paid",
  Partial = "partial",
  Pending = "pending",
  Refunded = "refunded",
}

export type Pharmacy = {
  __typename?: "Pharmacy";
  address?: Maybe<Address>;
  agb?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
  customShippingDetails?: Maybe<CustomShippingDetails>;
  dataProtectionOfficerEmail?: Maybe<Scalars["String"]>;
  dataProtectionOfficerName?: Maybe<Scalars["String"]>;
  defaultAvailability?: Maybe<ProductAvailabilityOptions>;
  deliveryOptions?: Maybe<PharmacyDeliveryOptions>;
  description?: Maybe<Scalars["String"]>;
  dhlSettings: PharmacyDhlSettings;
  email: Scalars["String"];
  heavyCapacity?: Maybe<Scalars["Int"]>;
  iKPharmacyNumber?: Maybe<Scalars["String"]>;
  id: Scalars["Snowflake"];
  imprint?: Maybe<Scalars["String"]>;
  invoiceFooter?: Maybe<Array<Scalars["String"]>>;
  invoicePaymentText?: Maybe<Scalars["String"]>;
  invoiceShowBankDetails: Scalars["Boolean"];
  invoiceShowPaymentText: Scalars["Boolean"];
  legalName?: Maybe<Scalars["String"]>;
  logoURL?: Maybe<Scalars["String"]>;
  mediumCapacity?: Maybe<Scalars["Int"]>;
  missingProperties?: Maybe<Array<MissingProperties>>;
  name: Scalars["String"];
  notificationPreferences?: Maybe<PharmacyNotificationPreferences>;
  openingHours?: Maybe<Scalars["String"]>;
  paymentOptions?: Maybe<PharmacyPaymentOptions>;
  pharmacyCapacityStatus?: Maybe<PharmacyCapacityStatus>;
  pharmacyNumber?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  prescriptionEnvelopeURL?: Maybe<Scalars["String"]>;
  privacyPolicy?: Maybe<Scalars["String"]>;
  shortID?: Maybe<Scalars["String"]>;
  status: PharmacyStatus;
  stockOptions?: Maybe<PharmacyStockOptions>;
  subDomain: Scalars["String"];
  taxNumber?: Maybe<Scalars["String"]>;
  teamLocationPictureURL: Array<Maybe<Scalars["String"]>>;
  updateBy?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Time"];
  useOrderConfirmationInsteadOfInvoice: Scalars["Boolean"];
  user?: Maybe<PharmacyAdmin>;
  userGroup?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type PharmacyAdmin = {
  __typename?: "PharmacyAdmin";
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export enum PharmacyCapacityStatus {
  Green = "Green",
  Red = "Red",
  Yellow = "Yellow",
}

export type PharmacyDhlSettings = {
  __typename?: "PharmacyDHLSettings";
  useAgeCheck: Scalars["Boolean"];
  useDirectDelivery: Scalars["Boolean"];
  useIdentCheck: Scalars["Boolean"];
};

export type PharmacyDhlSettingsInput = {
  useAgeCheck: Scalars["Boolean"];
  useDirectDelivery: Scalars["Boolean"];
  useIdentCheck: Scalars["Boolean"];
};

export type PharmacyDeliveryOptions = {
  __typename?: "PharmacyDeliveryOptions";
  dhlDetails?: Maybe<DhlResponse>;
  goDetails?: Maybe<GoResponse>;
};

export type PharmacyDetailInput = {
  id: Scalars["Snowflake"];
};

export type PharmacyDetailResponse = {
  __typename?: "PharmacyDetailResponse";
  result: Pharmacy;
};

export type PharmacyInput = {
  address?: InputMaybe<AddressInput>;
  agb?: InputMaybe<Scalars["String"]>;
  customShippingDetails?: InputMaybe<CustomShippingDetailsInput>;
  description?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  iKPharmacyNumber?: InputMaybe<Scalars["String"]>;
  imprint?: InputMaybe<Scalars["String"]>;
  invoiceFooter?: InputMaybe<Array<Scalars["String"]>>;
  invoicePaymentText?: InputMaybe<Scalars["String"]>;
  invoiceShowBankDetails?: InputMaybe<Scalars["Boolean"]>;
  invoiceShowPaymentText?: InputMaybe<Scalars["Boolean"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  openingHours?: InputMaybe<Scalars["String"]>;
  paymentMethodsOptions?: InputMaybe<Array<PharmacyPaymentOptionsInput>>;
  pharmacyNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  prescriptionEnvelope?: InputMaybe<Scalars["Upload"]>;
  privacyPolicy?: InputMaybe<Scalars["String"]>;
  shortID?: InputMaybe<Scalars["String"]>;
  subDomain: Scalars["String"];
  taxNumber?: InputMaybe<Scalars["String"]>;
  teamLocationPicture?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
  useOrderConfirmationInsteadOfInvoice?: InputMaybe<Scalars["Boolean"]>;
  user: UserInput;
  website?: InputMaybe<Scalars["String"]>;
};

export type PharmacyNotificationPreferences = {
  __typename?: "PharmacyNotificationPreferences";
  invalidErpConnection: Array<PharmacyNotificationType>;
  lowStock: Array<PharmacyNotificationType>;
  lowStockTriggerValue?: Maybe<Scalars["Int"]>;
  missingProductDetails: Array<PharmacyNotificationType>;
  newOrder: Array<PharmacyNotificationType>;
  newProduct: Array<PharmacyNotificationType>;
  orderStatusUpdate: Array<PharmacyNotificationType>;
  ticketUpdate: Array<PharmacyNotificationType>;
};

export type PharmacyNotificationPreferencesInput = {
  invalidErpConnection: Array<PharmacyNotificationType>;
  lowStock: Array<PharmacyNotificationType>;
  lowStockTriggerValue?: InputMaybe<Scalars["Int"]>;
  missingProductDetails: Array<PharmacyNotificationType>;
  newOrder: Array<PharmacyNotificationType>;
  newProduct: Array<PharmacyNotificationType>;
  orderStatusUpdate: Array<PharmacyNotificationType>;
  ticketUpdate: Array<PharmacyNotificationType>;
};

export enum PharmacyNotificationType {
  Dashboard = "dashboard",
  Email = "email",
}

export type PharmacyPaymentMethodOptions = {
  __typename?: "PharmacyPaymentMethodOptions";
  message?: Maybe<Scalars["String"]>;
  method: PharmacyPaymentOption;
};

export enum PharmacyPaymentOption {
  Cash = "Cash",
  ForcedPrepayment = "ForcedPrepayment",
  Invoice = "Invoice",
  Prepayment = "Prepayment",
  Unzer = "Unzer",
}

export type PharmacyPaymentOptions = {
  __typename?: "PharmacyPaymentOptions";
  bankDetails?: Maybe<BankResponse>;
  /** @deprecated No longer supported */
  paymentMethods?: Maybe<Array<PharmacyPaymentOption>>;
  paymentMethodsOptions: Array<PharmacyPaymentMethodOptions>;
  unzerDetails?: Maybe<UnzerResponse>;
};

export type PharmacyPaymentOptionsInput = {
  message?: InputMaybe<Scalars["String"]>;
  method: PharmacyPaymentOption;
};

export type PharmacyProductBulkUpdateInput = {
  availability?: InputMaybe<ProductAvailabilityInput>;
  basePrice?: InputMaybe<Scalars["Int"]>;
  cbdPercentage?: InputMaybe<Scalars["Float"]>;
  cbdPercentageIsLessThan?: InputMaybe<Scalars["Boolean"]>;
  insurancePrice?: InputMaybe<Scalars["Int"]>;
  selfPayPrice?: InputMaybe<Scalars["Int"]>;
  thcPercentage?: InputMaybe<Scalars["Float"]>;
  thcPercentageIsLessThan?: InputMaybe<Scalars["Boolean"]>;
};

export type PharmacyProductDetails = {
  __typename?: "PharmacyProductDetails";
  availability: ProductAvailability;
  basePrice?: Maybe<Scalars["Int"]>;
  cbdPercentage?: Maybe<Scalars["Float"]>;
  cbdPercentageIsLessThan: Scalars["Boolean"];
  insurancePrice?: Maybe<Scalars["Int"]>;
  pharmacy: Pharmacy;
  pharmacyId: Scalars["Snowflake"];
  pharmacyProductIDs?: Maybe<Scalars["String"]>;
  product: Product;
  productId: Scalars["Snowflake"];
  selfPayPrice?: Maybe<Scalars["Int"]>;
  thcPercentage?: Maybe<Scalars["Float"]>;
  thcPercentageIsLessThan: Scalars["Boolean"];
};

export type PharmacyProductDetailsInput = {
  availability?: InputMaybe<ProductAvailabilityInput>;
  basePrice?: InputMaybe<Scalars["Int"]>;
  cbdPercentage?: InputMaybe<Scalars["Float"]>;
  cbdPercentageIsLessThan?: InputMaybe<Scalars["Boolean"]>;
  deletePharmacyProductID?: InputMaybe<Scalars["Boolean"]>;
  insurancePrice?: InputMaybe<Scalars["Int"]>;
  pharmacyId: Scalars["Snowflake"];
  pharmacyProductIDToDelete?: InputMaybe<Scalars["String"]>;
  pharmacyProductIDs?: InputMaybe<Scalars["String"]>;
  productId: Scalars["Snowflake"];
  selfPayPrice?: InputMaybe<Scalars["Int"]>;
  thcPercentage?: InputMaybe<Scalars["Float"]>;
  thcPercentageIsLessThan?: InputMaybe<Scalars["Boolean"]>;
};

export type PharmacyProductImportAvailabilityMapping = {
  availability: ProductAvailabilityInput;
  rowValue: Scalars["String"];
};

export type PharmacyProductImportFieldMapping = {
  columnName: Scalars["String"];
  field: PharmacyProductImportFields;
};

export enum PharmacyProductImportFields {
  Availability = "availability",
  BasePrice = "basePrice",
  CbdPercentage = "cbdPercentage",
  GramsAvailable = "gramsAvailable",
  InsurancePrice = "insurancePrice",
  PharmacyProductIDs = "pharmacyProductIDs",
  ProductId = "productId",
  SelfPayPrice = "selfPayPrice",
  ThcPercentage = "thcPercentage",
}

export type PharmacyProductImportInput = {
  availabilityMapping?: InputMaybe<Array<PharmacyProductImportAvailabilityMapping>>;
  fieldMapping: Array<PharmacyProductImportFieldMapping>;
  file: Scalars["Upload"];
  metaData: CsvMetaData;
  pharmacyId: Scalars["Snowflake"];
};

export type PharmacyProductImportResponse = {
  __typename?: "PharmacyProductImportResponse";
  messages: Array<Scalars["String"]>;
  updated: Scalars["Int"];
};

export enum PharmacyStatus {
  Disabled = "disabled",
  Enabled = "enabled",
}

export type PharmacyStockOptions = {
  __typename?: "PharmacyStockOptions";
  ixosDetails?: Maybe<IxosResponse>;
  manual: Scalars["Boolean"];
  winApoDetails?: Maybe<WinApoResponse>;
};

export type PharmacyUpdateInput = {
  address?: InputMaybe<AddressInput>;
  agb?: InputMaybe<Scalars["String"]>;
  customShippingDetails?: InputMaybe<CustomShippingDetailsInput>;
  dataProtectionOfficerEmail?: InputMaybe<Scalars["String"]>;
  dataProtectionOfficerName?: InputMaybe<Scalars["String"]>;
  defaultAvailability?: InputMaybe<ProductAvailabilityOptions>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  heavyCapacity?: InputMaybe<Scalars["Int"]>;
  iKPharmacyNumber?: InputMaybe<Scalars["String"]>;
  imprint?: InputMaybe<Scalars["String"]>;
  invoiceFooter?: InputMaybe<Array<Scalars["String"]>>;
  invoicePaymentText?: InputMaybe<Scalars["String"]>;
  invoiceShowBankDetails?: InputMaybe<Scalars["Boolean"]>;
  invoiceShowPaymentText?: InputMaybe<Scalars["Boolean"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  mediumCapacity?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  openingHours?: InputMaybe<Scalars["String"]>;
  paymentMethodsOptions?: InputMaybe<Array<PharmacyPaymentOptionsInput>>;
  pharmacyNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  prescriptionEnvelope?: InputMaybe<Scalars["Upload"]>;
  privacyPolicy?: InputMaybe<Scalars["String"]>;
  shortID?: InputMaybe<Scalars["String"]>;
  subDomain?: InputMaybe<Scalars["String"]>;
  taxNumber?: InputMaybe<Scalars["String"]>;
  teamLocationPicture?: InputMaybe<Array<InputMaybe<Scalars["Upload"]>>>;
  useOrderConfirmationInsteadOfInvoice?: InputMaybe<Scalars["Boolean"]>;
  user?: InputMaybe<UserInput>;
  website?: InputMaybe<Scalars["String"]>;
};

export type PopulatePharmacyUserGroupsInput = {
  shouldUpdate: Scalars["Boolean"];
};

export type PopulatePharmacyUserGroupsResponse = {
  __typename?: "PopulatePharmacyUserGroupsResponse";
  success: Scalars["Boolean"];
};

export type Portfolio = {
  __typename?: "Portfolio";
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  createdByUserName?: Maybe<Scalars["String"]>;
  dateChanged?: Maybe<Scalars["Int"]>;
  dateCreated?: Maybe<Scalars["Int"]>;
  dateLocked?: Maybe<Scalars["String"]>;
  dateStarted?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  docs?: Maybe<Array<Maybe<Document>>>;
  emailGroupId?: Maybe<Scalars["Int"]>;
  extHash?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  informWlfStrtAtEnd?: Maybe<Scalars["String"]>;
  lockedBy?: Maybe<User>;
  lockedByUserId?: Maybe<Scalars["Int"]>;
  lockedByUserName?: Maybe<Scalars["String"]>;
  managedBy?: Maybe<User>;
  managedByUserId?: Maybe<Scalars["Int"]>;
  managedByUserName?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<User>;
  modifiedByUserId?: Maybe<Scalars["Int"]>;
  modifiedByUserName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nextSigner?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  observedBy?: Maybe<User>;
  observedByUserId?: Maybe<Scalars["Int"]>;
  observedByUserName?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["Int"]>;
  portfolioErrorMsg?: Maybe<Scalars["String"]>;
  portfolioId?: Maybe<Scalars["Int"]>;
  portfolioManagers?: Maybe<Array<Maybe<User>>>;
  portfolioName?: Maybe<Scalars["String"]>;
  portfolioNote?: Maybe<Scalars["String"]>;
  portfolioObservers?: Maybe<Array<Maybe<User>>>;
  portfolioState?: Maybe<Scalars["Int"]>;
  portfolioStatusMsg?: Maybe<Scalars["String"]>;
  processedSteps?: Maybe<Scalars["String"]>;
  sealDocsAtEnd?: Maybe<Scalars["String"]>;
  sealProtocolAtEnd?: Maybe<Scalars["String"]>;
  sigPosBySigner?: Maybe<Scalars["Boolean"]>;
  sigPosByWorkFlowUser?: Maybe<Scalars["Boolean"]>;
  signerAreOrdered?: Maybe<Scalars["Boolean"]>;
  startedBy?: Maybe<User>;
  startedByName?: Maybe<Scalars["String"]>;
  startedByUserId?: Maybe<Scalars["Int"]>;
  startedByUserName?: Maybe<Scalars["String"]>;
  steps?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  suppressDocSeal?: Maybe<Scalars["Boolean"]>;
  suppressProtocolSeal?: Maybe<Scalars["Boolean"]>;
  suppressWlfEndMail?: Maybe<Scalars["Boolean"]>;
  transactionId?: Maybe<Scalars["String"]>;
  wflPresetDirectSig?: Maybe<Scalars["String"]>;
  workFlowPfStatusId?: Maybe<Scalars["Int"]>;
  workFlowPfStepId?: Maybe<Scalars["Int"]>;
  workflowContextId?: Maybe<Scalars["String"]>;
  workflowContextName?: Maybe<Scalars["String"]>;
  workflowId?: Maybe<Scalars["Int"]>;
  workflowName?: Maybe<Scalars["String"]>;
  workflowNote?: Maybe<Scalars["String"]>;
  workflowPresetId?: Maybe<Scalars["String"]>;
  workflowPresetName?: Maybe<Scalars["String"]>;
  workflowStep?: Maybe<Scalars["String"]>;
  workflowStepId?: Maybe<Scalars["Int"]>;
};

export type Prescription = {
  __typename?: "Prescription";
  checkoutId: Scalars["Snowflake"];
  doubleSignedDocumentID?: Maybe<Scalars["String"]>;
  epDocumentID?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  prescriptionData: PrescriptionData;
  prescriptionFilePath?: Maybe<Scalars["String"]>;
  prescriptionIssues?: Maybe<Array<PrescriptionIssue>>;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
};

export type PrescriptionData = {
  __typename?: "PrescriptionData";
  coverageFilePath?: Maybe<Scalars["String"]>;
  isAlgeaCare?: Maybe<Scalars["Boolean"]>;
  metaFields: Array<MetaField>;
  prescriptionFilePath?: Maybe<Scalars["String"]>;
};

export type PrescriptionDataInput = {
  coverage?: InputMaybe<Scalars["Upload"]>;
  isAlgeaCare?: InputMaybe<Scalars["Boolean"]>;
  metaFields?: InputMaybe<Array<InputMaybe<MetaFieldInput>>>;
  prescription?: InputMaybe<Scalars["Upload"]>;
  prescriptionBase64?: InputMaybe<Scalars["String"]>;
};

export type PrescriptionInput = {
  checkoutId: Scalars["Snowflake"];
  epDocumentID?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  prescriptionData: PrescriptionDataInput;
  prescriptionFilePath?: InputMaybe<Scalars["String"]>;
  prescriptionIssues?: InputMaybe<Array<PrescriptionIssueInput>>;
  prescriptionOfflineStatus?: InputMaybe<PrescriptionOfflineStatus>;
  prescriptionOnlineStatus?: InputMaybe<PrescriptionOnlineStatus>;
};

export type PrescriptionIssue = {
  __typename?: "PrescriptionIssue";
  message: Scalars["String"];
  type: PrescriptionIssueType;
};

export type PrescriptionIssueInput = {
  message: Scalars["String"];
  newPrescriptionData?: InputMaybe<PrescriptionDataInput>;
  newProducts?: InputMaybe<Array<CheckoutProductInput>>;
  type: PrescriptionIssueType;
};

export enum PrescriptionIssueType {
  AddPrescriptionData = "addPrescriptionData",
  AddProducts = "addProducts",
  OfflinePrescriptionDoesNotMatch = "offlinePrescriptionDoesNotMatch",
  PersonalDetailsInvalid = "personalDetailsInvalid",
  PrescriptionDetailsInvalid = "prescriptionDetailsInvalid",
  ProductsInvalid = "productsInvalid",
}

export type PrescriptionNcm = {
  __typename?: "PrescriptionNcm";
  id: Scalars["ID"];
  ncmId: Scalars["Snowflake"];
  prescriptionData: PrescriptionData;
  prescriptionFilePath?: Maybe<Scalars["String"]>;
  prescriptionIssues?: Maybe<Array<PrescriptionIssue>>;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
};

export type PrescriptionNcmInput = {
  id?: InputMaybe<Scalars["ID"]>;
  ncmId?: InputMaybe<Scalars["Snowflake"]>;
  prescriptionData?: InputMaybe<PrescriptionDataInput>;
  prescriptionFilePath?: InputMaybe<Scalars["String"]>;
  prescriptionIssues?: InputMaybe<Array<InputMaybe<PrescriptionIssueInput>>>;
  prescriptionOfflineStatus?: InputMaybe<PrescriptionOfflineStatus>;
  prescriptionOnlineStatus?: InputMaybe<PrescriptionOnlineStatus>;
};

export enum PrescriptionOfflineStatus {
  Arrived = "arrived",
  Invalid = "invalid",
  Pending = "pending",
  Verified = "verified",
}

export enum PrescriptionOnlineStatus {
  Invalid = "invalid",
  Pending = "pending",
  Verified = "verified",
}

export type Product = {
  __typename?: "Product";
  averageMarketPrice?: Maybe<Scalars["Int"]>;
  bestAvailability: PublicAvailability;
  category: ProductCategory;
  cbdQuantityFrom?: Maybe<Scalars["Float"]>;
  cbdQuantityTo?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["Time"];
  description?: Maybe<Scalars["String"]>;
  fullName: Scalars["String"];
  generalMarketAvailability?: Maybe<Scalars["Boolean"]>;
  genetics: Label;
  id: Scalars["Snowflake"];
  iliosSalesPrice?: Maybe<Scalars["Int"]>;
  imageURL: Scalars["String"];
  insurancePriceFrom?: Maybe<Scalars["Int"]>;
  insurancePriceTo?: Maybe<Scalars["Int"]>;
  irradiation: Label;
  isPromoted?: Maybe<Scalars["Boolean"]>;
  manufacturer: Label;
  medicalEffectText?: Maybe<Scalars["String"]>;
  medicalEffects: Array<MedicalEffect>;
  metaFields: Array<MetaField>;
  pharmacyProductIDs?: Maybe<Scalars["String"]>;
  selfPayPriceFrom?: Maybe<Scalars["Int"]>;
  selfPayPriceTo?: Maybe<Scalars["Int"]>;
  sku: Scalars["String"];
  slotOrder?: Maybe<Scalars["Int"]>;
  specialProductIdentifier?: Maybe<Scalars["String"]>;
  status: ProductStatus;
  strain: Label;
  taxClass: TaxClass;
  terpenes: Array<Terpene>;
  terpenesProfileText: Scalars["String"];
  thcQuantityFrom?: Maybe<Scalars["Float"]>;
  thcQuantityTo?: Maybe<Scalars["Float"]>;
  uniqueProductIdentifiers?: Maybe<Scalars["String"]>;
  unit: Unit;
  unitValue: Scalars["Float"];
  updatedAt: Scalars["Time"];
  wholesaler: Label;
};

export type ProductAvailability = {
  __typename?: "ProductAvailability";
  availableInDaysFrom?: Maybe<Scalars["Int"]>;
  availableInDaysTo?: Maybe<Scalars["Int"]>;
  currentAvailability: ProductAvailabilityOptions;
  dateAvailableFrom?: Maybe<Scalars["Time"]>;
  gramsAvailable?: Maybe<Scalars["Int"]>;
};

export type ProductAvailabilityInput = {
  availableInDaysFrom?: InputMaybe<Scalars["Int"]>;
  availableInDaysTo?: InputMaybe<Scalars["Int"]>;
  currentAvailability: ProductAvailabilityOptions;
  dateAvailableFrom?: InputMaybe<Scalars["Time"]>;
  gramsAvailable?: InputMaybe<Scalars["Int"]>;
};

export enum ProductAvailabilityOptions {
  AvailableFrom = "availableFrom",
  AvailableIn = "availableIn",
  ImmediatelyAvailable = "immediatelyAvailable",
  Unavailable = "unavailable",
}

export type ProductBulkUpdateInput = {
  averageMarketPrice?: InputMaybe<Scalars["Int"]>;
  category?: InputMaybe<ProductCategory>;
  cbdQuantityFrom?: InputMaybe<Scalars["Float"]>;
  cbdQuantityTo?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  generalMarketAvailability?: InputMaybe<Scalars["Boolean"]>;
  genetics?: InputMaybe<LabelInput>;
  iliosSalesPrice?: InputMaybe<Scalars["Int"]>;
  irradiation?: InputMaybe<LabelInput>;
  manufacturer?: InputMaybe<LabelInput>;
  metaFields?: InputMaybe<Array<MetaFieldInput>>;
  pharmacyProductIDs?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  slotOrder?: InputMaybe<Scalars["Int"]>;
  specialProductIdentifier?: InputMaybe<Scalars["String"]>;
  strain?: InputMaybe<LabelInput>;
  taxClass?: InputMaybe<TaxClass>;
  thcQuantityFrom?: InputMaybe<Scalars["Float"]>;
  thcQuantityTo?: InputMaybe<Scalars["Float"]>;
  toStatus?: InputMaybe<ProductStatus>;
  uniqueProductIdentifiers?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Unit>;
  unitValue?: InputMaybe<Scalars["Float"]>;
  wholesaler?: InputMaybe<LabelInput>;
};

export enum ProductCategory {
  Capsule = "capsule",
  Extract = "extract",
  Flower = "flower",
}

export type ProductDescription = {
  __typename?: "ProductDescription";
  description: Scalars["String"];
  effectsId?: Maybe<Array<Scalars["Snowflake"]>>;
  id: Scalars["Snowflake"];
  labelsId?: Maybe<Array<Scalars["Snowflake"]>>;
  productId: Scalars["Snowflake"];
};

export type ProductInput = {
  averageMarketPrice?: InputMaybe<Scalars["Int"]>;
  category: ProductCategory;
  cbdQuantityFrom?: InputMaybe<Scalars["Float"]>;
  cbdQuantityTo?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  fullName: Scalars["String"];
  generalMarketAvailability?: InputMaybe<Scalars["Boolean"]>;
  genetics?: InputMaybe<LabelInput>;
  iliosSalesPrice?: InputMaybe<Scalars["Int"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  irradiation?: InputMaybe<LabelInput>;
  manufacturer?: InputMaybe<LabelInput>;
  medicalEffectText?: InputMaybe<Scalars["String"]>;
  medicalEffects?: InputMaybe<Array<MedicalEffectInput>>;
  metaFields: Array<MetaFieldInput>;
  pharmacyProductIDs?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  slotOrder?: InputMaybe<Scalars["Int"]>;
  specialProductIdentifier?: InputMaybe<Scalars["String"]>;
  strain?: InputMaybe<LabelInput>;
  taxClass: TaxClass;
  terpenes?: InputMaybe<Array<TerpeneInput>>;
  terpenesProfileText?: InputMaybe<Scalars["String"]>;
  thcQuantityFrom?: InputMaybe<Scalars["Float"]>;
  thcQuantityTo?: InputMaybe<Scalars["Float"]>;
  uniqueProductIdentifiers?: InputMaybe<Scalars["String"]>;
  unit: Unit;
  unitValue: Scalars["Float"];
  wholesaler?: InputMaybe<LabelInput>;
};

export enum ProductStatus {
  Active = "active",
  Archived = "archived",
  Draft = "draft",
}

export type ProductUpdateInput = {
  averageMarketPrice?: InputMaybe<Scalars["Int"]>;
  category?: InputMaybe<ProductCategory>;
  cbdQuantityFrom?: InputMaybe<Scalars["Float"]>;
  cbdQuantityTo?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  generalMarketAvailability?: InputMaybe<Scalars["Boolean"]>;
  genetics?: InputMaybe<LabelInput>;
  iliosSalesPrice?: InputMaybe<Scalars["Int"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  irradiation?: InputMaybe<LabelInput>;
  manufacturer?: InputMaybe<LabelInput>;
  medicalEffectText?: InputMaybe<Scalars["String"]>;
  medicalEffects?: InputMaybe<Array<MedicalEffectInput>>;
  metaFields?: InputMaybe<Array<MetaFieldInput>>;
  pharmacyProductIDs?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  slotOrder?: InputMaybe<Scalars["Int"]>;
  specialProductIdentifier?: InputMaybe<Scalars["String"]>;
  strain?: InputMaybe<LabelInput>;
  taxClass?: InputMaybe<TaxClass>;
  terpenes?: InputMaybe<Array<TerpeneInput>>;
  terpenesProfileText?: InputMaybe<Scalars["String"]>;
  thcQuantityFrom?: InputMaybe<Scalars["Float"]>;
  thcQuantityTo?: InputMaybe<Scalars["Float"]>;
  uniqueProductIdentifiers?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Unit>;
  unitValue?: InputMaybe<Scalars["Float"]>;
  wholesaler?: InputMaybe<LabelInput>;
};

export type ProductsOrderedInput = {
  checkoutId: Scalars["Snowflake"];
  message?: InputMaybe<Scalars["String"]>;
};

export type ProductsOrderedResponse = {
  __typename?: "ProductsOrderedResponse";
  checkout: Checkout;
};

export type ProviderOffer = {
  __typename?: "ProviderOffer";
  availability: PublicAvailability;
  id: Scalars["Snowflake"];
  pharmacy: PublicPharmacy;
  products: Array<ProviderProduct>;
  subTotal: Scalars["Int"];
};

export type ProviderProduct = {
  __typename?: "ProviderProduct";
  basePrice: Scalars["Int"];
  price: Scalars["Int"];
  product: PublicProduct;
  quantity: Scalars["Int"];
};

export type PublicAvailability = {
  __typename?: "PublicAvailability";
  availableInDaysFrom?: Maybe<Scalars["Int"]>;
  availableInDaysTo?: Maybe<Scalars["Int"]>;
  currentAvailability: ProductAvailabilityOptions;
  dateAvailableFrom?: Maybe<Scalars["Time"]>;
};

export type PublicPharmacy = {
  __typename?: "PublicPharmacy";
  address?: Maybe<Address>;
  agb?: Maybe<Scalars["String"]>;
  bankInfo?: Maybe<BankResponse>;
  customShippingDetails?: Maybe<CustomShippingDetails>;
  dataProtectionOfficerEmail?: Maybe<Scalars["String"]>;
  dataProtectionOfficerName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["Snowflake"];
  imprint?: Maybe<Scalars["String"]>;
  legalName?: Maybe<Scalars["String"]>;
  logoURL?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  openingHours?: Maybe<Scalars["String"]>;
  paymentMethods?: Maybe<Array<PharmacyPaymentMethodOptions>>;
  phoneNumber?: Maybe<Scalars["String"]>;
  prescriptionEnvelopeURL?: Maybe<Scalars["String"]>;
  privacyPolicy?: Maybe<Scalars["String"]>;
  subDomain: Scalars["String"];
  taxNumber?: Maybe<Scalars["String"]>;
  teamLocationPictureURL?: Maybe<Scalars["String"]>;
  useOrderConfirmationInsteadOfInvoice?: Maybe<Scalars["Boolean"]>;
  website?: Maybe<Scalars["String"]>;
};

export type PublicProduct = {
  __typename?: "PublicProduct";
  availability: PublicAvailability;
  averageMarketPrice?: Maybe<Scalars["Int"]>;
  basePrice?: Maybe<Scalars["Int"]>;
  category: ProductCategory;
  cbdQuantityFrom?: Maybe<Scalars["Float"]>;
  cbdQuantityTo?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  fullName: Scalars["String"];
  generalMarketAvailability?: Maybe<Scalars["Boolean"]>;
  genetics: Label;
  id: Scalars["Snowflake"];
  iliosSalesPrice?: Maybe<Scalars["Int"]>;
  imageURL: Scalars["String"];
  irradiation: Label;
  isPromoted?: Maybe<Scalars["Boolean"]>;
  manufacturer: Label;
  medicalEffectText?: Maybe<Scalars["String"]>;
  medicalEffects: Array<MedicalEffect>;
  metaFields: Array<MetaField>;
  pharmacyProductIDs?: Maybe<Scalars["String"]>;
  slotOrder?: Maybe<Scalars["Int"]>;
  specialProductIdentifier?: Maybe<Scalars["String"]>;
  startingPrice?: Maybe<Scalars["Int"]>;
  startingPriceIsInsurance?: Maybe<Scalars["Boolean"]>;
  strain: Label;
  taxClass: TaxClass;
  terpenes: Array<Terpene>;
  terpenesProfileText: Scalars["String"];
  thcQuantityFrom?: Maybe<Scalars["Float"]>;
  thcQuantityTo?: Maybe<Scalars["Float"]>;
  uniqueProductIdentifiers?: Maybe<Scalars["String"]>;
  unit: Unit;
  unitValue: Scalars["Float"];
  wholesaler: Label;
};

export type Query = {
  __typename?: "Query";
  auditLogs: AuditLogResponse;
  availableDeliveryOptions: AvailableDeliveryOptionsResponse;
  availableOffers: AvailableOffersResponse;
  availablePaymentMethods: AvailablePaymentMethodsResponse;
  checkOpenOrders: CheckOpenOrdersResponse;
  checkout: GetCheckoutResponse;
  checkoutPreviewNewCart: CheckoutPreviewNewCartResponse;
  dashboardData: DashboardDataResponse;
  dummy?: Maybe<Scalars["Boolean"]>;
  events: EventsResponse;
  fetchSignedPrescription: FetchSignedPrescriptionResponse;
  filterCheckoutGroups: FilterCheckoutGroupsResponse;
  filterCheckouts: FilterCheckoutsResponse;
  filterCheckoutsCount: FilterCheckoutsCountResponse;
  filterCustomers: FilterCustomersResponse;
  filterNcms: FilterNcmsResponse;
  filterPharmacies: FilterPharmacyResponse;
  filterPharmacyProductDetails: FilterPharmacyProductDetailsResponse;
  filterProducts: FilterProductResponse;
  filterPublicPharmacies: FilterPublicPharmacyResponse;
  filterPublicProducts: FilterPublicProductResponse;
  getAvailableDeliveryOptions?: Maybe<GetAvailableDeliveryOptionsResponse>;
  /** @deprecated No longer supported */
  getCheckoutNotes: CheckoutNotesResponse;
  getNotes: NotesResponse;
  getUserPharmacy: FilterUserPharmacyResponse;
  info: Info;
  labels: FilterLabelResponse;
  metaFieldDefinitions: MetaFieldDefinitionResponse;
  ncm: NcmResponse;
  notifications: NotificationsResponse;
  pharmacyDetail: PharmacyDetailResponse;
  retrieveOrderGroup?: Maybe<RetrieveOrderGroupResponse>;
  tickets: TicketsResponse;
  userDetails: UserDetailsResponse;
  zugangsCodes: FilterZugangsCodeResponse;
};

export type QueryAuditLogsArgs = {
  input: AuditLogInput;
};

export type QueryAvailableDeliveryOptionsArgs = {
  input: AvailableDeliveryOptionsInput;
};

export type QueryAvailableOffersArgs = {
  input: AvailableOffersInput;
};

export type QueryAvailablePaymentMethodsArgs = {
  input: AvailablePaymentMethodsInput;
};

export type QueryCheckOpenOrdersArgs = {
  input: CheckOpenOrdersInput;
};

export type QueryCheckoutArgs = {
  input: GetCheckoutInput;
};

export type QueryCheckoutPreviewNewCartArgs = {
  input: CheckoutPreviewNewCartInput;
};

export type QueryDashboardDataArgs = {
  input: DashboardDataInput;
};

export type QueryEventsArgs = {
  request: EventsInput;
};

export type QueryFetchSignedPrescriptionArgs = {
  input: FetchSignedPrescriptionInput;
};

export type QueryFilterCheckoutGroupsArgs = {
  input: FilterCheckoutsInput;
};

export type QueryFilterCheckoutsArgs = {
  input: FilterCheckoutsInput;
};

export type QueryFilterCheckoutsCountArgs = {
  input: FilterCheckoutsCountInput;
};

export type QueryFilterCustomersArgs = {
  input: FilterCustomersInput;
};

export type QueryFilterNcmsArgs = {
  input: FilterNcmsInput;
};

export type QueryFilterPharmaciesArgs = {
  input: FilterPharmacyInput;
};

export type QueryFilterPharmacyProductDetailsArgs = {
  input: FilterPharmacyProductDetailsInput;
};

export type QueryFilterProductsArgs = {
  input: FilterProductInput;
};

export type QueryFilterPublicPharmaciesArgs = {
  input: FilterPublicPharmacyInput;
};

export type QueryFilterPublicProductsArgs = {
  input: FilterPublicProductInput;
};

export type QueryGetAvailableDeliveryOptionsArgs = {
  input?: InputMaybe<GetAvailableDeliveryOptionsInput>;
};

export type QueryGetCheckoutNotesArgs = {
  input: CheckoutNotesInput;
};

export type QueryGetNotesArgs = {
  input: NotesInput;
};

export type QueryGetUserPharmacyArgs = {
  input: FilterUserPharmacyInput;
};

export type QueryInfoArgs = {
  input: InfoInput;
};

export type QueryLabelsArgs = {
  input?: InputMaybe<FilterLabelInput>;
};

export type QueryMetaFieldDefinitionsArgs = {
  input: MetaFieldDefinitionsInput;
};

export type QueryNcmArgs = {
  input: NcmInput;
};

export type QueryNotificationsArgs = {
  input: NotificationsInput;
};

export type QueryPharmacyDetailArgs = {
  input: PharmacyDetailInput;
};

export type QueryRetrieveOrderGroupArgs = {
  input?: InputMaybe<DeleteeCheckoutInput>;
};

export type QueryTicketsArgs = {
  input: TicketsInput;
};

export type QueryUserDetailsArgs = {
  input: UserDetailsInput;
};

export type QueryZugangsCodesArgs = {
  filter: FilterZugangsCodeInput;
};

export type RefreshTokenInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type RefreshTokenResponse = {
  __typename?: "RefreshTokenResponse";
  success: Scalars["Boolean"];
};

export type RejectNcmInput = {
  id: Scalars["Snowflake"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type RejectNcmResponse = {
  __typename?: "RejectNCMResponse";
  result: NcmDetails;
};

export type RetrieveOrderGroupResponse = {
  __typename?: "RetrieveOrderGroupResponse";
  orderGroupId: Scalars["Snowflake"];
};

export type Revenue = {
  __typename?: "Revenue";
  averageOrderValue: Scalars["Int"];
  averageOrderValueFromAlgaeCare: Scalars["Int"];
  total: Scalars["Int"];
  totalFromAlgaeCare: Scalars["Int"];
};

export type Secret = {
  __typename?: "Secret";
  lastChars: Scalars["String"];
  length: Scalars["Int"];
};

export type SendTicketMessageInput = {
  attachments?: InputMaybe<Array<Scalars["Upload"]>>;
  richText?: InputMaybe<Scalars["String"]>;
  text: Scalars["String"];
  ticketId: Scalars["String"];
};

export type SendTicketMessageResponse = {
  __typename?: "SendTicketMessageResponse";
  result: Ticket;
};

export type SignatureDocumentDeleteInput = {
  __typename?: "SignatureDocumentDeleteInput";
  portfolioId: Scalars["Int"];
};

export type SignatureDocumentDeleteResponse = {
  __typename?: "SignatureDocumentDeleteResponse";
  success: Scalars["Boolean"];
};

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type SortNode = {
  direction: SortDirection;
  field: Scalars["String"];
};

export type StatusCounts = {
  __typename?: "StatusCounts";
  count: Scalars["Int"];
  status: Scalars["String"];
};

export enum SubmitInAdjustingStatusOption {
  OtherReason = "OtherReason",
  ProductsAreOrdered = "ProductsAreOrdered",
  ProposeProducts = "ProposeProducts",
  WaitingForDoctorApproval = "WaitingForDoctorApproval",
  WaitingForProducts = "WaitingForProducts",
}

export type SubmitManualCheckoutInput = {
  checkoutId: Scalars["Snowflake"];
  message?: InputMaybe<Scalars["String"]>;
  prescriptions?: InputMaybe<Array<InputMaybe<PrescriptionInput>>>;
  submitInAdjustingStatus?: InputMaybe<Scalars["Boolean"]>;
  submitInAdjustingStatusOption?: InputMaybe<SubmitInAdjustingStatusOption>;
};

export type SubmitManualCheckoutResponse = {
  __typename?: "SubmitManualCheckoutResponse";
  checkout: Checkout;
};

export type SyncDataInput = {
  from: Scalars["Time"];
  to?: InputMaybe<Scalars["Time"]>;
  type: Array<SyncDataType>;
  update: Scalars["Boolean"];
};

export type SyncDataResponse = {
  __typename?: "SyncDataResponse";
  success: Scalars["Boolean"];
};

export enum SyncDataType {
  Checkout = "Checkout",
  CheckoutDeleted = "CheckoutDeleted",
  CheckoutUserAssociation = "CheckoutUserAssociation",
  Pharmacy = "Pharmacy",
  Product = "Product",
  User = "User",
}

export enum TaxClass {
  Vat19 = "vat19",
}

export type Terpene = {
  __typename?: "Terpene";
  contributionPercentage?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  label: Label;
  productId: Scalars["ID"];
};

export type TerpeneInput = {
  contributionPercentage?: InputMaybe<Scalars["Float"]>;
  labelId: Scalars["ID"];
  productId: Scalars["ID"];
};

export type Ticket = {
  __typename?: "Ticket";
  createdAt: Scalars["Time"];
  id: Scalars["String"];
  latestMessageReceivedTimestamp?: Maybe<Scalars["Time"]>;
  latestMessageSentTimestamp?: Maybe<Scalars["Time"]>;
  messages: Array<TicketMessage>;
  status: TicketStatus;
  subject: Scalars["String"];
  updatedAt: Scalars["Time"];
};

export type TicketAttachment = {
  __typename?: "TicketAttachment";
  type: TicketAttachmentType;
  url: Scalars["String"];
};

export enum TicketAttachmentType {
  Image = "IMAGE",
  Other = "OTHER",
}

export type TicketMessage = {
  __typename?: "TicketMessage";
  attachments: Array<TicketAttachment>;
  createdAt: Scalars["Time"];
  direction: TicketMessageDirection;
  id: Scalars["String"];
  richText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  updatedAt?: Maybe<Scalars["Time"]>;
};

export enum TicketMessageDirection {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export enum TicketStatus {
  Archived = "ARCHIVED",
  Closed = "CLOSED",
  Open = "OPEN",
}

export type TicketsInput = {
  filter: Filter;
};

export type TicketsResponse = {
  __typename?: "TicketsResponse";
  pageInfo: PageInfo;
  results: Array<Ticket>;
};

export type ToggleWaitingForDoctorApprovalInput = {
  checkoutId: Scalars["Snowflake"];
  message?: InputMaybe<Scalars["String"]>;
};

export type ToggleWaitingForDoctorApprovalResponse = {
  __typename?: "ToggleWaitingForDoctorApprovalResponse";
  checkout: Checkout;
};

export enum Unit {
  Gram = "gram",
  Milliliter = "milliliter",
  Piece = "piece",
}

export type UnzerInput = {
  privateKey?: InputMaybe<Scalars["String"]>;
  publicKey?: InputMaybe<Scalars["String"]>;
};

export type UnzerResponse = {
  __typename?: "UnzerResponse";
  privateKey?: Maybe<Secret>;
  publicKey?: Maybe<Scalars["String"]>;
};

export type UpdateAccountInput = {
  billingAddress?: InputMaybe<AddressInput>;
  coverage?: InputMaybe<Scalars["Boolean"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  insuranceContact?: InputMaybe<Scalars["String"]>;
  insuranceName?: InputMaybe<Scalars["String"]>;
  insuranceType?: InputMaybe<InsuranceType>;
  isAlgeaCare?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type UpdateAccountResponse = {
  __typename?: "UpdateAccountResponse";
  success: Scalars["Boolean"];
};

export type UpdateCheckoutInput = {
  billingAddress?: InputMaybe<AddressInput>;
  id: Scalars["Snowflake"];
  informOnly?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  prescriptionData?: InputMaybe<PrescriptionDataInput>;
  prescriptions?: InputMaybe<Array<InputMaybe<PrescriptionInput>>>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type UpdateCheckoutResponse = {
  __typename?: "UpdateCheckoutResponse";
  checkout: Checkout;
};

export type UpdateLabelInput = {
  id: Scalars["Int"];
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  namespace: Scalars["String"];
};

export type UpdateLabelResponse = {
  __typename?: "UpdateLabelResponse";
  label: Label;
};

export type UpdateManualCheckoutInput = {
  checkout: ManualCheckoutInput;
  checkoutId: Scalars["Snowflake"];
};

export type UpdateManualCheckoutResponse = {
  __typename?: "UpdateManualCheckoutResponse";
  checkout: Checkout;
};

export type UpdateNcmInput = {
  Prescriptions: Array<PrescriptionNcmInput>;
  billingAddress?: InputMaybe<AddressInput>;
  id: Scalars["Snowflake"];
  isAlgeaCare?: InputMaybe<Scalars["Boolean"]>;
  prescriptionMetaFields?: InputMaybe<Array<MetaFieldInput>>;
  products?: InputMaybe<Array<NcmProductInput>>;
};

export type UpdateNcmResponse = {
  __typename?: "UpdateNcmResponse";
  result: NcmDetails;
};

export type UpdateNotificationInput = {
  id: Scalars["String"];
  toStatus: NotificationStatus;
};

export type UpdateNotificationPreferencesInput = {
  notificationPreferences: NotificationPreferencesInput;
};

export type UpdateNotificationPreferencesResponse = {
  __typename?: "UpdateNotificationPreferencesResponse";
  success: Scalars["Boolean"];
};

export type UpdateNotificationResponse = {
  __typename?: "UpdateNotificationResponse";
  success: Scalars["Boolean"];
};

export type UpdatePharmacyDhlSettingsInput = {
  dhlSettings: PharmacyDhlSettingsInput;
  id: Scalars["Snowflake"];
};

export type UpdatePharmacyDhlSettingsResponse = {
  __typename?: "UpdatePharmacyDHLSettingsResponse";
  result: PharmacyDhlSettings;
};

export type UpdatePharmacyDeliveryOptionsInput = {
  dhlDetails?: InputMaybe<DhlInput>;
  goDetails?: InputMaybe<GoInput>;
  id: Scalars["Snowflake"];
};

export type UpdatePharmacyDeliveryOptionsResponse = {
  __typename?: "UpdatePharmacyDeliveryOptionsResponse";
  result: PharmacyDeliveryOptions;
};

export type UpdatePharmacyInput = {
  id?: InputMaybe<Scalars["Snowflake"]>;
  pharmacy: PharmacyUpdateInput;
};

export type UpdatePharmacyNotificationPreferencesInput = {
  notificationPreferences: PharmacyNotificationPreferencesInput;
};

export type UpdatePharmacyNotificationPreferencesResponse = {
  __typename?: "UpdatePharmacyNotificationPreferencesResponse";
  success: Scalars["Boolean"];
};

export type UpdatePharmacyPaymentOptionsInput = {
  bankDetails?: InputMaybe<BankInput>;
  id: Scalars["Snowflake"];
  paymentMethods?: InputMaybe<Array<PharmacyPaymentOption>>;
  paymentMethodsOptions?: InputMaybe<Array<PharmacyPaymentOptionsInput>>;
  unzerDetails?: InputMaybe<UnzerInput>;
};

export type UpdatePharmacyPaymentOptionsResponse = {
  __typename?: "UpdatePharmacyPaymentOptionsResponse";
  result: PharmacyPaymentOptions;
};

export type UpdatePharmacyProductBulkInput = {
  filter: FilterEdge;
  product: PharmacyProductBulkUpdateInput;
};

export type UpdatePharmacyProductBulkResponse = {
  __typename?: "UpdatePharmacyProductBulkResponse";
  success: Scalars["Boolean"];
};

export type UpdatePharmacyProductDetailsResponse = {
  __typename?: "UpdatePharmacyProductDetailsResponse";
  result: PharmacyProductDetails;
};

export type UpdatePharmacyResponse = {
  __typename?: "UpdatePharmacyResponse";
  result: Pharmacy;
};

export type UpdatePharmacyStatusInput = {
  id: Scalars["Snowflake"];
  status: PharmacyStatus;
};

export type UpdatePharmacyStatusResponse = {
  __typename?: "UpdatePharmacyStatusResponse";
  result: Pharmacy;
};

export type UpdatePharmacyStockManagementInput = {
  id: Scalars["Snowflake"];
  ixosDetails?: InputMaybe<IxosInput>;
  manual: Scalars["Boolean"];
  winApoDetails?: InputMaybe<WinApoInput>;
};

export type UpdatePharmacyStockManagementResponse = {
  __typename?: "UpdatePharmacyStockManagementResponse";
  result: PharmacyStockOptions;
};

export type UpdateProductBulkInput = {
  filter: FilterEdge;
  product: ProductBulkUpdateInput;
};

export type UpdateProductBulkResponse = {
  __typename?: "UpdateProductBulkResponse";
  success: Scalars["Boolean"];
};

export type UpdateProductInput = {
  id: Scalars["Snowflake"];
  product: ProductUpdateInput;
};

export type UpdateProductResponse = {
  __typename?: "UpdateProductResponse";
  result: Product;
};

export type UpdateProductStatusInput = {
  id: Scalars["Snowflake"];
  toStatus: ProductStatus;
};

export type UpdateProductStatusResponse = {
  __typename?: "UpdateProductStatusResponse";
  result: Product;
};

export type UpdateZugangsCodeInput = {
  code: ZugangsCodeInput;
  id: Scalars["ID"];
};

export type UpdateZugangsCodeResponse = {
  __typename?: "UpdateZugangsCodeResponse";
  result: ZugangsCode;
};

export type User = {
  __typename?: "User";
  contactEmailAddr?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  extUserGroup?: Maybe<Scalars["String"]>;
  extUserName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  genericUserGroupId?: Maybe<Scalars["Int"]>;
  informSignerAtEnd?: Maybe<Scalars["Boolean"]>;
  informSignerToSign?: Maybe<Scalars["Boolean"]>;
  isDelegate?: Maybe<Scalars["Boolean"]>;
  isExtern?: Maybe<Scalars["Boolean"]>;
  isPlaceholder?: Maybe<Scalars["Boolean"]>;
  isSubstitute?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  mobilePhoneNr?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  portfolioId?: Maybe<Scalars["Int"]>;
  signerFirstName?: Maybe<Scalars["String"]>;
  signerId?: Maybe<Scalars["Int"]>;
  signerLastName?: Maybe<Scalars["String"]>;
  signerName?: Maybe<Scalars["String"]>;
  signerTitle?: Maybe<Scalars["String"]>;
  suppressWfLsgnMail?: Maybe<Scalars["Boolean"]>;
  suppressWlfEndMail?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  userGroupId?: Maybe<Scalars["Int"]>;
  userIdId?: Maybe<Scalars["Int"]>;
  workFlowSignerPatternId?: Maybe<Scalars["Int"]>;
  workFlowSignerTypeId?: Maybe<Scalars["Int"]>;
  workflowStepId?: Maybe<Scalars["Int"]>;
};

export type UserDetails = {
  __typename?: "UserDetails";
  billingAddress?: Maybe<Address>;
  coverage: Scalars["Boolean"];
  dateOfBirth?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  insuranceContact?: Maybe<Scalars["String"]>;
  insuranceName?: Maybe<Scalars["String"]>;
  insuranceType: InsuranceType;
  isAlgeaCare?: Maybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  notificationPreferences: NotificationPreferences;
  permissions: Array<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roles: Array<UserRole>;
  shippingAddress?: Maybe<Address>;
};

export type UserDetailsInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type UserDetailsResponse = {
  __typename?: "UserDetailsResponse";
  user?: Maybe<UserDetails>;
};

export type UserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export enum UserRole {
  Admin = "admin",
  Pharmacist = "pharmacist",
}

export type WinApoInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
};

export type WinApoResponse = {
  __typename?: "WinApoResponse";
  default?: Maybe<Scalars["Boolean"]>;
};

export type ZugangsCode = {
  __typename?: "ZugangsCode";
  analytics?: Maybe<ZugangsCodeAnalytics>;
  availability: ZugangsCodeAvailability;
  code: Scalars["String"];
  createdAt: Scalars["Time"];
  description: Scalars["String"];
  id: Scalars["ID"];
  maxRedeems?: Maybe<Scalars["Int"]>;
  status: ZugangsCodeStatus;
  updatedAt: Scalars["Time"];
  validFrom?: Maybe<Scalars["Time"]>;
  validTo?: Maybe<Scalars["Time"]>;
};

export type ZugangsCodeAnalytics = {
  __typename?: "ZugangsCodeAnalytics";
  redeems: Scalars["Int"];
};

export enum ZugangsCodeAvailability {
  Limited = "limited",
  Unlimited = "unlimited",
}

export type ZugangsCodeInput = {
  availability: ZugangsCodeAvailability;
  code: Scalars["String"];
  description: Scalars["String"];
  maxRedeems?: InputMaybe<Scalars["Int"]>;
  status: ZugangsCodeStatus;
  validFrom?: InputMaybe<Scalars["Time"]>;
  validTo?: InputMaybe<Scalars["Time"]>;
};

export enum ZugangsCodeStatus {
  Active = "active",
  Inactive = "inactive",
}

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountRequest;
}>;

export type CreateAccountMutation = {
  __typename?: "Mutation";
  createAccount: { __typename?: "CreateAccountResponse"; success: boolean };
};

export type LoginMutationVariables = Exact<{
  input: LoginRequest;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: { __typename?: "LoginResponse"; success: boolean };
};

export type UserDetailsQueryVariables = Exact<{
  input: UserDetailsInput;
}>;

export type UserDetailsQuery = {
  __typename?: "Query";
  userDetails: {
    __typename?: "UserDetailsResponse";
    user?: {
      __typename?: "UserDetails";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      permissions: Array<string>;
      roles: Array<UserRole>;
      coverage: boolean;
      insuranceType: InsuranceType;
      dateOfBirth?: string | null;
      isAlgeaCare?: boolean | null;
      insuranceName?: string | null;
      insuranceContact?: string | null;
      notificationPreferences: {
        __typename?: "NotificationPreferences";
        newsletter: boolean;
        notificationType: Array<NotificationType>;
        ticketStatus: boolean;
        orderStatus: boolean;
      };
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
    } | null;
  };
};

export type LogoutMutationVariables = Exact<{
  input: LogoutRequest;
}>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "LogoutResponse"; success: boolean };
};

export type UpdateMyAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;

export type UpdateMyAccountMutation = {
  __typename?: "Mutation";
  updateAccount: { __typename?: "UpdateAccountResponse"; success: boolean };
};

export type DeleteUserAccountMutationVariables = Exact<{
  input: DeleteAccountInput;
}>;

export type DeleteUserAccountMutation = {
  __typename?: "Mutation";
  deleteAccount: { __typename?: "DeleteAccountResponse"; success: boolean };
};

export type UpdateNotificationPreferencesMutationVariables = Exact<{
  input: UpdateNotificationPreferencesInput;
}>;

export type UpdateNotificationPreferencesMutation = {
  __typename?: "Mutation";
  updateNotificationPreferences: {
    __typename?: "UpdateNotificationPreferencesResponse";
    success: boolean;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: { __typename?: "ChangePasswordResponse"; success: boolean };
};

export type RefreshMutationVariables = Exact<{
  input: RefreshTokenInput;
}>;

export type RefreshMutation = {
  __typename?: "Mutation";
  refresh: { __typename?: "RefreshTokenResponse"; success: boolean };
};

export type AvailableOffersQueryVariables = Exact<{
  input: AvailableOffersInput;
}>;

export type AvailableOffersQuery = {
  __typename?: "Query";
  availableOffers: {
    __typename?: "AvailableOffersResponse";
    lowestPrice: number;
    offers: Array<{
      __typename?: "ProviderOffer";
      id: any;
      subTotal: number;
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
      pharmacy: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        subDomain: string;
        email: string;
        phoneNumber?: string | null;
        logoURL?: string | null;
        teamLocationPictureURL?: string | null;
        prescriptionEnvelopeURL?: string | null;
        customShippingDetails?: {
          __typename?: "CustomShippingDetails";
          minPriceToFreeShipment?: number | null;
          flatRate?: number | null;
          pharmacyID: any;
        } | null;
      };
      products: Array<{
        __typename?: "ProviderProduct";
        price: number;
        quantity: number;
        basePrice: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type AvailableDeliveryOptionsQueryVariables = Exact<{
  input: AvailableDeliveryOptionsInput;
}>;

export type AvailableDeliveryOptionsQuery = {
  __typename?: "Query";
  availableDeliveryOptions: {
    __typename?: "AvailableDeliveryOptionsResponse";
    deliveryOptions: Array<{
      __typename?: "DeliveryOption";
      id: any;
      name: string;
      type: DeliveryOptionType;
      price: number;
    }>;
  };
};

export type AvailablePaymentMethodsQueryVariables = Exact<{
  input: AvailablePaymentMethodsInput;
}>;

export type AvailablePaymentMethodsQuery = {
  __typename?: "Query";
  availablePaymentMethods: {
    __typename?: "AvailablePaymentMethodsResponse";
    unzerBaseUrl?: string | null;
    unzerPublicKey?: string | null;
    paymentMethods: Array<{
      __typename?: "PaymentMethod";
      type: string;
      allowsForAuthorization: boolean;
    }>;
  };
};

export type CheckoutFragment = {
  __typename?: "Checkout";
  id: any;
  orderNumber: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userEmail?: string | null;
  userId?: any | null;
  userInsuranceType?: InsuranceType | null;
  dateShippedOn?: any | null;
  dateOrderedOn?: any | null;
  dateDeliveredOn?: any | null;
  noteCount?: number | null;
  invoiceBillingDate?: any | null;
  invoiceNumber?: string | null;
  cancellationInvoiceNumber?: string | null;
  paymentMethod?: string | null;
  InternalInvoiceNumber?: string | null;
  proposedProductsStatus?: CheckoutProposedProductsStatus | null;
  shouldDisableChangeLink?: boolean | null;
  outstandingAmount: number;
  paymentStatus: PaymentStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
  deliveryStatus: DeliveryStatus;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  updatedAt: any;
  createdAt: any;
  status: CheckoutStatus;
  nextStep: CheckoutNextStep;
  subtotal: number;
  subtotalNet: number;
  total: number;
  totalNet: number;
  invoiceLink?: string | null;
  cancellationInvoiceLink?: string | null;
  deliverySlipLink?: string | null;
  shippingLabelLink?: string | null;
  productLabelLink?: string | null;
  productsStatus: CheckoutProductsStatus;
  documentationStatus: CheckoutDocumentationStatus;
  isWaitingForDoctorApproval: boolean;
  areProductsOrdered: boolean;
  shipmentId?: string | null;
  trackingLink?: string | null;
  shipmentWeightInGrams: number;
  isPharmacySignatureNeeded?: boolean | null;
  workflowurl?: string | null;
  secSignPortfolioID?: number | null;
  documentSignatureStatus?: EsignatureStatus | null;
  ePrescriptionDownloadedByUser?: string | null;
  ePrescriptionDownloadedAt?: any | null;
  products: Array<{
    __typename?: "CheckoutProduct";
    quantity: number;
    price: number;
    product: {
      __typename?: "PublicProduct";
      taxClass: TaxClass;
      id: any;
      fullName: string;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      startingPrice?: number | null;
      imageURL: string;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
    };
  }>;
  payments: Array<{
    __typename?: "CheckoutPayment";
    id: string;
    method: string;
    amount: number;
    status: PaymentStatus;
  }>;
  proposedCheckoutDetails?: {
    __typename?: "OriginalCheckoutDetails";
    subtotal: number;
    deliveryFee: number;
    total: number;
    products: Array<{
      __typename?: "CheckoutProduct";
      quantity: number;
      price: number;
      product: {
        __typename?: "PublicProduct";
        taxClass: TaxClass;
        id: any;
        fullName: string;
        description?: string | null;
        startingPrice?: number | null;
        imageURL: string;
        unit: Unit;
        unitValue: number;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
      };
    }>;
    extras: Array<{
      __typename?: "CheckoutExtra";
      name: string;
      description: string;
      quantity: number;
      price: number;
    }>;
  } | null;
  originalCheckoutDetails?: {
    __typename?: "OriginalCheckoutDetails";
    subtotal: number;
    deliveryFee: number;
    total: number;
    products: Array<{
      __typename?: "CheckoutProduct";
      quantity: number;
      price: number;
      product: {
        __typename?: "PublicProduct";
        taxClass: TaxClass;
        id: any;
        fullName: string;
        description?: string | null;
        startingPrice?: number | null;
        imageURL: string;
        unit: Unit;
        unitValue: number;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
      };
    }>;
    extras: Array<{
      __typename?: "CheckoutExtra";
      name: string;
      description: string;
      quantity: number;
      price: number;
    }>;
  } | null;
  prescriptionIssues?: Array<{
    __typename?: "PrescriptionIssue";
    type: PrescriptionIssueType;
    message: string;
  }> | null;
  pharmacy?: {
    __typename?: "PublicPharmacy";
    id: any;
    name: string;
    email: string;
    subDomain: string;
    phoneNumber?: string | null;
  } | null;
  prescriptionData: {
    __typename?: "PrescriptionData";
    prescriptionFilePath?: string | null;
    coverageFilePath?: string | null;
    isAlgeaCare?: boolean | null;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  };
  extras: Array<{
    __typename?: "CheckoutExtra";
    name: string;
    description: string;
    quantity: number;
    price: number;
  }>;
  Prescriptions: Array<{
    __typename?: "Prescription";
    id: string;
    checkoutId: any;
    epDocumentID?: string | null;
    prescriptionData: {
      __typename?: "PrescriptionData";
      prescriptionFilePath?: string | null;
      coverageFilePath?: string | null;
      isAlgeaCare?: boolean | null;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    };
  }>;
  shippingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  deliveryOption?: {
    __typename?: "DeliveryOption";
    id: any;
    name: string;
    type: DeliveryOptionType;
    price: number;
  } | null;
};

export type SingleCheckoutFragment = {
  __typename?: "Checkout";
  id: any;
  orderNumber: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userEmail?: string | null;
  userId?: any | null;
  userInsuranceType?: InsuranceType | null;
  dateShippedOn?: any | null;
  dateOrderedOn?: any | null;
  dateDeliveredOn?: any | null;
  noteCount?: number | null;
  invoiceBillingDate?: any | null;
  invoiceNumber?: string | null;
  cancellationInvoiceNumber?: string | null;
  paymentMethod?: string | null;
  InternalInvoiceNumber?: string | null;
  shouldDisableChangeLink?: boolean | null;
  proposedProductsStatus?: CheckoutProposedProductsStatus | null;
  outstandingAmount: number;
  paymentStatus: PaymentStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
  deliveryStatus: DeliveryStatus;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  updatedAt: any;
  createdAt: any;
  status: CheckoutStatus;
  nextStep: CheckoutNextStep;
  subtotal: number;
  subtotalNet: number;
  total: number;
  totalNet: number;
  invoiceLink?: string | null;
  cancellationInvoiceLink?: string | null;
  deliverySlipLink?: string | null;
  shippingLabelLink?: string | null;
  productLabelLink?: string | null;
  productsStatus: CheckoutProductsStatus;
  documentationStatus: CheckoutDocumentationStatus;
  isWaitingForDoctorApproval: boolean;
  areProductsOrdered: boolean;
  shipmentId?: string | null;
  trackingLink?: string | null;
  shipmentWeightInGrams: number;
  isPharmacySignatureNeeded?: boolean | null;
  workflowurl?: string | null;
  secSignPortfolioID?: number | null;
  ePrescriptionDownloadedByUser?: string | null;
  ePrescriptionDownloadedAt?: any | null;
  documentSignatureStatus?: EsignatureStatus | null;
  products: Array<{
    __typename?: "CheckoutProduct";
    quantity: number;
    price: number;
    product: {
      __typename?: "PublicProduct";
      taxClass: TaxClass;
      id: any;
      fullName: string;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      startingPrice?: number | null;
      imageURL: string;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
    };
  }>;
  proposedCheckoutDetails?: {
    __typename?: "OriginalCheckoutDetails";
    subtotal: number;
    deliveryFee: number;
    total: number;
    products: Array<{
      __typename?: "CheckoutProduct";
      quantity: number;
      price: number;
      product: {
        __typename?: "PublicProduct";
        taxClass: TaxClass;
        id: any;
        fullName: string;
        description?: string | null;
        startingPrice?: number | null;
        imageURL: string;
        unit: Unit;
        unitValue: number;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
      };
    }>;
    extras: Array<{
      __typename?: "CheckoutExtra";
      name: string;
      description: string;
      quantity: number;
      price: number;
    }>;
  } | null;
  payments: Array<{
    __typename?: "CheckoutPayment";
    id: string;
    method: string;
    amount: number;
    status: PaymentStatus;
  }>;
  originalCheckoutDetails?: {
    __typename?: "OriginalCheckoutDetails";
    subtotal: number;
    deliveryFee: number;
    total: number;
    products: Array<{
      __typename?: "CheckoutProduct";
      quantity: number;
      price: number;
      product: {
        __typename?: "PublicProduct";
        taxClass: TaxClass;
        id: any;
        fullName: string;
        description?: string | null;
        startingPrice?: number | null;
        imageURL: string;
        unit: Unit;
        unitValue: number;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
      };
    }>;
    extras: Array<{
      __typename?: "CheckoutExtra";
      name: string;
      description: string;
      quantity: number;
      price: number;
    }>;
  } | null;
  prescriptionIssues?: Array<{
    __typename?: "PrescriptionIssue";
    type: PrescriptionIssueType;
    message: string;
  }> | null;
  pharmacy?: {
    __typename?: "PublicPharmacy";
    id: any;
    name: string;
    email: string;
    subDomain: string;
    teamLocationPictureURL?: string | null;
    prescriptionEnvelopeURL?: string | null;
    logoURL?: string | null;
    website?: string | null;
    openingHours?: string | null;
    dataProtectionOfficerEmail?: string | null;
    dataProtectionOfficerName?: string | null;
    useOrderConfirmationInsteadOfInvoice?: boolean | null;
    phoneNumber?: string | null;
    paymentMethods?: Array<{
      __typename?: "PharmacyPaymentMethodOptions";
      method: PharmacyPaymentOption;
      message?: string | null;
    }> | null;
    bankInfo?: {
      __typename?: "BankResponse";
      owner: string;
      iban: string;
      bic?: string | null;
      bank?: string | null;
    } | null;
    address?: {
      __typename?: "Address";
      id: any;
      street: string;
      houseNumber: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      createdAt: any;
      updatedAt: any;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    } | null;
  } | null;
  billingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  prescriptionData: {
    __typename?: "PrescriptionData";
    prescriptionFilePath?: string | null;
    coverageFilePath?: string | null;
    isAlgeaCare?: boolean | null;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  };
  extras: Array<{
    __typename?: "CheckoutExtra";
    name: string;
    description: string;
    quantity: number;
    price: number;
  }>;
  Prescriptions: Array<{
    __typename?: "Prescription";
    id: string;
    checkoutId: any;
    epDocumentID?: string | null;
    prescriptionData: {
      __typename?: "PrescriptionData";
      prescriptionFilePath?: string | null;
      coverageFilePath?: string | null;
      isAlgeaCare?: boolean | null;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    };
  }>;
  shippingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  deliveryOption?: {
    __typename?: "DeliveryOption";
    id: any;
    name: string;
    type: DeliveryOptionType;
    price: number;
  } | null;
};

export type WebshopCheckoutFragment = {
  __typename?: "Checkout";
  id: any;
  orderNumber: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userEmail?: string | null;
  userId?: any | null;
  userInsuranceType?: InsuranceType | null;
  dateShippedOn?: any | null;
  dateOrderedOn?: any | null;
  dateDeliveredOn?: any | null;
  noteCount?: number | null;
  invoiceBillingDate?: any | null;
  invoiceNumber?: string | null;
  cancellationInvoiceNumber?: string | null;
  paymentMethod?: string | null;
  InternalInvoiceNumber?: string | null;
  shouldDisableChangeLink?: boolean | null;
  outstandingAmount: number;
  paymentStatus: PaymentStatus;
  prescriptionOnlineStatus: PrescriptionOnlineStatus;
  deliveryStatus: DeliveryStatus;
  prescriptionOfflineStatus: PrescriptionOfflineStatus;
  updatedAt: any;
  createdAt: any;
  status: CheckoutStatus;
  nextStep: CheckoutNextStep;
  subtotal: number;
  subtotalNet: number;
  total: number;
  totalNet: number;
  invoiceLink?: string | null;
  cancellationInvoiceLink?: string | null;
  deliverySlipLink?: string | null;
  shippingLabelLink?: string | null;
  productLabelLink?: string | null;
  productsStatus: CheckoutProductsStatus;
  documentationStatus: CheckoutDocumentationStatus;
  isWaitingForDoctorApproval: boolean;
  areProductsOrdered: boolean;
  shipmentId?: string | null;
  trackingLink?: string | null;
  shipmentWeightInGrams: number;
  isPharmacySignatureNeeded?: boolean | null;
  workflowurl?: string | null;
  secSignPortfolioID?: number | null;
  ePrescriptionDownloadedByUser?: string | null;
  ePrescriptionDownloadedAt?: any | null;
  documentSignatureStatus?: EsignatureStatus | null;
  products: Array<{
    __typename?: "CheckoutProduct";
    quantity: number;
    price: number;
    product: {
      __typename?: "PublicProduct";
      taxClass: TaxClass;
      id: any;
      fullName: string;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      startingPrice?: number | null;
      imageURL: string;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
    };
  }>;
  payments: Array<{
    __typename?: "CheckoutPayment";
    id: string;
    method: string;
    amount: number;
    status: PaymentStatus;
  }>;
  originalCheckoutDetails?: {
    __typename?: "OriginalCheckoutDetails";
    subtotal: number;
    deliveryFee: number;
    total: number;
    products: Array<{
      __typename?: "CheckoutProduct";
      quantity: number;
      price: number;
      product: {
        __typename?: "PublicProduct";
        taxClass: TaxClass;
        id: any;
        fullName: string;
        description?: string | null;
        startingPrice?: number | null;
        imageURL: string;
        unit: Unit;
        unitValue: number;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
      };
    }>;
    extras: Array<{
      __typename?: "CheckoutExtra";
      name: string;
      description: string;
      quantity: number;
      price: number;
    }>;
  } | null;
  prescriptionIssues?: Array<{
    __typename?: "PrescriptionIssue";
    type: PrescriptionIssueType;
    message: string;
  }> | null;
  pharmacy?: {
    __typename?: "PublicPharmacy";
    id: any;
    name: string;
    email: string;
    subDomain: string;
    teamLocationPictureURL?: string | null;
    prescriptionEnvelopeURL?: string | null;
    logoURL?: string | null;
    website?: string | null;
    openingHours?: string | null;
    agb?: string | null;
    description?: string | null;
    imprint?: string | null;
    privacyPolicy?: string | null;
    dataProtectionOfficerEmail?: string | null;
    dataProtectionOfficerName?: string | null;
    useOrderConfirmationInsteadOfInvoice?: boolean | null;
    phoneNumber?: string | null;
    paymentMethods?: Array<{
      __typename?: "PharmacyPaymentMethodOptions";
      method: PharmacyPaymentOption;
      message?: string | null;
    }> | null;
    bankInfo?: {
      __typename?: "BankResponse";
      owner: string;
      iban: string;
      bic?: string | null;
      bank?: string | null;
    } | null;
    address?: {
      __typename?: "Address";
      id: any;
      street: string;
      houseNumber: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      createdAt: any;
      updatedAt: any;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    } | null;
  } | null;
  billingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  prescriptionData: {
    __typename?: "PrescriptionData";
    prescriptionFilePath?: string | null;
    coverageFilePath?: string | null;
    isAlgeaCare?: boolean | null;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  };
  extras: Array<{
    __typename?: "CheckoutExtra";
    name: string;
    description: string;
    quantity: number;
    price: number;
  }>;
  Prescriptions: Array<{
    __typename?: "Prescription";
    id: string;
    checkoutId: any;
    epDocumentID?: string | null;
    prescriptionData: {
      __typename?: "PrescriptionData";
      prescriptionFilePath?: string | null;
      coverageFilePath?: string | null;
      isAlgeaCare?: boolean | null;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    };
  }>;
  shippingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  deliveryOption?: {
    __typename?: "DeliveryOption";
    id: any;
    name: string;
    type: DeliveryOptionType;
    price: number;
  } | null;
};

export type UpdateCheckoutMutationVariables = Exact<{
  input: UpdateCheckoutInput;
}>;

export type UpdateCheckoutMutation = {
  __typename?: "Mutation";
  updateCheckout: {
    __typename?: "UpdateCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type PreliminaryCheckoutQueryVariables = Exact<{
  input: CheckoutPreviewNewCartInput;
}>;

export type PreliminaryCheckoutQuery = {
  __typename?: "Query";
  checkoutPreviewNewCart: {
    __typename?: "CheckoutPreviewNewCartResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CancelCheckoutMutationVariables = Exact<{
  input: CheckoutCancelInput;
}>;

export type CancelCheckoutMutation = {
  __typename?: "Mutation";
  cancelCheckout: {
    __typename?: "CheckoutCancelResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CreateCheckoutMutationVariables = Exact<{
  input: CreateCheckoutInput;
}>;

export type CreateCheckoutMutation = {
  __typename?: "Mutation";
  createCheckout: {
    __typename?: "CreateCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutUpdateAddressesMutationVariables = Exact<{
  input: CheckoutUpdateAddressesInput;
}>;

export type CheckoutUpdateAddressesMutation = {
  __typename?: "Mutation";
  checkoutUpdateAddresses: {
    __typename?: "CheckoutUpdateAddressesResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutUpdatPrescriptionDataMutationVariables = Exact<{
  input: CheckoutUpdatePrescriptionDataInput;
}>;

export type CheckoutUpdatPrescriptionDataMutation = {
  __typename?: "Mutation";
  checkoutUpdatePrescriptionData: {
    __typename?: "CheckoutUpdatePrescriptionDataResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutUpdateDeliveryOptionMutationVariables = Exact<{
  input: CheckoutUpdateDeliveryOptionInput;
}>;

export type CheckoutUpdateDeliveryOptionMutation = {
  __typename?: "Mutation";
  checkoutUpdateDeliveryOption: {
    __typename?: "CheckoutUpdateDeliveryOptionResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutCustomerDetailsStatusMutationVariables = Exact<{
  input: CheckoutCustomerDetailsStatusUpdateInput;
}>;

export type UpdateCheckoutCustomerDetailsStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutCustomerDetailsStatus: {
    __typename?: "CheckoutCustomerDetailsStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutProductStatusMutationVariables = Exact<{
  input: CheckoutProductStatusUpdateInput;
}>;

export type UpdateCheckoutProductStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutProductStatus: {
    __typename?: "CheckoutProductStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutDocumentationStatusMutationVariables = Exact<{
  input: CheckoutDocumentationStatusUpdateInput;
}>;

export type UpdateCheckoutDocumentationStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutDocumentationStatus: {
    __typename?: "CheckoutDocumentationStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutWaitingForDoctorApprovalMutationVariables = Exact<{
  input: ToggleWaitingForDoctorApprovalInput;
}>;

export type UpdateCheckoutWaitingForDoctorApprovalMutation = {
  __typename?: "Mutation";
  updateCheckoutWaitingForDoctorApproval: {
    __typename?: "ToggleWaitingForDoctorApprovalResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateUpdateCheckoutProductsOrderedMutationVariables = Exact<{
  input: ProductsOrderedInput;
}>;

export type UpdateUpdateCheckoutProductsOrderedMutation = {
  __typename?: "Mutation";
  updateUpdateCheckoutProductsOrdered: {
    __typename?: "ProductsOrderedResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutPaymentMutationVariables = Exact<{
  input: CheckoutPaymentInput;
}>;

export type CheckoutPaymentMutation = {
  __typename?: "Mutation";
  checkoutPayment:
    | { __typename?: "CheckoutPaymentRedirectResponse"; redirectUrl: string }
    | { __typename?: "CheckoutPaymentSuccessResponse"; success: boolean };
};

export type CalculatePharmacyCapacityMutationVariables = Exact<{
  input: CalculatePharmacyCapacityInput;
}>;

export type CalculatePharmacyCapacityMutation = {
  __typename?: "Mutation";
  calculatePharmacyCapacity: { __typename?: "CalculatePharmacyCapacityResponse"; success: boolean };
};

export type FilterCheckoutsQueryVariables = Exact<{
  input: FilterCheckoutsInput;
}>;

export type FilterCheckoutsQuery = {
  __typename?: "Query";
  filterCheckouts: {
    __typename?: "FilterCheckoutsResponse";
    results: Array<{
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type FilterWebshopCheckoutsQueryVariables = Exact<{
  input: FilterCheckoutsInput;
}>;

export type FilterWebshopCheckoutsQuery = {
  __typename?: "Query";
  filterCheckouts: {
    __typename?: "FilterCheckoutsResponse";
    results: Array<{
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      documentSignatureStatus?: EsignatureStatus | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        teamLocationPictureURL?: string | null;
        prescriptionEnvelopeURL?: string | null;
        logoURL?: string | null;
        website?: string | null;
        openingHours?: string | null;
        agb?: string | null;
        description?: string | null;
        imprint?: string | null;
        privacyPolicy?: string | null;
        dataProtectionOfficerEmail?: string | null;
        dataProtectionOfficerName?: string | null;
        useOrderConfirmationInsteadOfInvoice?: boolean | null;
        phoneNumber?: string | null;
        paymentMethods?: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }> | null;
        bankInfo?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        address?: {
          __typename?: "Address";
          id: any;
          street: string;
          houseNumber: string;
          city: string;
          state: string;
          zip: string;
          country: string;
          createdAt: any;
          updatedAt: any;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        } | null;
      } | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type FilterSingleCheckoutQueryVariables = Exact<{
  input: FilterCheckoutsInput;
}>;

export type FilterSingleCheckoutQuery = {
  __typename?: "Query";
  filterCheckouts: {
    __typename?: "FilterCheckoutsResponse";
    results: Array<{
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      shouldDisableChangeLink?: boolean | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      documentSignatureStatus?: EsignatureStatus | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        teamLocationPictureURL?: string | null;
        prescriptionEnvelopeURL?: string | null;
        logoURL?: string | null;
        website?: string | null;
        openingHours?: string | null;
        dataProtectionOfficerEmail?: string | null;
        dataProtectionOfficerName?: string | null;
        useOrderConfirmationInsteadOfInvoice?: boolean | null;
        phoneNumber?: string | null;
        paymentMethods?: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }> | null;
        bankInfo?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        address?: {
          __typename?: "Address";
          id: any;
          street: string;
          houseNumber: string;
          city: string;
          state: string;
          zip: string;
          country: string;
          createdAt: any;
          updatedAt: any;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        } | null;
      } | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type FilterCheckoutsCountQueryVariables = Exact<{
  input: FilterCheckoutsCountInput;
}>;

export type FilterCheckoutsCountQuery = {
  __typename?: "Query";
  filterCheckoutsCount: {
    __typename?: "FilterCheckoutsCountResponse";
    results: Array<{ __typename?: "StatusCounts"; status: string; count: number }>;
  };
};

export type MetaFieldsFragment = {
  __typename?: "MetaField";
  name: string;
  label: string;
  value: string;
  type: MetaFieldType;
};

export type CheckoutQueryVariables = Exact<{
  input: GetCheckoutInput;
}>;

export type CheckoutQuery = {
  __typename?: "Query";
  checkout: {
    __typename?: "GetCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      documentSignatureStatus?: EsignatureStatus | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        teamLocationPictureURL?: string | null;
        prescriptionEnvelopeURL?: string | null;
        logoURL?: string | null;
        website?: string | null;
        openingHours?: string | null;
        agb?: string | null;
        description?: string | null;
        imprint?: string | null;
        privacyPolicy?: string | null;
        dataProtectionOfficerEmail?: string | null;
        dataProtectionOfficerName?: string | null;
        useOrderConfirmationInsteadOfInvoice?: boolean | null;
        phoneNumber?: string | null;
        paymentMethods?: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }> | null;
        bankInfo?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        address?: {
          __typename?: "Address";
          id: any;
          street: string;
          houseNumber: string;
          city: string;
          state: string;
          zip: string;
          country: string;
          createdAt: any;
          updatedAt: any;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        } | null;
      } | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutDownloadQueryVariables = Exact<{
  input: GetCheckoutInput;
}>;

export type CheckoutDownloadQuery = {
  __typename?: "Query";
  checkout: {
    __typename?: "GetCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      invoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      cancellationInvoiceLink?: string | null;
      productLabelLink?: string | null;
      shipmentId?: string | null;
      status: CheckoutStatus;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        useOrderConfirmationInsteadOfInvoice?: boolean | null;
      } | null;
    };
  };
};

export type FilterCheckoutGroupsQueryVariables = Exact<{
  input: FilterCheckoutsInput;
}>;

export type FilterCheckoutGroupsQuery = {
  __typename?: "Query";
  filterCheckoutGroups: {
    __typename?: "FilterCheckoutGroupsResponse";
    results: Array<{
      __typename?: "OrderGroup";
      id: number;
      groupStatus: EsignatureStatus;
      isLocked?: boolean | null;
      createdAt: any;
      updatedAt: any;
      ordersCount?: number | null;
      checkouts?: Array<{
        __typename?: "Checkout";
        id: any;
        orderNumber: string;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userEmail?: string | null;
        userId?: any | null;
        userInsuranceType?: InsuranceType | null;
        dateShippedOn?: any | null;
        dateOrderedOn?: any | null;
        dateDeliveredOn?: any | null;
        noteCount?: number | null;
        invoiceBillingDate?: any | null;
        invoiceNumber?: string | null;
        cancellationInvoiceNumber?: string | null;
        paymentMethod?: string | null;
        InternalInvoiceNumber?: string | null;
        proposedProductsStatus?: CheckoutProposedProductsStatus | null;
        shouldDisableChangeLink?: boolean | null;
        outstandingAmount: number;
        paymentStatus: PaymentStatus;
        prescriptionOnlineStatus: PrescriptionOnlineStatus;
        deliveryStatus: DeliveryStatus;
        prescriptionOfflineStatus: PrescriptionOfflineStatus;
        updatedAt: any;
        createdAt: any;
        status: CheckoutStatus;
        nextStep: CheckoutNextStep;
        subtotal: number;
        subtotalNet: number;
        total: number;
        totalNet: number;
        invoiceLink?: string | null;
        cancellationInvoiceLink?: string | null;
        deliverySlipLink?: string | null;
        shippingLabelLink?: string | null;
        productLabelLink?: string | null;
        productsStatus: CheckoutProductsStatus;
        documentationStatus: CheckoutDocumentationStatus;
        isWaitingForDoctorApproval: boolean;
        areProductsOrdered: boolean;
        shipmentId?: string | null;
        trackingLink?: string | null;
        shipmentWeightInGrams: number;
        isPharmacySignatureNeeded?: boolean | null;
        workflowurl?: string | null;
        secSignPortfolioID?: number | null;
        documentSignatureStatus?: EsignatureStatus | null;
        ePrescriptionDownloadedByUser?: string | null;
        ePrescriptionDownloadedAt?: any | null;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            unit: Unit;
            unitValue: number;
            startingPrice?: number | null;
            imageURL: string;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            strain: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        payments: Array<{
          __typename?: "CheckoutPayment";
          id: string;
          method: string;
          amount: number;
          status: PaymentStatus;
        }>;
        proposedCheckoutDetails?: {
          __typename?: "OriginalCheckoutDetails";
          subtotal: number;
          deliveryFee: number;
          total: number;
          products: Array<{
            __typename?: "CheckoutProduct";
            quantity: number;
            price: number;
            product: {
              __typename?: "PublicProduct";
              taxClass: TaxClass;
              id: any;
              fullName: string;
              description?: string | null;
              startingPrice?: number | null;
              imageURL: string;
              unit: Unit;
              unitValue: number;
              metaFields: Array<{
                __typename?: "MetaField";
                name: string;
                label: string;
                value: string;
                type: MetaFieldType;
              }>;
              availability: {
                __typename?: "PublicAvailability";
                currentAvailability: ProductAvailabilityOptions;
                availableInDaysFrom?: number | null;
                availableInDaysTo?: number | null;
                dateAvailableFrom?: any | null;
              };
            };
          }>;
          extras: Array<{
            __typename?: "CheckoutExtra";
            name: string;
            description: string;
            quantity: number;
            price: number;
          }>;
        } | null;
        originalCheckoutDetails?: {
          __typename?: "OriginalCheckoutDetails";
          subtotal: number;
          deliveryFee: number;
          total: number;
          products: Array<{
            __typename?: "CheckoutProduct";
            quantity: number;
            price: number;
            product: {
              __typename?: "PublicProduct";
              taxClass: TaxClass;
              id: any;
              fullName: string;
              description?: string | null;
              startingPrice?: number | null;
              imageURL: string;
              unit: Unit;
              unitValue: number;
              metaFields: Array<{
                __typename?: "MetaField";
                name: string;
                label: string;
                value: string;
                type: MetaFieldType;
              }>;
              availability: {
                __typename?: "PublicAvailability";
                currentAvailability: ProductAvailabilityOptions;
                availableInDaysFrom?: number | null;
                availableInDaysTo?: number | null;
                dateAvailableFrom?: any | null;
              };
            };
          }>;
          extras: Array<{
            __typename?: "CheckoutExtra";
            name: string;
            description: string;
            quantity: number;
            price: number;
          }>;
        } | null;
        prescriptionIssues?: Array<{
          __typename?: "PrescriptionIssue";
          type: PrescriptionIssueType;
          message: string;
        }> | null;
        pharmacy?: {
          __typename?: "PublicPharmacy";
          id: any;
          name: string;
          email: string;
          subDomain: string;
          phoneNumber?: string | null;
        } | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
        Prescriptions: Array<{
          __typename?: "Prescription";
          id: string;
          checkoutId: any;
          epDocumentID?: string | null;
          prescriptionData: {
            __typename?: "PrescriptionData";
            prescriptionFilePath?: string | null;
            coverageFilePath?: string | null;
            isAlgeaCare?: boolean | null;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
          };
        }>;
        shippingAddress?: {
          __typename?: "Address";
          id: any;
          street: string;
          houseNumber: string;
          city: string;
          state: string;
          zip: string;
          country: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        } | null;
        deliveryOption?: {
          __typename?: "DeliveryOption";
          id: any;
          name: string;
          type: DeliveryOptionType;
          price: number;
        } | null;
      } | null> | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type ProcessBulkESignatureMutationVariables = Exact<{
  input: BulkESignatureInput;
}>;

export type ProcessBulkESignatureMutation = {
  __typename?: "Mutation";
  processBulkESignature: {
    __typename?: "BulkESignatureResponse";
    checkout?: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    } | null;
  };
};

export type DeleteCheckoutMutationVariables = Exact<{
  input: DeleteCheckoutInput;
}>;

export type DeleteCheckoutMutation = {
  __typename?: "Mutation";
  deleteCheckout: { __typename?: "DeleteCheckoutResponse"; success: boolean };
};

export type UpdateCheckoutPaymentMethodMutationVariables = Exact<{
  input: CheckoutUpdatePaymentMethodAdminInput;
}>;

export type UpdateCheckoutPaymentMethodMutation = {
  __typename?: "Mutation";
  updateCheckoutPaymentMethod: {
    __typename?: "CheckoutUpdatePaymentMethodAdminResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CheckoutCapturePaymentMutationVariables = Exact<{
  input: CheckoutCapturePaymentInput;
}>;

export type CheckoutCapturePaymentMutation = {
  __typename?: "Mutation";
  checkoutCapturePayment: {
    __typename?: "CheckoutCapturePaymentResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutShippingWeightMutationVariables = Exact<{
  input: CheckoutShippingWeightInGramsUpdateInput;
}>;

export type UpdateCheckoutShippingWeightMutation = {
  __typename?: "Mutation";
  updateCheckoutShippingWeight: {
    __typename?: "CheckoutShippingWeightInGramsUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type FilterCustomersQueryVariables = Exact<{
  input: FilterCustomersInput;
}>;

export type FilterCustomersQuery = {
  __typename?: "Query";
  filterCustomers: {
    __typename?: "FilterCustomersResponse";
    results: Array<{
      __typename?: "Customer";
      insuranceType: string;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      isAlgeaCare?: boolean | null;
      coverage: boolean;
      numOrders: number;
      dateOfBirth?: string | null;
      createdAt: any;
      billingAddress?: {
        __typename?: "Address";
        state: string;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        zip: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      shippingAddress?: {
        __typename?: "Address";
        state: string;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        zip: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      outstandingOrders: Array<{
        __typename?: "OrderInfo";
        status: CheckoutStatus;
        subtotal: number;
        total: number;
        totalNet: number;
        trackingLink?: string | null;
        userEmail?: string | null;
        userId?: any | null;
        userInsuranceType?: InsuranceType | null;
        userName?: string | null;
        productsStatus: CheckoutProductsStatus;
        outstandingAmount: number;
        orderNumber: string;
        nextStep: CheckoutNextStep;
        paymentStatus: PaymentStatus;
        id: any;
        dateOrderedOn?: any | null;
        dateShippedOn?: any | null;
        dateDeliveredOn?: any | null;
        createdAt: any;
        prescriptionOfflineStatus: PrescriptionOfflineStatus;
        prescriptionOnlineStatus: PrescriptionOnlineStatus;
        isWaitingForDoctorApproval: boolean;
        areProductsOrdered: boolean;
        documentationStatus: CheckoutDocumentationStatus;
        deliveryStatus: DeliveryStatus;
        shipmentId?: string | null;
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      total: number;
      nextCursor?: string | null;
    };
  };
};

export type GetDashboardDataQueryVariables = Exact<{
  input: DashboardDataInput;
}>;

export type GetDashboardDataQuery = {
  __typename?: "Query";
  dashboardData: {
    __typename?: "DashboardDataResponse";
    productData: Array<{
      __typename?: "DashboardDataPerProduct";
      data: {
        __typename?: "DashboardProductData";
        amountOfOrders: number;
        averageOrderQuantity: number;
        averageOrderRevenue: number;
        revenue: number;
        totalVolume: number;
      };
      previousData: {
        __typename?: "DashboardProductData";
        amountOfOrders: number;
        averageOrderQuantity: number;
        averageOrderRevenue: number;
        revenue: number;
        totalVolume: number;
      };
      product: {
        __typename?: "PublicProduct";
        startingPrice?: number | null;
        startingPriceIsInsurance?: boolean | null;
        taxClass: TaxClass;
        id: any;
        fullName: string;
        unit: Unit;
        uniqueProductIdentifiers?: string | null;
        specialProductIdentifier?: string | null;
        pharmacyProductIDs?: string | null;
        generalMarketAvailability?: boolean | null;
        cbdQuantityTo?: number | null;
        cbdQuantityFrom?: number | null;
        thcQuantityFrom?: number | null;
        thcQuantityTo?: number | null;
        averageMarketPrice?: number | null;
        iliosSalesPrice?: number | null;
        basePrice?: number | null;
        unitValue: number;
        category: ProductCategory;
        description?: string | null;
        imageURL: string;
        terpenesProfileText: string;
        medicalEffectText?: string | null;
        slotOrder?: number | null;
        strain: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        genetics: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        manufacturer: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        irradiation: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        availability: {
          __typename?: "PublicAvailability";
          currentAvailability: ProductAvailabilityOptions;
          availableInDaysFrom?: number | null;
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
        };
        wholesaler: {
          __typename?: "Label";
          id: number;
          namespace: string;
          name: string;
          imageURL?: string | null;
        };
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          value: string;
          label: string;
          type: MetaFieldType;
        }>;
        terpenes: Array<{
          __typename?: "Terpene";
          id: string;
          productId: string;
          contributionPercentage?: number | null;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
        medicalEffects: Array<{
          __typename?: "MedicalEffect";
          id: string;
          labelId?: string | null;
          productId: string;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
      };
    }>;
    data: Array<{
      __typename?: "DashboardDataPerPharmacy";
      pharmacy: {
        __typename?: "Pharmacy";
        name: string;
        status: PharmacyStatus;
        pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
        mediumCapacity?: number | null;
        heavyCapacity?: number | null;
      };
      data: {
        __typename?: "DashboardData";
        amountOfOrders: {
          __typename?: "AmountOfOrders";
          total: number;
          pending: number;
          fromAlgaeCare: number;
          completed: number;
          cancelled: number;
          open: number;
          orderApproved: number;
          adjustments: number;
          shippedPickup: number;
          waitingForApproval: number;
          waitingForProcessing: number;
          processing: number;
        };
        revenue: {
          __typename?: "Revenue";
          total: number;
          averageOrderValue: number;
          averageOrderValueFromAlgaeCare: number;
          totalFromAlgaeCare: number;
        };
        flowersSold: {
          __typename?: "FlowersSold";
          total: number;
          averageOrderValue: number;
          averageOrderValueIlios: number;
          totalIlios: number;
        };
        customers: { __typename?: "Customers"; total: number; new: number };
      };
    }>;
    total: {
      __typename?: "DashboardData";
      amountOfOrders: {
        __typename?: "AmountOfOrders";
        total: number;
        pending: number;
        fromAlgaeCare: number;
        completed: number;
        cancelled: number;
        open: number;
        orderApproved: number;
        adjustments: number;
        shippedPickup: number;
        waitingForApproval: number;
        waitingForProcessing: number;
        processing: number;
      };
      revenue: {
        __typename?: "Revenue";
        total: number;
        averageOrderValue: number;
        averageOrderValueFromAlgaeCare: number;
        totalFromAlgaeCare: number;
      };
      flowersSold: {
        __typename?: "FlowersSold";
        total: number;
        averageOrderValue: number;
        averageOrderValueIlios: number;
        totalIlios: number;
      };
      customers: { __typename?: "Customers"; total: number; new: number };
    };
    previousTotal: {
      __typename?: "DashboardData";
      amountOfOrders: {
        __typename?: "AmountOfOrders";
        total: number;
        pending: number;
        fromAlgaeCare: number;
        completed: number;
        cancelled: number;
        open: number;
        orderApproved: number;
        adjustments: number;
        shippedPickup: number;
        waitingForApproval: number;
        waitingForProcessing: number;
        processing: number;
      };
      revenue: {
        __typename?: "Revenue";
        total: number;
        averageOrderValue: number;
        averageOrderValueFromAlgaeCare: number;
        totalFromAlgaeCare: number;
      };
      flowersSold: {
        __typename?: "FlowersSold";
        total: number;
        averageOrderValue: number;
        averageOrderValueIlios: number;
        totalIlios: number;
      };
      customers: { __typename?: "Customers"; total: number; new: number };
    };
  };
};

export type AmountOfOrdersFragment = {
  __typename?: "AmountOfOrders";
  total: number;
  pending: number;
  fromAlgaeCare: number;
  completed: number;
  cancelled: number;
  open: number;
  orderApproved: number;
  adjustments: number;
  shippedPickup: number;
  waitingForApproval: number;
  waitingForProcessing: number;
  processing: number;
};

export type RevenueFragment = {
  __typename?: "Revenue";
  total: number;
  averageOrderValue: number;
  averageOrderValueFromAlgaeCare: number;
  totalFromAlgaeCare: number;
};

export type FlowersSoldFragment = {
  __typename?: "FlowersSold";
  total: number;
  averageOrderValue: number;
  averageOrderValueIlios: number;
  totalIlios: number;
};

export type CustomersFragment = { __typename?: "Customers"; total: number; new: number };

export type GetEventsQueryVariables = Exact<{
  request: EventsInput;
}>;

export type GetEventsQuery = {
  __typename?: "Query";
  events: {
    __typename?: "EventsResponse";
    nextCursor?: string | null;
    result: Array<{
      __typename?: "Event";
      id: string;
      status: EventStatus;
      date: any;
      description: string;
    }>;
  };
};

export type InfoQueryVariables = Exact<{
  input: InfoInput;
}>;

export type InfoQuery = {
  __typename?: "Query";
  info: {
    __typename?: "Info";
    version: string;
    name: string;
    description: string;
    lastSupportedAppVersion?: string | null;
  };
};

export type AuditLogsQueryVariables = Exact<{
  input: AuditLogInput;
}>;

export type AuditLogsQuery = {
  __typename?: "Query";
  auditLogs: {
    __typename?: "AuditLogResponse";
    results: Array<{
      __typename?: "AuditLog";
      title: string;
      details?: string | null;
      itemId: string;
      newValues?: any | null;
      itemType: string;
      date: any;
      userId?: string | null;
      userName?: string | null;
      userEmail?: string | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type LabelsQueryVariables = Exact<{
  input?: InputMaybe<FilterLabelInput>;
}>;

export type LabelsQuery = {
  __typename?: "Query";
  labels: {
    __typename?: "FilterLabelResponse";
    results: Array<{
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type LabelFragment = {
  __typename?: "Label";
  id: number;
  name: string;
  namespace: string;
  imageURL?: string | null;
};

export type CreateLabelMutationVariables = Exact<{
  input: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: "Mutation";
  createLabel: {
    __typename?: "CreateLabelResponse";
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  };
};

export type UpdateLabelMutationVariables = Exact<{
  input: UpdateLabelInput;
}>;

export type UpdateLabelMutation = {
  __typename?: "Mutation";
  updateLabel: {
    __typename?: "UpdateLabelResponse";
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  };
};

export type DeleteLabelMutationVariables = Exact<{
  input: DeleteLabelInput;
}>;

export type DeleteLabelMutation = {
  __typename?: "Mutation";
  deleteLabel: { __typename?: "DeleteLabelResponse"; success: boolean; message: string };
};

export type CreateManualCheckoutMutationVariables = Exact<{
  input: CreateManualCheckoutInput;
}>;

export type CreateManualCheckoutMutation = {
  __typename?: "Mutation";
  createManualCheckout: {
    __typename?: "CreateCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateManualCheckoutMutationVariables = Exact<{
  input: UpdateManualCheckoutInput;
}>;

export type UpdateManualCheckoutMutation = {
  __typename?: "Mutation";
  updateManualCheckout: {
    __typename?: "CreateCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type SubmitManualCheckoutMutationVariables = Exact<{
  input: SubmitManualCheckoutInput;
}>;

export type SubmitManualCheckoutMutation = {
  __typename?: "Mutation";
  submitManualCheckout: {
    __typename?: "CreateCheckoutResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type CreateNcmMutationVariables = Exact<{
  input: CreateNcmInput;
}>;

export type CreateNcmMutation = {
  __typename?: "Mutation";
  createNcm: { __typename?: "CreateNcmResponse"; result: boolean };
};

export type UpdateNcmMutationVariables = Exact<{
  input: UpdateNcmInput;
}>;

export type UpdateNcmMutation = {
  __typename?: "Mutation";
  updateNcm: {
    __typename?: "UpdateNcmResponse";
    result: {
      __typename?: "NcmDetails";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt: any;
      createdAt: any;
      status: NcmStatus;
      completedDate?: any | null;
      statusUser: NcmStatusUser;
      insuranceType?: InsuranceType | null;
      isAlgeaCare?: boolean | null;
      userId?: string | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        zip: string;
        state: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionMetaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
      Prescriptions: Array<{
        __typename?: "PrescriptionNcm";
        id: string;
        ncmId: any;
        prescriptionFilePath?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
    };
  };
};

export type CompleteNcmMutationVariables = Exact<{
  input: CompleteNcmInput;
}>;

export type CompleteNcmMutation = {
  __typename?: "Mutation";
  completeNCM: {
    __typename?: "CompleteNCMResponse";
    result: {
      __typename?: "NcmDetails";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt: any;
      createdAt: any;
      status: NcmStatus;
      completedDate?: any | null;
      statusUser: NcmStatusUser;
      insuranceType?: InsuranceType | null;
      isAlgeaCare?: boolean | null;
      userId?: string | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        zip: string;
        state: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionMetaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
      Prescriptions: Array<{
        __typename?: "PrescriptionNcm";
        id: string;
        ncmId: any;
        prescriptionFilePath?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
    };
  };
};

export type DeleteNcmMutationVariables = Exact<{
  input: DeleteNcmInput;
}>;

export type DeleteNcmMutation = {
  __typename?: "Mutation";
  deleteNCM: {
    __typename?: "DeleteNcmResponse";
    result: {
      __typename?: "NcmDetails";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt: any;
      createdAt: any;
      status: NcmStatus;
      completedDate?: any | null;
      statusUser: NcmStatusUser;
      insuranceType?: InsuranceType | null;
      isAlgeaCare?: boolean | null;
      userId?: string | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        zip: string;
        state: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionMetaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
      Prescriptions: Array<{
        __typename?: "PrescriptionNcm";
        id: string;
        ncmId: any;
        prescriptionFilePath?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
    };
  };
};

export type RejectNcmMutationVariables = Exact<{
  input: RejectNcmInput;
}>;

export type RejectNcmMutation = {
  __typename?: "Mutation";
  rejectNCM: {
    __typename?: "RejectNCMResponse";
    result: {
      __typename?: "NcmDetails";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt: any;
      createdAt: any;
      status: NcmStatus;
      completedDate?: any | null;
      statusUser: NcmStatusUser;
      insuranceType?: InsuranceType | null;
      isAlgeaCare?: boolean | null;
      userId?: string | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        zip: string;
        state: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionMetaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
      Prescriptions: Array<{
        __typename?: "PrescriptionNcm";
        id: string;
        ncmId: any;
        prescriptionFilePath?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
    };
  };
};

export type NcmQueryVariables = Exact<{
  input: NcmInput;
}>;

export type NcmQuery = {
  __typename?: "Query";
  ncm: {
    __typename?: "NcmResponse";
    result: {
      __typename?: "Ncm";
      id: any;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
    };
  };
};

export type FilterNcmsQueryVariables = Exact<{
  input: FilterNcmsInput;
}>;

export type FilterNcmsQuery = {
  __typename?: "Query";
  filterNcms: {
    __typename?: "FilterNcmsResponse";
    results: Array<{
      __typename?: "NcmDetails";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt: any;
      createdAt: any;
      status: NcmStatus;
      completedDate?: any | null;
      statusUser: NcmStatusUser;
      insuranceType?: InsuranceType | null;
      isAlgeaCare?: boolean | null;
      userId?: string | null;
      billingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        country: string;
        zip: string;
        state: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      prescriptionMetaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
      products: Array<{
        __typename?: "NcmProduct";
        quantity: number;
        product: {
          __typename?: "PublicProduct";
          startingPrice?: number | null;
          startingPriceIsInsurance?: boolean | null;
          taxClass: TaxClass;
          id: any;
          fullName: string;
          unit: Unit;
          uniqueProductIdentifiers?: string | null;
          specialProductIdentifier?: string | null;
          pharmacyProductIDs?: string | null;
          generalMarketAvailability?: boolean | null;
          cbdQuantityTo?: number | null;
          cbdQuantityFrom?: number | null;
          thcQuantityFrom?: number | null;
          thcQuantityTo?: number | null;
          averageMarketPrice?: number | null;
          iliosSalesPrice?: number | null;
          basePrice?: number | null;
          unitValue: number;
          category: ProductCategory;
          description?: string | null;
          imageURL: string;
          terpenesProfileText: string;
          medicalEffectText?: string | null;
          slotOrder?: number | null;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          genetics: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          manufacturer: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          irradiation: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
          wholesaler: {
            __typename?: "Label";
            id: number;
            namespace: string;
            name: string;
            imageURL?: string | null;
          };
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            value: string;
            label: string;
            type: MetaFieldType;
          }>;
          terpenes: Array<{
            __typename?: "Terpene";
            id: string;
            productId: string;
            contributionPercentage?: number | null;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
          medicalEffects: Array<{
            __typename?: "MedicalEffect";
            id: string;
            labelId?: string | null;
            productId: string;
            label: {
              __typename?: "Label";
              id: number;
              name: string;
              namespace: string;
              imageURL?: string | null;
            };
          }>;
        };
      }>;
      Prescriptions: Array<{
        __typename?: "PrescriptionNcm";
        id: string;
        ncmId: any;
        prescriptionFilePath?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type NcmsDetailFragment = {
  __typename?: "NcmDetails";
  id: any;
  firstName?: string | null;
  lastName?: string | null;
  updatedAt: any;
  createdAt: any;
  status: NcmStatus;
  completedDate?: any | null;
  statusUser: NcmStatusUser;
  insuranceType?: InsuranceType | null;
  isAlgeaCare?: boolean | null;
  userId?: string | null;
  billingAddress?: {
    __typename?: "Address";
    id: any;
    street: string;
    houseNumber: string;
    city: string;
    country: string;
    zip: string;
    state: string;
    createdAt: any;
    updatedAt: any;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  prescriptionMetaFields: Array<{
    __typename?: "MetaField";
    name: string;
    label: string;
    value: string;
    type: MetaFieldType;
  }>;
  products: Array<{
    __typename?: "NcmProduct";
    quantity: number;
    product: {
      __typename?: "PublicProduct";
      startingPrice?: number | null;
      startingPriceIsInsurance?: boolean | null;
      taxClass: TaxClass;
      id: any;
      fullName: string;
      unit: Unit;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      basePrice?: number | null;
      unitValue: number;
      category: ProductCategory;
      description?: string | null;
      imageURL: string;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
      wholesaler: {
        __typename?: "Label";
        id: number;
        namespace: string;
        name: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        id: string;
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    };
  }>;
  Prescriptions: Array<{
    __typename?: "PrescriptionNcm";
    id: string;
    ncmId: any;
    prescriptionFilePath?: string | null;
    prescriptionData: {
      __typename?: "PrescriptionData";
      prescriptionFilePath?: string | null;
      coverageFilePath?: string | null;
      isAlgeaCare?: boolean | null;
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        label: string;
        value: string;
        type: MetaFieldType;
      }>;
    };
  }>;
};

export type NotificationsQueryVariables = Exact<{
  input: NotificationsInput;
}>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: {
    __typename?: "NotificationsResponse";
    results: Array<{
      __typename?: "Notification";
      id: string;
      type: NotificationName;
      name: string;
      date: any;
      status: NotificationStatus;
      task?: string | null;
      relatedObjects: Array<{
        __typename?: "NotificationRelatedObject";
        type: NotificationObject;
        id: string;
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: "Mutation";
  updateNotification: { __typename?: "UpdateNotificationResponse"; success: boolean };
};

export type CancelWorkflowRequestMutationVariables = Exact<{
  input: CancelWorkflowRequestInput;
}>;

export type CancelWorkflowRequestMutation = {
  __typename?: "Mutation";
  cancelWorkflowRequest: { __typename?: "CancelWorkflowResponse"; success: boolean };
};

export type UpdateCheckoutDeliveryStatusMutationVariables = Exact<{
  input: CheckoutDeliveryStatusUpdateInput;
}>;

export type UpdateCheckoutDeliveryStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutDeliveryStatus: {
    __typename?: "CheckoutDeliveryStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutPaymentStatusMutationVariables = Exact<{
  input: CheckoutPaymentStatusUpdateInput;
}>;

export type UpdateCheckoutPaymentStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutPaymentStatus: {
    __typename?: "CheckoutPaymentStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateInternalInvoiceNumberMutationVariables = Exact<{
  input: InternalInvoiceNumberInput;
}>;

export type UpdateInternalInvoiceNumberMutation = {
  __typename?: "Mutation";
  updateInternalInvoiceNumber: {
    __typename?: "InternalInvoiceNumberResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutPrescriptionStatusMutationVariables = Exact<{
  input: CheckoutPrescriptionStatusUpdateInput;
}>;

export type UpdateCheckoutPrescriptionStatusMutation = {
  __typename?: "Mutation";
  updateCheckoutPrescriptionStatus: {
    __typename?: "CheckoutPrescriptionStatusUpdateResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type AcceptCheckoutChangeMutationVariables = Exact<{
  input: AcceptCheckoutChangesInput;
}>;

export type AcceptCheckoutChangeMutation = {
  __typename?: "Mutation";
  acceptCheckoutChanges:
    | { __typename?: "AcceptCheckoutChangesSuccessResponse"; success: boolean }
    | { __typename?: "CheckoutPaymentRedirectResponse"; redirectUrl: string };
};

export type UpdateCheckoutProposeLateProductChangeMutationVariables = Exact<{
  input: CheckoutProposeLateProductChangeInput;
}>;

export type UpdateCheckoutProposeLateProductChangeMutation = {
  __typename?: "Mutation";
  updateCheckoutProposeLateProductChange: {
    __typename?: "CheckoutProposeLateProductChangeResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type UpdateCheckoutDeliveryOptionMutationVariables = Exact<{
  input: CheckoutUpdateDeliveryOptionAdminInput;
}>;

export type UpdateCheckoutDeliveryOptionMutation = {
  __typename?: "Mutation";
  updateCheckoutDeliveryOption: {
    __typename?: "CheckoutUpdateDeliveryOptionAdminResponse";
    checkout: {
      __typename?: "Checkout";
      id: any;
      orderNumber: string;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userEmail?: string | null;
      userId?: any | null;
      userInsuranceType?: InsuranceType | null;
      dateShippedOn?: any | null;
      dateOrderedOn?: any | null;
      dateDeliveredOn?: any | null;
      noteCount?: number | null;
      invoiceBillingDate?: any | null;
      invoiceNumber?: string | null;
      cancellationInvoiceNumber?: string | null;
      paymentMethod?: string | null;
      InternalInvoiceNumber?: string | null;
      proposedProductsStatus?: CheckoutProposedProductsStatus | null;
      shouldDisableChangeLink?: boolean | null;
      outstandingAmount: number;
      paymentStatus: PaymentStatus;
      prescriptionOnlineStatus: PrescriptionOnlineStatus;
      deliveryStatus: DeliveryStatus;
      prescriptionOfflineStatus: PrescriptionOfflineStatus;
      updatedAt: any;
      createdAt: any;
      status: CheckoutStatus;
      nextStep: CheckoutNextStep;
      subtotal: number;
      subtotalNet: number;
      total: number;
      totalNet: number;
      invoiceLink?: string | null;
      cancellationInvoiceLink?: string | null;
      deliverySlipLink?: string | null;
      shippingLabelLink?: string | null;
      productLabelLink?: string | null;
      productsStatus: CheckoutProductsStatus;
      documentationStatus: CheckoutDocumentationStatus;
      isWaitingForDoctorApproval: boolean;
      areProductsOrdered: boolean;
      shipmentId?: string | null;
      trackingLink?: string | null;
      shipmentWeightInGrams: number;
      isPharmacySignatureNeeded?: boolean | null;
      workflowurl?: string | null;
      secSignPortfolioID?: number | null;
      documentSignatureStatus?: EsignatureStatus | null;
      ePrescriptionDownloadedByUser?: string | null;
      ePrescriptionDownloadedAt?: any | null;
      products: Array<{
        __typename?: "CheckoutProduct";
        quantity: number;
        price: number;
        product: {
          __typename?: "PublicProduct";
          taxClass: TaxClass;
          id: any;
          fullName: string;
          description?: string | null;
          unit: Unit;
          unitValue: number;
          startingPrice?: number | null;
          imageURL: string;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
          strain: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
          availability: {
            __typename?: "PublicAvailability";
            currentAvailability: ProductAvailabilityOptions;
            availableInDaysFrom?: number | null;
            availableInDaysTo?: number | null;
            dateAvailableFrom?: any | null;
          };
        };
      }>;
      payments: Array<{
        __typename?: "CheckoutPayment";
        id: string;
        method: string;
        amount: number;
        status: PaymentStatus;
      }>;
      proposedCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      originalCheckoutDetails?: {
        __typename?: "OriginalCheckoutDetails";
        subtotal: number;
        deliveryFee: number;
        total: number;
        products: Array<{
          __typename?: "CheckoutProduct";
          quantity: number;
          price: number;
          product: {
            __typename?: "PublicProduct";
            taxClass: TaxClass;
            id: any;
            fullName: string;
            description?: string | null;
            startingPrice?: number | null;
            imageURL: string;
            unit: Unit;
            unitValue: number;
            metaFields: Array<{
              __typename?: "MetaField";
              name: string;
              label: string;
              value: string;
              type: MetaFieldType;
            }>;
            availability: {
              __typename?: "PublicAvailability";
              currentAvailability: ProductAvailabilityOptions;
              availableInDaysFrom?: number | null;
              availableInDaysTo?: number | null;
              dateAvailableFrom?: any | null;
            };
          };
        }>;
        extras: Array<{
          __typename?: "CheckoutExtra";
          name: string;
          description: string;
          quantity: number;
          price: number;
        }>;
      } | null;
      prescriptionIssues?: Array<{
        __typename?: "PrescriptionIssue";
        type: PrescriptionIssueType;
        message: string;
      }> | null;
      pharmacy?: {
        __typename?: "PublicPharmacy";
        id: any;
        name: string;
        email: string;
        subDomain: string;
        phoneNumber?: string | null;
      } | null;
      prescriptionData: {
        __typename?: "PrescriptionData";
        prescriptionFilePath?: string | null;
        coverageFilePath?: string | null;
        isAlgeaCare?: boolean | null;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      };
      extras: Array<{
        __typename?: "CheckoutExtra";
        name: string;
        description: string;
        quantity: number;
        price: number;
      }>;
      Prescriptions: Array<{
        __typename?: "Prescription";
        id: string;
        checkoutId: any;
        epDocumentID?: string | null;
        prescriptionData: {
          __typename?: "PrescriptionData";
          prescriptionFilePath?: string | null;
          coverageFilePath?: string | null;
          isAlgeaCare?: boolean | null;
          metaFields: Array<{
            __typename?: "MetaField";
            name: string;
            label: string;
            value: string;
            type: MetaFieldType;
          }>;
        };
      }>;
      shippingAddress?: {
        __typename?: "Address";
        id: any;
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOption?: {
        __typename?: "DeliveryOption";
        id: any;
        name: string;
        type: DeliveryOptionType;
        price: number;
      } | null;
    };
  };
};

export type FetchSignedPrescriptionQueryVariables = Exact<{
  input: FetchSignedPrescriptionInput;
}>;

export type FetchSignedPrescriptionQuery = {
  __typename?: "Query";
  fetchSignedPrescription: {
    __typename?: "FetchSignedPrescriptionResponse";
    signedPrescription: string;
  };
};

export type PharmacyFragment = {
  __typename?: "Pharmacy";
  id: any;
  name: string;
  legalName?: string | null;
  website?: string | null;
  subDomain: string;
  email: string;
  phoneNumber?: string | null;
  createdAt: any;
  updatedAt: any;
  updateBy?: string | null;
  status: PharmacyStatus;
  openingHours?: string | null;
  prescriptionEnvelopeURL?: string | null;
  pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
  mediumCapacity?: number | null;
  heavyCapacity?: number | null;
  taxNumber?: string | null;
  useOrderConfirmationInsteadOfInvoice: boolean;
  invoiceFooter?: Array<string> | null;
  invoicePaymentText?: string | null;
  invoiceShowBankDetails: boolean;
  invoiceShowPaymentText: boolean;
  user?: {
    __typename?: "PharmacyAdmin";
    name?: string | null;
    email: string;
    phone?: string | null;
  } | null;
  address?: {
    __typename?: "Address";
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    createdAt: any;
    updatedAt: any;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  deliveryOptions?: {
    __typename?: "PharmacyDeliveryOptions";
    dhlDetails?: {
      __typename?: "DhlResponse";
      username?: string | null;
      billingNumber?: string | null;
      password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
    goDetails?: {
      __typename?: "GoResponse";
      username?: string | null;
      responsibleStation?: string | null;
      customerID?: string | null;
      pickupTimeTo?: string | null;
      pickupTimeFrom?: string | null;
      usernameTracking?: string | null;
      password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
  } | null;
  customShippingDetails?: {
    __typename?: "CustomShippingDetails";
    minPriceToFreeShipment?: number | null;
    flatRate?: number | null;
    pharmacyID: any;
  } | null;
};

export type CustomShippingDetailsFragment = {
  __typename?: "CustomShippingDetails";
  minPriceToFreeShipment?: number | null;
  flatRate?: number | null;
  pharmacyID: any;
};

export type SinglePharmacyFragment = {
  __typename?: "Pharmacy";
  agb?: string | null;
  privacyPolicy?: string | null;
  dataProtectionOfficerEmail?: string | null;
  dataProtectionOfficerName?: string | null;
  description?: string | null;
  imprint?: string | null;
  createdAt: any;
  logoURL?: string | null;
  iKPharmacyNumber?: string | null;
  prescriptionEnvelopeURL?: string | null;
  teamLocationPictureURL: Array<string | null>;
  id: any;
  name: string;
  legalName?: string | null;
  website?: string | null;
  subDomain: string;
  email: string;
  phoneNumber?: string | null;
  updatedAt: any;
  updateBy?: string | null;
  status: PharmacyStatus;
  openingHours?: string | null;
  pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
  mediumCapacity?: number | null;
  heavyCapacity?: number | null;
  taxNumber?: string | null;
  useOrderConfirmationInsteadOfInvoice: boolean;
  invoiceFooter?: Array<string> | null;
  invoicePaymentText?: string | null;
  invoiceShowBankDetails: boolean;
  invoiceShowPaymentText: boolean;
  notificationPreferences?: {
    __typename?: "PharmacyNotificationPreferences";
    newOrder: Array<PharmacyNotificationType>;
    lowStock: Array<PharmacyNotificationType>;
    lowStockTriggerValue?: number | null;
    newProduct: Array<PharmacyNotificationType>;
    missingProductDetails: Array<PharmacyNotificationType>;
    ticketUpdate: Array<PharmacyNotificationType>;
    invalidErpConnection: Array<PharmacyNotificationType>;
    orderStatusUpdate: Array<PharmacyNotificationType>;
  } | null;
  stockOptions?: {
    __typename?: "PharmacyStockOptions";
    manual: boolean;
    winApoDetails?: { __typename?: "WinApoResponse"; default?: boolean | null } | null;
    ixosDetails?: {
      __typename?: "IxosResponse";
      idf?: string | null;
      pharmacyNr?: string | null;
      pharmacyEmail?: string | null;
      password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
  } | null;
  paymentOptions?: {
    __typename?: "PharmacyPaymentOptions";
    paymentMethods?: Array<PharmacyPaymentOption> | null;
    unzerDetails?: {
      __typename?: "UnzerResponse";
      publicKey?: string | null;
      privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
    bankDetails?: {
      __typename?: "BankResponse";
      owner: string;
      iban: string;
      bic?: string | null;
      bank?: string | null;
    } | null;
    paymentMethodsOptions: Array<{
      __typename?: "PharmacyPaymentMethodOptions";
      method: PharmacyPaymentOption;
      message?: string | null;
    }>;
  } | null;
  deliveryOptions?: {
    __typename?: "PharmacyDeliveryOptions";
    dhlDetails?: {
      __typename?: "DhlResponse";
      username?: string | null;
      billingNumber?: string | null;
      password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
    goDetails?: {
      __typename?: "GoResponse";
      username?: string | null;
      responsibleStation?: string | null;
      customerID?: string | null;
      pickupTimeTo?: string | null;
      pickupTimeFrom?: string | null;
      usernameTracking?: string | null;
      password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    } | null;
  } | null;
  dhlSettings: {
    __typename?: "PharmacyDHLSettings";
    useIdentCheck: boolean;
    useAgeCheck: boolean;
    useDirectDelivery: boolean;
  };
  user?: {
    __typename?: "PharmacyAdmin";
    name?: string | null;
    email: string;
    phone?: string | null;
  } | null;
  address?: {
    __typename?: "Address";
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    createdAt: any;
    updatedAt: any;
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      label: string;
      value: string;
      type: MetaFieldType;
    }>;
  } | null;
  customShippingDetails?: {
    __typename?: "CustomShippingDetails";
    minPriceToFreeShipment?: number | null;
    flatRate?: number | null;
    pharmacyID: any;
  } | null;
};

export type PharmacyDeliveryOptionFragment = {
  __typename?: "PharmacyDeliveryOptions";
  dhlDetails?: {
    __typename?: "DhlResponse";
    username?: string | null;
    billingNumber?: string | null;
    password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
  } | null;
  goDetails?: {
    __typename?: "GoResponse";
    username?: string | null;
    responsibleStation?: string | null;
    customerID?: string | null;
    pickupTimeTo?: string | null;
    pickupTimeFrom?: string | null;
    usernameTracking?: string | null;
    password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
    passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
  } | null;
};

export type PharmacyPaymentOptionFragment = {
  __typename?: "PharmacyPaymentOptions";
  paymentMethods?: Array<PharmacyPaymentOption> | null;
  unzerDetails?: {
    __typename?: "UnzerResponse";
    publicKey?: string | null;
    privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
  } | null;
  bankDetails?: {
    __typename?: "BankResponse";
    owner: string;
    iban: string;
    bic?: string | null;
    bank?: string | null;
  } | null;
  paymentMethodsOptions: Array<{
    __typename?: "PharmacyPaymentMethodOptions";
    method: PharmacyPaymentOption;
    message?: string | null;
  }>;
};

export type PharmacyDhlSettingsFragment = {
  __typename?: "PharmacyDHLSettings";
  useIdentCheck: boolean;
  useAgeCheck: boolean;
  useDirectDelivery: boolean;
};

export type FilterPharmaciesQueryVariables = Exact<{
  input: FilterPharmacyInput;
}>;

export type FilterPharmaciesQuery = {
  __typename?: "Query";
  filterPharmacies: {
    __typename?: "FilterPharmacyResponse";
    results: Array<{
      __typename?: "Pharmacy";
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      prescriptionEnvelopeURL?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type PharmacyDetailQueryVariables = Exact<{
  input: PharmacyDetailInput;
}>;

export type PharmacyDetailQuery = {
  __typename?: "Query";
  pharmacyDetail: {
    __typename?: "PharmacyDetailResponse";
    result: {
      __typename?: "Pharmacy";
      agb?: string | null;
      privacyPolicy?: string | null;
      dataProtectionOfficerEmail?: string | null;
      dataProtectionOfficerName?: string | null;
      description?: string | null;
      imprint?: string | null;
      createdAt: any;
      logoURL?: string | null;
      iKPharmacyNumber?: string | null;
      prescriptionEnvelopeURL?: string | null;
      teamLocationPictureURL: Array<string | null>;
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      notificationPreferences?: {
        __typename?: "PharmacyNotificationPreferences";
        newOrder: Array<PharmacyNotificationType>;
        lowStock: Array<PharmacyNotificationType>;
        lowStockTriggerValue?: number | null;
        newProduct: Array<PharmacyNotificationType>;
        missingProductDetails: Array<PharmacyNotificationType>;
        ticketUpdate: Array<PharmacyNotificationType>;
        invalidErpConnection: Array<PharmacyNotificationType>;
        orderStatusUpdate: Array<PharmacyNotificationType>;
      } | null;
      stockOptions?: {
        __typename?: "PharmacyStockOptions";
        manual: boolean;
        winApoDetails?: { __typename?: "WinApoResponse"; default?: boolean | null } | null;
        ixosDetails?: {
          __typename?: "IxosResponse";
          idf?: string | null;
          pharmacyNr?: string | null;
          pharmacyEmail?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      paymentOptions?: {
        __typename?: "PharmacyPaymentOptions";
        paymentMethods?: Array<PharmacyPaymentOption> | null;
        unzerDetails?: {
          __typename?: "UnzerResponse";
          publicKey?: string | null;
          privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        bankDetails?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        paymentMethodsOptions: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      dhlSettings: {
        __typename?: "PharmacyDHLSettings";
        useIdentCheck: boolean;
        useAgeCheck: boolean;
        useDirectDelivery: boolean;
      };
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    };
  };
};

export type FilterPublicPharmaciesQueryVariables = Exact<{
  input: FilterPublicPharmacyInput;
}>;

export type FilterPublicPharmaciesQuery = {
  __typename?: "Query";
  filterPublicPharmacies: {
    __typename?: "FilterPublicPharmacyResponse";
    results: Array<{
      __typename?: "PublicPharmacy";
      id: any;
      name: string;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      agb?: string | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type CreatePharmacyMutationVariables = Exact<{
  input: CreatePharmacyInput;
}>;

export type CreatePharmacyMutation = {
  __typename?: "Mutation";
  createPharmacy: {
    __typename?: "CreatePharmacyResponse";
    result: {
      __typename?: "Pharmacy";
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      prescriptionEnvelopeURL?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    };
  };
};

export type UpdatePharmacyMutationVariables = Exact<{
  input: UpdatePharmacyInput;
}>;

export type UpdatePharmacyMutation = {
  __typename?: "Mutation";
  updatePharmacy: {
    __typename?: "UpdatePharmacyResponse";
    result: {
      __typename?: "Pharmacy";
      agb?: string | null;
      privacyPolicy?: string | null;
      dataProtectionOfficerEmail?: string | null;
      dataProtectionOfficerName?: string | null;
      description?: string | null;
      imprint?: string | null;
      createdAt: any;
      logoURL?: string | null;
      iKPharmacyNumber?: string | null;
      prescriptionEnvelopeURL?: string | null;
      teamLocationPictureURL: Array<string | null>;
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      notificationPreferences?: {
        __typename?: "PharmacyNotificationPreferences";
        newOrder: Array<PharmacyNotificationType>;
        lowStock: Array<PharmacyNotificationType>;
        lowStockTriggerValue?: number | null;
        newProduct: Array<PharmacyNotificationType>;
        missingProductDetails: Array<PharmacyNotificationType>;
        ticketUpdate: Array<PharmacyNotificationType>;
        invalidErpConnection: Array<PharmacyNotificationType>;
        orderStatusUpdate: Array<PharmacyNotificationType>;
      } | null;
      stockOptions?: {
        __typename?: "PharmacyStockOptions";
        manual: boolean;
        winApoDetails?: { __typename?: "WinApoResponse"; default?: boolean | null } | null;
        ixosDetails?: {
          __typename?: "IxosResponse";
          idf?: string | null;
          pharmacyNr?: string | null;
          pharmacyEmail?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      paymentOptions?: {
        __typename?: "PharmacyPaymentOptions";
        paymentMethods?: Array<PharmacyPaymentOption> | null;
        unzerDetails?: {
          __typename?: "UnzerResponse";
          publicKey?: string | null;
          privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        bankDetails?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        paymentMethodsOptions: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      dhlSettings: {
        __typename?: "PharmacyDHLSettings";
        useIdentCheck: boolean;
        useAgeCheck: boolean;
        useDirectDelivery: boolean;
      };
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    };
  };
};

export type UpdatePharmacyStatusMutationVariables = Exact<{
  input: UpdatePharmacyStatusInput;
}>;

export type UpdatePharmacyStatusMutation = {
  __typename?: "Mutation";
  updatePharmacyStatus: {
    __typename?: "UpdatePharmacyStatusResponse";
    result: {
      __typename?: "Pharmacy";
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      prescriptionEnvelopeURL?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    };
  };
};

export type UpdatePharmacyDeliveryOptionsMutationVariables = Exact<{
  input: UpdatePharmacyDeliveryOptionsInput;
}>;

export type UpdatePharmacyDeliveryOptionsMutation = {
  __typename?: "Mutation";
  updatePharmacyDeliveryOptions: {
    __typename?: "UpdatePharmacyDeliveryOptionsResponse";
    result: {
      __typename?: "PharmacyDeliveryOptions";
      dhlDetails?: {
        __typename?: "DhlResponse";
        username?: string | null;
        billingNumber?: string | null;
        password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      } | null;
      goDetails?: {
        __typename?: "GoResponse";
        username?: string | null;
        responsibleStation?: string | null;
        customerID?: string | null;
        pickupTimeTo?: string | null;
        pickupTimeFrom?: string | null;
        usernameTracking?: string | null;
        password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      } | null;
    };
  };
};

export type UpdatePharmacyPaymentOptionsMutationVariables = Exact<{
  input: UpdatePharmacyPaymentOptionsInput;
}>;

export type UpdatePharmacyPaymentOptionsMutation = {
  __typename?: "Mutation";
  updatePharmacyPaymentOptions: {
    __typename?: "UpdatePharmacyPaymentOptionsResponse";
    result: {
      __typename?: "PharmacyPaymentOptions";
      paymentMethods?: Array<PharmacyPaymentOption> | null;
      unzerDetails?: {
        __typename?: "UnzerResponse";
        publicKey?: string | null;
        privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      } | null;
      bankDetails?: {
        __typename?: "BankResponse";
        owner: string;
        iban: string;
        bic?: string | null;
        bank?: string | null;
      } | null;
      paymentMethodsOptions: Array<{
        __typename?: "PharmacyPaymentMethodOptions";
        method: PharmacyPaymentOption;
        message?: string | null;
      }>;
    };
  };
};

export type UpdatePharmacyDhlSettingsMutationVariables = Exact<{
  input: UpdatePharmacyDhlSettingsInput;
}>;

export type UpdatePharmacyDhlSettingsMutation = {
  __typename?: "Mutation";
  updatePharmacyDHLSettings: {
    __typename?: "UpdatePharmacyDHLSettingsResponse";
    result: {
      __typename?: "PharmacyDHLSettings";
      useIdentCheck: boolean;
      useAgeCheck: boolean;
      useDirectDelivery: boolean;
    };
  };
};

export type UpdatePharmacyStockManagementMutationVariables = Exact<{
  input: UpdatePharmacyStockManagementInput;
}>;

export type UpdatePharmacyStockManagementMutation = {
  __typename?: "Mutation";
  updatePharmacyStockManagement: {
    __typename?: "UpdatePharmacyStockManagementResponse";
    result: {
      __typename?: "PharmacyStockOptions";
      manual: boolean;
      winApoDetails?: { __typename?: "WinApoResponse"; default?: boolean | null } | null;
      ixosDetails?: {
        __typename?: "IxosResponse";
        idf?: string | null;
        pharmacyNr?: string | null;
        pharmacyEmail?: string | null;
        password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
      } | null;
    };
  };
};

export type UpdatePharmacyNotificationPreferencesMutationVariables = Exact<{
  input: PharmacyNotificationPreferencesInput;
}>;

export type UpdatePharmacyNotificationPreferencesMutation = {
  __typename?: "Mutation";
  updatePharmacyNotificationPreferences: {
    __typename?: "UpdatePharmacyNotificationPreferencesResponse";
    success: boolean;
  };
};

export type GetUserPharmacyQueryVariables = Exact<{
  input: FilterUserPharmacyInput;
}>;

export type GetUserPharmacyQuery = {
  __typename?: "Query";
  getUserPharmacy: {
    __typename?: "FilterUserPharmacyResponse";
    result: {
      __typename?: "Pharmacy";
      missingProperties?: Array<MissingProperties> | null;
      agb?: string | null;
      privacyPolicy?: string | null;
      dataProtectionOfficerEmail?: string | null;
      dataProtectionOfficerName?: string | null;
      description?: string | null;
      imprint?: string | null;
      createdAt: any;
      logoURL?: string | null;
      iKPharmacyNumber?: string | null;
      prescriptionEnvelopeURL?: string | null;
      teamLocationPictureURL: Array<string | null>;
      id: any;
      name: string;
      legalName?: string | null;
      website?: string | null;
      subDomain: string;
      email: string;
      phoneNumber?: string | null;
      updatedAt: any;
      updateBy?: string | null;
      status: PharmacyStatus;
      openingHours?: string | null;
      pharmacyCapacityStatus?: PharmacyCapacityStatus | null;
      mediumCapacity?: number | null;
      heavyCapacity?: number | null;
      taxNumber?: string | null;
      useOrderConfirmationInsteadOfInvoice: boolean;
      invoiceFooter?: Array<string> | null;
      invoicePaymentText?: string | null;
      invoiceShowBankDetails: boolean;
      invoiceShowPaymentText: boolean;
      notificationPreferences?: {
        __typename?: "PharmacyNotificationPreferences";
        newOrder: Array<PharmacyNotificationType>;
        lowStock: Array<PharmacyNotificationType>;
        lowStockTriggerValue?: number | null;
        newProduct: Array<PharmacyNotificationType>;
        missingProductDetails: Array<PharmacyNotificationType>;
        ticketUpdate: Array<PharmacyNotificationType>;
        invalidErpConnection: Array<PharmacyNotificationType>;
        orderStatusUpdate: Array<PharmacyNotificationType>;
      } | null;
      stockOptions?: {
        __typename?: "PharmacyStockOptions";
        manual: boolean;
        winApoDetails?: { __typename?: "WinApoResponse"; default?: boolean | null } | null;
        ixosDetails?: {
          __typename?: "IxosResponse";
          idf?: string | null;
          pharmacyNr?: string | null;
          pharmacyEmail?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      paymentOptions?: {
        __typename?: "PharmacyPaymentOptions";
        paymentMethods?: Array<PharmacyPaymentOption> | null;
        unzerDetails?: {
          __typename?: "UnzerResponse";
          publicKey?: string | null;
          privateKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        bankDetails?: {
          __typename?: "BankResponse";
          owner: string;
          iban: string;
          bic?: string | null;
          bank?: string | null;
        } | null;
        paymentMethodsOptions: Array<{
          __typename?: "PharmacyPaymentMethodOptions";
          method: PharmacyPaymentOption;
          message?: string | null;
        }>;
      } | null;
      deliveryOptions?: {
        __typename?: "PharmacyDeliveryOptions";
        dhlDetails?: {
          __typename?: "DhlResponse";
          username?: string | null;
          billingNumber?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          apiKey?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
        goDetails?: {
          __typename?: "GoResponse";
          username?: string | null;
          responsibleStation?: string | null;
          customerID?: string | null;
          pickupTimeTo?: string | null;
          pickupTimeFrom?: string | null;
          usernameTracking?: string | null;
          password?: { __typename?: "Secret"; length: number; lastChars: string } | null;
          passwordTracking?: { __typename?: "Secret"; length: number; lastChars: string } | null;
        } | null;
      } | null;
      dhlSettings: {
        __typename?: "PharmacyDHLSettings";
        useIdentCheck: boolean;
        useAgeCheck: boolean;
        useDirectDelivery: boolean;
      };
      user?: {
        __typename?: "PharmacyAdmin";
        name?: string | null;
        email: string;
        phone?: string | null;
      } | null;
      address?: {
        __typename?: "Address";
        street: string;
        houseNumber: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        createdAt: any;
        updatedAt: any;
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          label: string;
          value: string;
          type: MetaFieldType;
        }>;
      } | null;
      customShippingDetails?: {
        __typename?: "CustomShippingDetails";
        minPriceToFreeShipment?: number | null;
        flatRate?: number | null;
        pharmacyID: any;
      } | null;
    };
  };
};

export type PasswordFragment = { __typename?: "Secret"; length: number; lastChars: string };

export type GetAvailableDeliveryOptionsQueryVariables = Exact<{
  input: GetAvailableDeliveryOptionsInput;
}>;

export type GetAvailableDeliveryOptionsQuery = {
  __typename?: "Query";
  getAvailableDeliveryOptions?: {
    __typename?: "GetAvailableDeliveryOptionsResponse";
    results?: Array<{
      __typename?: "DeliveryOption";
      id: any;
      name: string;
      type: DeliveryOptionType;
      price: number;
    } | null> | null;
  } | null;
};

export type ProductFragment = {
  __typename?: "Product";
  id: any;
  fullName: string;
  createdAt: any;
  updatedAt: any;
  insurancePriceTo?: number | null;
  selfPayPriceFrom?: number | null;
  insurancePriceFrom?: number | null;
  selfPayPriceTo?: number | null;
  description?: string | null;
  unit: Unit;
  unitValue: number;
  category: ProductCategory;
  status: ProductStatus;
  taxClass: TaxClass;
  sku: string;
  imageURL: string;
  uniqueProductIdentifiers?: string | null;
  specialProductIdentifier?: string | null;
  pharmacyProductIDs?: string | null;
  generalMarketAvailability?: boolean | null;
  cbdQuantityTo?: number | null;
  cbdQuantityFrom?: number | null;
  thcQuantityFrom?: number | null;
  thcQuantityTo?: number | null;
  averageMarketPrice?: number | null;
  iliosSalesPrice?: number | null;
  terpenesProfileText: string;
  medicalEffectText?: string | null;
  slotOrder?: number | null;
  strain: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  genetics: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  manufacturer: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  irradiation: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  metaFields: Array<{
    __typename?: "MetaField";
    name: string;
    value: string;
    label: string;
    type: MetaFieldType;
  }>;
  bestAvailability: {
    __typename?: "PublicAvailability";
    availableInDaysTo?: number | null;
    dateAvailableFrom?: any | null;
    availableInDaysFrom?: number | null;
    currentAvailability: ProductAvailabilityOptions;
  };
  terpenes: Array<{
    __typename?: "Terpene";
    id: string;
    productId: string;
    contributionPercentage?: number | null;
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  }>;
  medicalEffects: Array<{
    __typename?: "MedicalEffect";
    labelId?: string | null;
    productId: string;
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  }>;
};

export type PublicProductFragment = {
  __typename?: "PublicProduct";
  startingPrice?: number | null;
  startingPriceIsInsurance?: boolean | null;
  taxClass: TaxClass;
  id: any;
  fullName: string;
  unit: Unit;
  uniqueProductIdentifiers?: string | null;
  specialProductIdentifier?: string | null;
  pharmacyProductIDs?: string | null;
  generalMarketAvailability?: boolean | null;
  cbdQuantityTo?: number | null;
  cbdQuantityFrom?: number | null;
  thcQuantityFrom?: number | null;
  thcQuantityTo?: number | null;
  averageMarketPrice?: number | null;
  iliosSalesPrice?: number | null;
  basePrice?: number | null;
  unitValue: number;
  category: ProductCategory;
  description?: string | null;
  imageURL: string;
  terpenesProfileText: string;
  medicalEffectText?: string | null;
  slotOrder?: number | null;
  strain: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  genetics: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  manufacturer: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  irradiation: {
    __typename?: "Label";
    id: number;
    name: string;
    namespace: string;
    imageURL?: string | null;
  };
  availability: {
    __typename?: "PublicAvailability";
    currentAvailability: ProductAvailabilityOptions;
    availableInDaysFrom?: number | null;
    availableInDaysTo?: number | null;
    dateAvailableFrom?: any | null;
  };
  wholesaler: {
    __typename?: "Label";
    id: number;
    namespace: string;
    name: string;
    imageURL?: string | null;
  };
  metaFields: Array<{
    __typename?: "MetaField";
    name: string;
    value: string;
    label: string;
    type: MetaFieldType;
  }>;
  terpenes: Array<{
    __typename?: "Terpene";
    id: string;
    productId: string;
    contributionPercentage?: number | null;
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  }>;
  medicalEffects: Array<{
    __typename?: "MedicalEffect";
    id: string;
    labelId?: string | null;
    productId: string;
    label: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
  }>;
};

export type AvailabilityFragment = {
  __typename?: "PublicAvailability";
  currentAvailability: ProductAvailabilityOptions;
  availableInDaysFrom?: number | null;
  availableInDaysTo?: number | null;
  dateAvailableFrom?: any | null;
};

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: "Mutation";
  createProduct: {
    __typename?: "CreateProductResponse";
    result: {
      __typename?: "Product";
      id: any;
      fullName: string;
      createdAt: any;
      updatedAt: any;
      insurancePriceTo?: number | null;
      selfPayPriceFrom?: number | null;
      insurancePriceFrom?: number | null;
      selfPayPriceTo?: number | null;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      category: ProductCategory;
      status: ProductStatus;
      taxClass: TaxClass;
      sku: string;
      imageURL: string;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      bestAvailability: {
        __typename?: "PublicAvailability";
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        availableInDaysFrom?: number | null;
        currentAvailability: ProductAvailabilityOptions;
      };
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    };
  };
};

export type UpdateProductStatusMutationVariables = Exact<{
  input: UpdateProductStatusInput;
}>;

export type UpdateProductStatusMutation = {
  __typename?: "Mutation";
  updateProductStatus: {
    __typename?: "UpdateProductStatusResponse";
    result: {
      __typename?: "Product";
      id: any;
      fullName: string;
      createdAt: any;
      updatedAt: any;
      insurancePriceTo?: number | null;
      selfPayPriceFrom?: number | null;
      insurancePriceFrom?: number | null;
      selfPayPriceTo?: number | null;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      category: ProductCategory;
      status: ProductStatus;
      taxClass: TaxClass;
      sku: string;
      imageURL: string;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      bestAvailability: {
        __typename?: "PublicAvailability";
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        availableInDaysFrom?: number | null;
        currentAvailability: ProductAvailabilityOptions;
      };
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    };
  };
};

export type HandleDeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;

export type HandleDeleteProductMutation = {
  __typename?: "Mutation";
  handleDeleteProduct: { __typename?: "DeleteProductResponse"; success: boolean };
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: "Mutation";
  updateProduct: {
    __typename?: "UpdateProductResponse";
    result: {
      __typename: "Product";
      id: any;
      fullName: string;
      createdAt: any;
      updatedAt: any;
      insurancePriceTo?: number | null;
      selfPayPriceFrom?: number | null;
      insurancePriceFrom?: number | null;
      selfPayPriceTo?: number | null;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      category: ProductCategory;
      status: ProductStatus;
      taxClass: TaxClass;
      sku: string;
      imageURL: string;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      bestAvailability: {
        __typename?: "PublicAvailability";
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        availableInDaysFrom?: number | null;
        currentAvailability: ProductAvailabilityOptions;
      };
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    };
  };
};

export type UpdateProductBulkMutationVariables = Exact<{
  input: UpdateProductBulkInput;
}>;

export type UpdateProductBulkMutation = {
  __typename?: "Mutation";
  updateProductBulk: { __typename?: "UpdateProductBulkResponse"; success: boolean };
};

export type FilterProductQueryVariables = Exact<{
  input: FilterProductInput;
}>;

export type FilterProductQuery = {
  __typename?: "Query";
  filterProducts: {
    __typename?: "FilterProductResponse";
    results: Array<{
      __typename?: "Product";
      createdAt: any;
      updatedAt: any;
      id: any;
      fullName: string;
      insurancePriceTo?: number | null;
      selfPayPriceFrom?: number | null;
      insurancePriceFrom?: number | null;
      selfPayPriceTo?: number | null;
      description?: string | null;
      unit: Unit;
      unitValue: number;
      category: ProductCategory;
      status: ProductStatus;
      taxClass: TaxClass;
      sku: string;
      imageURL: string;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      bestAvailability: {
        __typename?: "PublicAvailability";
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        availableInDaysFrom?: number | null;
        currentAvailability: ProductAvailabilityOptions;
      };
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type FilterPublicProductsQueryVariables = Exact<{
  input: FilterPublicProductInput;
}>;

export type FilterPublicProductsQuery = {
  __typename?: "Query";
  filterPublicProducts: {
    __typename?: "FilterPublicProductResponse";
    results: Array<{
      __typename?: "PublicProduct";
      startingPrice?: number | null;
      startingPriceIsInsurance?: boolean | null;
      taxClass: TaxClass;
      id: any;
      fullName: string;
      unit: Unit;
      uniqueProductIdentifiers?: string | null;
      specialProductIdentifier?: string | null;
      pharmacyProductIDs?: string | null;
      generalMarketAvailability?: boolean | null;
      cbdQuantityTo?: number | null;
      cbdQuantityFrom?: number | null;
      thcQuantityFrom?: number | null;
      thcQuantityTo?: number | null;
      averageMarketPrice?: number | null;
      iliosSalesPrice?: number | null;
      basePrice?: number | null;
      unitValue: number;
      category: ProductCategory;
      description?: string | null;
      imageURL: string;
      terpenesProfileText: string;
      medicalEffectText?: string | null;
      slotOrder?: number | null;
      strain: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      genetics: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      manufacturer: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      irradiation: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
      availability: {
        __typename?: "PublicAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
      };
      wholesaler: {
        __typename?: "Label";
        id: number;
        namespace: string;
        name: string;
        imageURL?: string | null;
      };
      metaFields: Array<{
        __typename?: "MetaField";
        name: string;
        value: string;
        label: string;
        type: MetaFieldType;
      }>;
      terpenes: Array<{
        __typename?: "Terpene";
        id: string;
        productId: string;
        contributionPercentage?: number | null;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
      medicalEffects: Array<{
        __typename?: "MedicalEffect";
        id: string;
        labelId?: string | null;
        productId: string;
        label: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
      }>;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type FilterPharmacyProductDetailsQueryVariables = Exact<{
  input: FilterPharmacyProductDetailsInput;
}>;

export type FilterPharmacyProductDetailsQuery = {
  __typename?: "Query";
  filterPharmacyProductDetails: {
    __typename?: "FilterPharmacyProductDetailsResponse";
    results: Array<{
      __typename?: "PharmacyProductDetails";
      productId: any;
      pharmacyId: any;
      thcPercentage?: number | null;
      cbdPercentage?: number | null;
      thcPercentageIsLessThan: boolean;
      cbdPercentageIsLessThan: boolean;
      pharmacyProductIDs?: string | null;
      selfPayPrice?: number | null;
      insurancePrice?: number | null;
      basePrice?: number | null;
      product: {
        __typename?: "Product";
        id: any;
        fullName: string;
        createdAt: any;
        updatedAt: any;
        insurancePriceTo?: number | null;
        selfPayPriceFrom?: number | null;
        insurancePriceFrom?: number | null;
        selfPayPriceTo?: number | null;
        description?: string | null;
        unit: Unit;
        unitValue: number;
        category: ProductCategory;
        status: ProductStatus;
        taxClass: TaxClass;
        sku: string;
        imageURL: string;
        uniqueProductIdentifiers?: string | null;
        specialProductIdentifier?: string | null;
        pharmacyProductIDs?: string | null;
        generalMarketAvailability?: boolean | null;
        cbdQuantityTo?: number | null;
        cbdQuantityFrom?: number | null;
        thcQuantityFrom?: number | null;
        thcQuantityTo?: number | null;
        averageMarketPrice?: number | null;
        iliosSalesPrice?: number | null;
        terpenesProfileText: string;
        medicalEffectText?: string | null;
        slotOrder?: number | null;
        strain: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        genetics: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        manufacturer: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        irradiation: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          value: string;
          label: string;
          type: MetaFieldType;
        }>;
        bestAvailability: {
          __typename?: "PublicAvailability";
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
          availableInDaysFrom?: number | null;
          currentAvailability: ProductAvailabilityOptions;
        };
        terpenes: Array<{
          __typename?: "Terpene";
          id: string;
          productId: string;
          contributionPercentage?: number | null;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
        medicalEffects: Array<{
          __typename?: "MedicalEffect";
          labelId?: string | null;
          productId: string;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
      };
      availability: {
        __typename?: "ProductAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        gramsAvailable?: number | null;
      };
      pharmacy: { __typename?: "Pharmacy"; name: string };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      total: number;
      hasNextPage: boolean;
      nextCursor?: string | null;
    };
  };
};

export type UpdatePharmacyProductDetailsMutationVariables = Exact<{
  input: PharmacyProductDetailsInput;
}>;

export type UpdatePharmacyProductDetailsMutation = {
  __typename?: "Mutation";
  updatePharmacyProductDetails: {
    __typename?: "UpdatePharmacyProductDetailsResponse";
    result: {
      __typename?: "PharmacyProductDetails";
      productId: any;
      pharmacyId: any;
      thcPercentage?: number | null;
      cbdPercentage?: number | null;
      thcPercentageIsLessThan: boolean;
      cbdPercentageIsLessThan: boolean;
      pharmacyProductIDs?: string | null;
      selfPayPrice?: number | null;
      insurancePrice?: number | null;
      basePrice?: number | null;
      product: {
        __typename?: "Product";
        id: any;
        fullName: string;
        createdAt: any;
        updatedAt: any;
        insurancePriceTo?: number | null;
        selfPayPriceFrom?: number | null;
        insurancePriceFrom?: number | null;
        selfPayPriceTo?: number | null;
        description?: string | null;
        unit: Unit;
        unitValue: number;
        category: ProductCategory;
        status: ProductStatus;
        taxClass: TaxClass;
        sku: string;
        imageURL: string;
        uniqueProductIdentifiers?: string | null;
        specialProductIdentifier?: string | null;
        pharmacyProductIDs?: string | null;
        generalMarketAvailability?: boolean | null;
        cbdQuantityTo?: number | null;
        cbdQuantityFrom?: number | null;
        thcQuantityFrom?: number | null;
        thcQuantityTo?: number | null;
        averageMarketPrice?: number | null;
        iliosSalesPrice?: number | null;
        terpenesProfileText: string;
        medicalEffectText?: string | null;
        slotOrder?: number | null;
        strain: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        genetics: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        manufacturer: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        irradiation: {
          __typename?: "Label";
          id: number;
          name: string;
          namespace: string;
          imageURL?: string | null;
        };
        metaFields: Array<{
          __typename?: "MetaField";
          name: string;
          value: string;
          label: string;
          type: MetaFieldType;
        }>;
        bestAvailability: {
          __typename?: "PublicAvailability";
          availableInDaysTo?: number | null;
          dateAvailableFrom?: any | null;
          availableInDaysFrom?: number | null;
          currentAvailability: ProductAvailabilityOptions;
        };
        terpenes: Array<{
          __typename?: "Terpene";
          id: string;
          productId: string;
          contributionPercentage?: number | null;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
        medicalEffects: Array<{
          __typename?: "MedicalEffect";
          labelId?: string | null;
          productId: string;
          label: {
            __typename?: "Label";
            id: number;
            name: string;
            namespace: string;
            imageURL?: string | null;
          };
        }>;
      };
      availability: {
        __typename?: "ProductAvailability";
        currentAvailability: ProductAvailabilityOptions;
        availableInDaysFrom?: number | null;
        availableInDaysTo?: number | null;
        dateAvailableFrom?: any | null;
        gramsAvailable?: number | null;
      };
      pharmacy: { __typename?: "Pharmacy"; name: string };
    };
  };
};

export type PharmacyProductDetailsFragment = {
  __typename?: "PharmacyProductDetails";
  productId: any;
  pharmacyId: any;
  thcPercentage?: number | null;
  cbdPercentage?: number | null;
  thcPercentageIsLessThan: boolean;
  cbdPercentageIsLessThan: boolean;
  pharmacyProductIDs?: string | null;
  selfPayPrice?: number | null;
  insurancePrice?: number | null;
  basePrice?: number | null;
  product: {
    __typename?: "Product";
    id: any;
    fullName: string;
    createdAt: any;
    updatedAt: any;
    insurancePriceTo?: number | null;
    selfPayPriceFrom?: number | null;
    insurancePriceFrom?: number | null;
    selfPayPriceTo?: number | null;
    description?: string | null;
    unit: Unit;
    unitValue: number;
    category: ProductCategory;
    status: ProductStatus;
    taxClass: TaxClass;
    sku: string;
    imageURL: string;
    uniqueProductIdentifiers?: string | null;
    specialProductIdentifier?: string | null;
    pharmacyProductIDs?: string | null;
    generalMarketAvailability?: boolean | null;
    cbdQuantityTo?: number | null;
    cbdQuantityFrom?: number | null;
    thcQuantityFrom?: number | null;
    thcQuantityTo?: number | null;
    averageMarketPrice?: number | null;
    iliosSalesPrice?: number | null;
    terpenesProfileText: string;
    medicalEffectText?: string | null;
    slotOrder?: number | null;
    strain: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
    genetics: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
    manufacturer: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
    irradiation: {
      __typename?: "Label";
      id: number;
      name: string;
      namespace: string;
      imageURL?: string | null;
    };
    metaFields: Array<{
      __typename?: "MetaField";
      name: string;
      value: string;
      label: string;
      type: MetaFieldType;
    }>;
    bestAvailability: {
      __typename?: "PublicAvailability";
      availableInDaysTo?: number | null;
      dateAvailableFrom?: any | null;
      availableInDaysFrom?: number | null;
      currentAvailability: ProductAvailabilityOptions;
    };
    terpenes: Array<{
      __typename?: "Terpene";
      id: string;
      productId: string;
      contributionPercentage?: number | null;
      label: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
    }>;
    medicalEffects: Array<{
      __typename?: "MedicalEffect";
      labelId?: string | null;
      productId: string;
      label: {
        __typename?: "Label";
        id: number;
        name: string;
        namespace: string;
        imageURL?: string | null;
      };
    }>;
  };
  availability: {
    __typename?: "ProductAvailability";
    currentAvailability: ProductAvailabilityOptions;
    availableInDaysFrom?: number | null;
    availableInDaysTo?: number | null;
    dateAvailableFrom?: any | null;
    gramsAvailable?: number | null;
  };
  pharmacy: { __typename?: "Pharmacy"; name: string };
};

export type UpdatePharmacyProductBulkMutationVariables = Exact<{
  input: UpdatePharmacyProductBulkInput;
}>;

export type UpdatePharmacyProductBulkMutation = {
  __typename?: "Mutation";
  updatePharmacyProductBulk: { __typename?: "UpdatePharmacyProductBulkResponse"; success: boolean };
};

export type ImportPharmacyProductsMutationVariables = Exact<{
  input: PharmacyProductImportInput;
}>;

export type ImportPharmacyProductsMutation = {
  __typename?: "Mutation";
  importPharmacyProducts: {
    __typename?: "PharmacyProductImportResponse";
    updated: number;
    messages: Array<string>;
  };
};

export type TicketFragment = {
  __typename?: "Ticket";
  id: string;
  status: TicketStatus;
  subject: string;
  createdAt: any;
  updatedAt: any;
  latestMessageSentTimestamp?: any | null;
  latestMessageReceivedTimestamp?: any | null;
  messages: Array<{
    __typename?: "TicketMessage";
    direction: TicketMessageDirection;
    text: string;
    createdAt: any;
    updatedAt?: any | null;
    attachments: Array<{
      __typename?: "TicketAttachment";
      url: string;
      type: TicketAttachmentType;
    }>;
  }>;
};

export type TicketsQueryVariables = Exact<{
  input: TicketsInput;
}>;

export type TicketsQuery = {
  __typename?: "Query";
  tickets: {
    __typename?: "TicketsResponse";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      total: number;
      nextCursor?: string | null;
    };
    results: Array<{
      __typename?: "Ticket";
      id: string;
      status: TicketStatus;
      subject: string;
      createdAt: any;
      updatedAt: any;
      latestMessageSentTimestamp?: any | null;
      latestMessageReceivedTimestamp?: any | null;
      messages: Array<{
        __typename?: "TicketMessage";
        direction: TicketMessageDirection;
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        attachments: Array<{
          __typename?: "TicketAttachment";
          url: string;
          type: TicketAttachmentType;
        }>;
      }>;
    }>;
  };
};

export type SendTicketMessageMutationVariables = Exact<{
  input: SendTicketMessageInput;
}>;

export type SendTicketMessageMutation = {
  __typename?: "Mutation";
  sendTicketMessage: {
    __typename?: "SendTicketMessageResponse";
    result: {
      __typename?: "Ticket";
      id: string;
      status: TicketStatus;
      subject: string;
      createdAt: any;
      updatedAt: any;
      latestMessageSentTimestamp?: any | null;
      latestMessageReceivedTimestamp?: any | null;
      messages: Array<{
        __typename?: "TicketMessage";
        direction: TicketMessageDirection;
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        attachments: Array<{
          __typename?: "TicketAttachment";
          url: string;
          type: TicketAttachmentType;
        }>;
      }>;
    };
  };
};

export type CloseSupportTicketMutationVariables = Exact<{
  input: CloseTicketInput;
}>;

export type CloseSupportTicketMutation = {
  __typename?: "Mutation";
  closeTicket: { __typename?: "CloseTicketResponse"; success: boolean };
};

export type AddCheckoutNoteMutationVariables = Exact<{
  input: AddCheckoutNoteInput;
}>;

export type AddCheckoutNoteMutation = {
  __typename?: "Mutation";
  addCheckoutNote: { __typename?: "AddCheckoutNoteResponse"; success: boolean };
};

export type AddNoteMutationVariables = Exact<{
  input: AddNoteInput;
}>;

export type AddNoteMutation = {
  __typename?: "Mutation";
  addNote: { __typename?: "AddNoteResponse"; success: boolean };
};

export type GetCheckoutNoteQueryVariables = Exact<{
  input: CheckoutNotesInput;
}>;

export type GetCheckoutNoteQuery = {
  __typename?: "Query";
  getCheckoutNotes: {
    __typename?: "CheckoutNotesResponse";
    results: Array<{
      __typename?: "CheckoutNote";
      createdAt: any;
      id: string;
      updatedAt: any;
      richText: string;
      attachments: Array<{
        __typename?: "HubspotAttachment";
        expiresAt: any;
        extension: string;
        height?: number | null;
        name: string;
        url: string;
      }>;
      sender: { __typename?: "OrderNoteSender"; id: string; name?: string | null };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export type GetNotesQueryVariables = Exact<{
  input: NotesInput;
}>;

export type GetNotesQuery = {
  __typename?: "Query";
  getNotes: {
    __typename?: "NotesResponse";
    results: Array<{
      __typename?: "Note";
      createdAt: any;
      id: string;
      updatedAt: any;
      richText: string;
      attachments: Array<{
        __typename?: "HubspotAttachment";
        expiresAt: any;
        extension: string;
        height?: number | null;
        name: string;
        url: string;
      }>;
      sender: { __typename?: "NoteSender"; id: string; name?: string | null };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      nextCursor?: string | null;
      total: number;
    };
  };
};

export const MetaFieldsFragmentDoc = gql`
  fragment metaFields on MetaField {
    name
    label
    value
    type
  }
`;
export const AvailabilityFragmentDoc = gql`
  fragment availability on PublicAvailability {
    currentAvailability
    availableInDaysFrom
    availableInDaysTo
    dateAvailableFrom
  }
`;
export const CheckoutFragmentDoc = gql`
  fragment checkout on Checkout {
    id
    orderNumber
    userName
    firstName
    lastName
    userEmail
    userId
    userInsuranceType
    dateShippedOn
    dateOrderedOn
    dateDeliveredOn
    noteCount
    invoiceBillingDate
    invoiceNumber
    cancellationInvoiceNumber
    paymentMethod
    InternalInvoiceNumber
    proposedProductsStatus
    shouldDisableChangeLink
    products {
      product {
        taxClass
        id
        fullName
        metaFields {
          ...metaFields
        }
        strain {
          id
          name
          namespace
          imageURL
        }
        description
        availability {
          ...availability
        }
        unit
        unitValue
        startingPrice
        imageURL
      }
      quantity
      price
    }
    outstandingAmount
    payments {
      id
      method
      amount
      status
    }
    proposedCheckoutDetails {
      products {
        product {
          taxClass
          id
          fullName
          metaFields {
            ...metaFields
          }
          description
          availability {
            ...availability
          }
          startingPrice
          imageURL
          unit
          unitValue
        }
        quantity
        price
      }
      extras {
        name
        description
        quantity
        price
      }
      subtotal
      deliveryFee
      total
    }
    originalCheckoutDetails {
      products {
        product {
          taxClass
          id
          fullName
          metaFields {
            ...metaFields
          }
          description
          availability {
            ...availability
          }
          startingPrice
          imageURL
          unit
          unitValue
        }
        quantity
        price
      }
      extras {
        name
        description
        quantity
        price
      }
      subtotal
      deliveryFee
      total
    }
    paymentStatus
    prescriptionIssues {
      type
      message
    }
    prescriptionOnlineStatus
    deliveryStatus
    prescriptionOfflineStatus
    pharmacy {
      id
      name
      email
      subDomain
      phoneNumber
    }
    prescriptionData {
      prescriptionFilePath
      coverageFilePath
      isAlgeaCare
      metaFields {
        ...metaFields
      }
    }
    updatedAt
    createdAt
    status
    nextStep
    subtotal
    subtotalNet
    total
    totalNet
    extras {
      name
      description
      quantity
      price
    }
    Prescriptions {
      id
      checkoutId
      prescriptionData {
        prescriptionFilePath
        coverageFilePath
        isAlgeaCare
        metaFields {
          ...metaFields
        }
      }
      epDocumentID
    }
    shippingAddress {
      id
      street
      houseNumber
      city
      state
      street
      zip
      country
      metaFields {
        ...metaFields
      }
    }
    deliveryOption {
      id
      name
      type
      price
    }
    invoiceLink
    cancellationInvoiceLink
    deliverySlipLink
    shippingLabelLink
    productLabelLink
    productsStatus
    documentationStatus
    isWaitingForDoctorApproval
    areProductsOrdered
    shipmentId
    trackingLink
    shipmentWeightInGrams
    isPharmacySignatureNeeded
    workflowurl
    secSignPortfolioID
    isPharmacySignatureNeeded
    documentSignatureStatus
    ePrescriptionDownloadedByUser
    ePrescriptionDownloadedAt
  }
  ${MetaFieldsFragmentDoc}
  ${AvailabilityFragmentDoc}
`;
export const SingleCheckoutFragmentDoc = gql`
  fragment singleCheckout on Checkout {
    id
    orderNumber
    userName
    firstName
    lastName
    userEmail
    userId
    userInsuranceType
    dateShippedOn
    dateOrderedOn
    dateDeliveredOn
    noteCount
    invoiceBillingDate
    invoiceNumber
    cancellationInvoiceNumber
    paymentMethod
    InternalInvoiceNumber
    shouldDisableChangeLink
    products {
      product {
        taxClass
        id
        fullName
        metaFields {
          ...metaFields
        }
        strain {
          id
          name
          namespace
          imageURL
        }
        description
        availability {
          ...availability
        }
        unit
        unitValue
        startingPrice
        imageURL
      }
      quantity
      price
    }
    proposedCheckoutDetails {
      products {
        product {
          taxClass
          id
          fullName
          metaFields {
            ...metaFields
          }
          description
          availability {
            ...availability
          }
          startingPrice
          imageURL
          unit
          unitValue
        }
        quantity
        price
      }
      extras {
        name
        description
        quantity
        price
      }
      subtotal
      deliveryFee
      total
    }
    proposedProductsStatus
    outstandingAmount
    payments {
      id
      method
      amount
      status
    }
    originalCheckoutDetails {
      products {
        product {
          taxClass
          id
          fullName
          metaFields {
            ...metaFields
          }
          description
          availability {
            ...availability
          }
          startingPrice
          imageURL
          unit
          unitValue
        }
        quantity
        price
      }
      extras {
        name
        description
        quantity
        price
      }
      subtotal
      deliveryFee
      total
    }
    paymentStatus
    prescriptionIssues {
      type
      message
    }
    prescriptionOnlineStatus
    deliveryStatus
    prescriptionOfflineStatus
    pharmacy {
      id
      name
      email
      subDomain
      teamLocationPictureURL
      prescriptionEnvelopeURL
      logoURL
      website
      openingHours
      paymentMethods {
        method
        message
      }
      dataProtectionOfficerEmail
      dataProtectionOfficerName
      useOrderConfirmationInsteadOfInvoice
      bankInfo {
        owner
        iban
        bic
        bank
      }
      address {
        id
        street
        houseNumber
        city
        state
        street
        zip
        country
        createdAt
        updatedAt
        metaFields {
          ...metaFields
        }
      }
      phoneNumber
    }
    billingAddress {
      id
      street
      houseNumber
      city
      state
      zip
      country
      metaFields {
        ...metaFields
      }
    }
    prescriptionData {
      prescriptionFilePath
      coverageFilePath
      isAlgeaCare
      metaFields {
        ...metaFields
      }
    }
    updatedAt
    createdAt
    status
    nextStep
    subtotal
    subtotalNet
    total
    totalNet
    extras {
      name
      description
      quantity
      price
    }
    Prescriptions {
      id
      checkoutId
      prescriptionData {
        prescriptionFilePath
        coverageFilePath
        isAlgeaCare
        metaFields {
          ...metaFields
        }
      }
      epDocumentID
    }
    shippingAddress {
      id
      street
      houseNumber
      city
      state
      street
      zip
      country
      metaFields {
        ...metaFields
      }
    }
    deliveryOption {
      id
      name
      type
      price
    }
    invoiceLink
    cancellationInvoiceLink
    deliverySlipLink
    shippingLabelLink
    productLabelLink
    productsStatus
    documentationStatus
    isWaitingForDoctorApproval
    areProductsOrdered
    shipmentId
    trackingLink
    shipmentWeightInGrams
    isPharmacySignatureNeeded
    workflowurl
    secSignPortfolioID
    ePrescriptionDownloadedByUser
    ePrescriptionDownloadedAt
    isPharmacySignatureNeeded
    documentSignatureStatus
  }
  ${MetaFieldsFragmentDoc}
  ${AvailabilityFragmentDoc}
`;
export const WebshopCheckoutFragmentDoc = gql`
  fragment webshopCheckout on Checkout {
    id
    orderNumber
    userName
    firstName
    lastName
    userEmail
    userId
    userInsuranceType
    dateShippedOn
    dateOrderedOn
    dateDeliveredOn
    noteCount
    invoiceBillingDate
    invoiceNumber
    cancellationInvoiceNumber
    paymentMethod
    InternalInvoiceNumber
    shouldDisableChangeLink
    products {
      product {
        taxClass
        id
        fullName
        metaFields {
          ...metaFields
        }
        strain {
          id
          name
          namespace
          imageURL
        }
        description
        availability {
          ...availability
        }
        unit
        unitValue
        startingPrice
        imageURL
      }
      quantity
      price
    }
    outstandingAmount
    payments {
      id
      method
      amount
      status
    }
    originalCheckoutDetails {
      products {
        product {
          taxClass
          id
          fullName
          metaFields {
            ...metaFields
          }
          description
          availability {
            ...availability
          }
          startingPrice
          imageURL
          unit
          unitValue
        }
        quantity
        price
      }
      extras {
        name
        description
        quantity
        price
      }
      subtotal
      deliveryFee
      total
    }
    paymentStatus
    prescriptionIssues {
      type
      message
    }
    prescriptionOnlineStatus
    deliveryStatus
    prescriptionOfflineStatus
    pharmacy {
      id
      name
      email
      subDomain
      teamLocationPictureURL
      prescriptionEnvelopeURL
      logoURL
      website
      openingHours
      agb
      description
      imprint
      privacyPolicy
      paymentMethods {
        method
        message
      }
      dataProtectionOfficerEmail
      dataProtectionOfficerName
      useOrderConfirmationInsteadOfInvoice
      bankInfo {
        owner
        iban
        bic
        bank
      }
      address {
        id
        street
        houseNumber
        city
        state
        street
        zip
        country
        createdAt
        updatedAt
        metaFields {
          ...metaFields
        }
      }
      phoneNumber
    }
    billingAddress {
      id
      street
      houseNumber
      city
      state
      zip
      country
      metaFields {
        ...metaFields
      }
    }
    prescriptionData {
      prescriptionFilePath
      coverageFilePath
      isAlgeaCare
      metaFields {
        ...metaFields
      }
    }
    updatedAt
    createdAt
    status
    nextStep
    subtotal
    subtotalNet
    total
    totalNet
    extras {
      name
      description
      quantity
      price
    }
    Prescriptions {
      id
      checkoutId
      prescriptionData {
        prescriptionFilePath
        coverageFilePath
        isAlgeaCare
        metaFields {
          ...metaFields
        }
      }
      epDocumentID
    }
    shippingAddress {
      id
      street
      houseNumber
      city
      state
      street
      zip
      country
      metaFields {
        ...metaFields
      }
    }
    deliveryOption {
      id
      name
      type
      price
    }
    invoiceLink
    cancellationInvoiceLink
    deliverySlipLink
    shippingLabelLink
    productLabelLink
    productsStatus
    documentationStatus
    isWaitingForDoctorApproval
    areProductsOrdered
    shipmentId
    trackingLink
    shipmentWeightInGrams
    isPharmacySignatureNeeded
    workflowurl
    secSignPortfolioID
    ePrescriptionDownloadedByUser
    ePrescriptionDownloadedAt
    isPharmacySignatureNeeded
    documentSignatureStatus
  }
  ${MetaFieldsFragmentDoc}
  ${AvailabilityFragmentDoc}
`;
export const AmountOfOrdersFragmentDoc = gql`
  fragment amountOfOrders on AmountOfOrders {
    total
    pending
    fromAlgaeCare
    completed
    cancelled
    open
    orderApproved
    adjustments
    shippedPickup
    waitingForApproval
    waitingForProcessing
    processing
  }
`;
export const RevenueFragmentDoc = gql`
  fragment revenue on Revenue {
    total
    averageOrderValue
    averageOrderValueFromAlgaeCare
    totalFromAlgaeCare
  }
`;
export const FlowersSoldFragmentDoc = gql`
  fragment flowersSold on FlowersSold {
    total
    averageOrderValue
    averageOrderValueIlios
    totalIlios
  }
`;
export const CustomersFragmentDoc = gql`
  fragment customers on Customers {
    total
    new
  }
`;
export const LabelFragmentDoc = gql`
  fragment label on Label {
    id
    name
    namespace
    imageURL
  }
`;
export const PublicProductFragmentDoc = gql`
  fragment publicProduct on PublicProduct {
    startingPrice
    startingPriceIsInsurance
    strain {
      ...label
    }
    genetics {
      ...label
    }
    manufacturer {
      ...label
    }
    irradiation {
      ...label
    }
    availability {
      ...availability
    }
    wholesaler {
      id
      namespace
      name
      imageURL
    }
    taxClass
    id
    fullName
    metaFields {
      name
      value
      label
      type
    }
    unit
    uniqueProductIdentifiers
    specialProductIdentifier
    pharmacyProductIDs
    generalMarketAvailability
    cbdQuantityTo
    cbdQuantityFrom
    thcQuantityFrom
    thcQuantityTo
    averageMarketPrice
    iliosSalesPrice
    basePrice
    unitValue
    category
    taxClass
    description
    imageURL
    terpenes {
      id
      label {
        ...label
      }
      productId
      contributionPercentage
    }
    medicalEffects {
      id
      labelId
      productId
      label {
        ...label
      }
    }
    terpenesProfileText
    medicalEffectText
    slotOrder
  }
  ${LabelFragmentDoc}
  ${AvailabilityFragmentDoc}
`;
export const NcmsDetailFragmentDoc = gql`
  fragment ncmsDetail on NcmDetails {
    id
    firstName
    lastName
    updatedAt
    createdAt
    status
    completedDate
    statusUser
    insuranceType
    billingAddress {
      id
      street
      houseNumber
      city
      country
      zip
      state
      createdAt
      updatedAt
      metaFields {
        name
        label
        value
        type
      }
    }
    isAlgeaCare
    prescriptionMetaFields {
      name
      label
      value
      type
    }
    userId
    products {
      product {
        ...publicProduct
      }
      quantity
    }
    Prescriptions {
      id
      ncmId
      prescriptionData {
        prescriptionFilePath
        coverageFilePath
        isAlgeaCare
        metaFields {
          name
          label
          value
          type
        }
      }
      prescriptionFilePath
    }
  }
  ${PublicProductFragmentDoc}
`;
export const PasswordFragmentDoc = gql`
  fragment password on Secret {
    length
    lastChars
  }
`;
export const PharmacyDeliveryOptionFragmentDoc = gql`
  fragment pharmacyDeliveryOption on PharmacyDeliveryOptions {
    dhlDetails {
      username
      password {
        ...password
      }
      apiKey {
        ...password
      }
      billingNumber
    }
    goDetails {
      username
      responsibleStation
      customerID
      pickupTimeTo
      pickupTimeFrom
      password {
        ...password
      }
      usernameTracking
      passwordTracking {
        ...password
      }
    }
  }
  ${PasswordFragmentDoc}
`;
export const CustomShippingDetailsFragmentDoc = gql`
  fragment customShippingDetails on CustomShippingDetails {
    minPriceToFreeShipment
    flatRate
    pharmacyID
  }
`;
export const PharmacyFragmentDoc = gql`
  fragment pharmacy on Pharmacy {
    id
    name
    legalName
    website
    subDomain
    email
    phoneNumber
    createdAt
    updatedAt
    updateBy
    status
    openingHours
    prescriptionEnvelopeURL
    pharmacyCapacityStatus
    mediumCapacity
    heavyCapacity
    user {
      name
      email
      phone
    }
    address {
      street
      houseNumber
      city
      state
      zip
      country
      metaFields {
        ...metaFields
      }
      createdAt
      updatedAt
    }
    deliveryOptions {
      ...pharmacyDeliveryOption
    }
    taxNumber
    useOrderConfirmationInsteadOfInvoice
    invoiceFooter
    invoicePaymentText
    invoiceShowBankDetails
    invoiceShowPaymentText
    customShippingDetails {
      ...customShippingDetails
    }
  }
  ${MetaFieldsFragmentDoc}
  ${PharmacyDeliveryOptionFragmentDoc}
  ${CustomShippingDetailsFragmentDoc}
`;
export const PharmacyPaymentOptionFragmentDoc = gql`
  fragment pharmacyPaymentOption on PharmacyPaymentOptions {
    unzerDetails {
      publicKey
      privateKey {
        length
        lastChars
      }
    }
    bankDetails {
      owner
      iban
      bic
      bank
    }
    paymentMethods
    paymentMethodsOptions {
      method
      message
    }
  }
`;
export const PharmacyDhlSettingsFragmentDoc = gql`
  fragment pharmacyDHLSettings on PharmacyDHLSettings {
    useIdentCheck
    useAgeCheck
    useDirectDelivery
  }
`;
export const SinglePharmacyFragmentDoc = gql`
  fragment singlePharmacy on Pharmacy {
    ...pharmacy
    agb
    privacyPolicy
    dataProtectionOfficerEmail
    dataProtectionOfficerName
    description
    imprint
    createdAt
    logoURL
    createdAt
    iKPharmacyNumber
    prescriptionEnvelopeURL
    teamLocationPictureURL
    notificationPreferences {
      newOrder
      lowStock
      lowStockTriggerValue
      newProduct
      missingProductDetails
      ticketUpdate
      invalidErpConnection
      orderStatusUpdate
    }
    stockOptions {
      manual
      winApoDetails {
        default
      }
      ixosDetails {
        idf
        password {
          ...password
        }
        pharmacyNr
        pharmacyEmail
      }
    }
    paymentOptions {
      ...pharmacyPaymentOption
    }
    deliveryOptions {
      ...pharmacyDeliveryOption
    }
    dhlSettings {
      ...pharmacyDHLSettings
    }
  }
  ${PharmacyFragmentDoc}
  ${PasswordFragmentDoc}
  ${PharmacyPaymentOptionFragmentDoc}
  ${PharmacyDeliveryOptionFragmentDoc}
  ${PharmacyDhlSettingsFragmentDoc}
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    id
    fullName
    strain {
      ...label
    }
    genetics {
      ...label
    }
    manufacturer {
      ...label
    }
    irradiation {
      ...label
    }
    metaFields {
      name
      value
      label
      type
    }
    createdAt
    updatedAt
    insurancePriceTo
    selfPayPriceFrom
    insurancePriceFrom
    selfPayPriceTo
    description
    unit
    unitValue
    category
    status
    taxClass
    sku
    imageURL
    uniqueProductIdentifiers
    specialProductIdentifier
    pharmacyProductIDs
    generalMarketAvailability
    cbdQuantityTo
    cbdQuantityFrom
    thcQuantityFrom
    thcQuantityTo
    averageMarketPrice
    iliosSalesPrice
    bestAvailability {
      availableInDaysTo
      dateAvailableFrom
      availableInDaysFrom
      currentAvailability
    }
    terpenes {
      id
      label {
        ...label
      }
      productId
      contributionPercentage
    }
    medicalEffects {
      labelId
      productId
      label {
        ...label
      }
    }
    terpenesProfileText
    medicalEffectText
    slotOrder
  }
  ${LabelFragmentDoc}
`;
export const PharmacyProductDetailsFragmentDoc = gql`
  fragment pharmacyProductDetails on PharmacyProductDetails {
    productId
    product {
      ...product
    }
    pharmacyId
    thcPercentage
    cbdPercentage
    thcPercentageIsLessThan
    cbdPercentageIsLessThan
    pharmacyProductIDs
    selfPayPrice
    insurancePrice
    basePrice
    availability {
      currentAvailability
      availableInDaysFrom
      availableInDaysTo
      dateAvailableFrom
      gramsAvailable
    }
    pharmacy {
      name
    }
  }
  ${ProductFragmentDoc}
`;
export const TicketFragmentDoc = gql`
  fragment ticket on Ticket {
    id
    status
    subject
    createdAt
    updatedAt
    latestMessageSentTimestamp
    latestMessageReceivedTimestamp
    messages {
      direction
      text
      attachments {
        url
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const CreateAccountDocument = gql`
  mutation createAccount($input: CreateAccountRequest!) {
    createAccount(input: $input) {
      success
    }
  }
`;
export const LoginDocument = gql`
  mutation login($input: LoginRequest!) {
    login(input: $input) {
      success
    }
  }
`;
export const UserDetailsDocument = gql`
  query userDetails($input: UserDetailsInput!) {
    userDetails(input: $input) {
      user {
        id
        firstName
        lastName
        email
        permissions
        roles
        coverage
        insuranceType
        dateOfBirth
        isAlgeaCare
        insuranceName
        notificationPreferences {
          newsletter
          notificationType
          ticketStatus
          orderStatus
        }
        insuranceContact
        billingAddress {
          id
          street
          houseNumber
          city
          state
          street
          zip
          country
          createdAt
          updatedAt
          metaFields {
            ...metaFields
          }
        }
        shippingAddress {
          id
          street
          houseNumber
          city
          state
          street
          zip
          country
          createdAt
          updatedAt
          metaFields {
            ...metaFields
          }
        }
      }
    }
  }
  ${MetaFieldsFragmentDoc}
`;
export const LogoutDocument = gql`
  mutation logout($input: LogoutRequest!) {
    logout(input: $input) {
      success
    }
  }
`;
export const UpdateMyAccountDocument = gql`
  mutation updateMyAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      success
    }
  }
`;
export const DeleteUserAccountDocument = gql`
  mutation deleteUserAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      success
    }
  }
`;
export const UpdateNotificationPreferencesDocument = gql`
  mutation updateNotificationPreferences($input: UpdateNotificationPreferencesInput!) {
    updateNotificationPreferences(input: $input) {
      success
    }
  }
`;
export const ChangePasswordDocument = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
    }
  }
`;
export const RefreshDocument = gql`
  mutation refresh($input: RefreshTokenInput!) {
    refresh(input: $input) {
      success
    }
  }
`;
export const AvailableOffersDocument = gql`
  query availableOffers($input: AvailableOffersInput!) {
    availableOffers(input: $input) {
      lowestPrice
      offers {
        id
        availability {
          ...availability
        }
        subTotal
        pharmacy {
          id
          name
          subDomain
          email
          phoneNumber
          name
          logoURL
          teamLocationPictureURL
          prescriptionEnvelopeURL
          customShippingDetails {
            minPriceToFreeShipment
            flatRate
            pharmacyID
          }
        }
        products {
          product {
            ...publicProduct
          }
          price
          quantity
          basePrice
        }
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${AvailabilityFragmentDoc}
  ${PublicProductFragmentDoc}
`;
export const AvailableDeliveryOptionsDocument = gql`
  query availableDeliveryOptions($input: AvailableDeliveryOptionsInput!) {
    availableDeliveryOptions(input: $input) {
      deliveryOptions {
        id
        name
        type
        price
      }
    }
  }
`;
export const AvailablePaymentMethodsDocument = gql`
  query availablePaymentMethods($input: AvailablePaymentMethodsInput!) {
    availablePaymentMethods(input: $input) {
      paymentMethods {
        type
        allowsForAuthorization
      }
      unzerBaseUrl
      unzerPublicKey
    }
  }
`;
export const UpdateCheckoutDocument = gql`
  mutation updateCheckout($input: UpdateCheckoutInput!) {
    updateCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const PreliminaryCheckoutDocument = gql`
  query preliminaryCheckout($input: CheckoutPreviewNewCartInput!) {
    checkoutPreviewNewCart(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CancelCheckoutDocument = gql`
  mutation cancelCheckout($input: CheckoutCancelInput!) {
    cancelCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CreateCheckoutDocument = gql`
  mutation createCheckout($input: CreateCheckoutInput!) {
    createCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CheckoutUpdateAddressesDocument = gql`
  mutation checkoutUpdateAddresses($input: CheckoutUpdateAddressesInput!) {
    checkoutUpdateAddresses(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CheckoutUpdatPrescriptionDataDocument = gql`
  mutation checkoutUpdatPrescriptionData($input: CheckoutUpdatePrescriptionDataInput!) {
    checkoutUpdatePrescriptionData(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CheckoutUpdateDeliveryOptionDocument = gql`
  mutation checkoutUpdateDeliveryOption($input: CheckoutUpdateDeliveryOptionInput!) {
    checkoutUpdateDeliveryOption(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutCustomerDetailsStatusDocument = gql`
  mutation updateCheckoutCustomerDetailsStatus($input: CheckoutCustomerDetailsStatusUpdateInput!) {
    updateCheckoutCustomerDetailsStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutProductStatusDocument = gql`
  mutation updateCheckoutProductStatus($input: CheckoutProductStatusUpdateInput!) {
    updateCheckoutProductStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutDocumentationStatusDocument = gql`
  mutation updateCheckoutDocumentationStatus($input: CheckoutDocumentationStatusUpdateInput!) {
    updateCheckoutDocumentationStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutWaitingForDoctorApprovalDocument = gql`
  mutation updateCheckoutWaitingForDoctorApproval($input: ToggleWaitingForDoctorApprovalInput!) {
    updateCheckoutWaitingForDoctorApproval(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateUpdateCheckoutProductsOrderedDocument = gql`
  mutation updateUpdateCheckoutProductsOrdered($input: ProductsOrderedInput!) {
    updateUpdateCheckoutProductsOrdered(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CheckoutPaymentDocument = gql`
  mutation checkoutPayment($input: CheckoutPaymentInput!) {
    checkoutPayment(input: $input) {
      ... on CheckoutPaymentRedirectResponse {
        redirectUrl
      }
      ... on CheckoutPaymentSuccessResponse {
        success
      }
    }
  }
`;
export const CalculatePharmacyCapacityDocument = gql`
  mutation calculatePharmacyCapacity($input: CalculatePharmacyCapacityInput!) {
    calculatePharmacyCapacity(input: $input) {
      success
    }
  }
`;
export const FilterCheckoutsDocument = gql`
  query filterCheckouts($input: FilterCheckoutsInput!) {
    filterCheckouts(input: $input) {
      results {
        ...checkout
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const FilterWebshopCheckoutsDocument = gql`
  query filterWebshopCheckouts($input: FilterCheckoutsInput!) {
    filterCheckouts(input: $input) {
      results {
        ...webshopCheckout
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${WebshopCheckoutFragmentDoc}
`;
export const FilterSingleCheckoutDocument = gql`
  query filterSingleCheckout($input: FilterCheckoutsInput!) {
    filterCheckouts(input: $input) {
      results {
        ...singleCheckout
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${SingleCheckoutFragmentDoc}
`;
export const FilterCheckoutsCountDocument = gql`
  query filterCheckoutsCount($input: FilterCheckoutsCountInput!) {
    filterCheckoutsCount(input: $input) {
      results {
        status
        count
      }
    }
  }
`;
export const CheckoutDocument = gql`
  query checkout($input: GetCheckoutInput!) {
    checkout(input: $input) {
      checkout {
        ...webshopCheckout
      }
    }
  }
  ${WebshopCheckoutFragmentDoc}
`;
export const CheckoutDownloadDocument = gql`
  query checkoutDownload($input: GetCheckoutInput!) {
    checkout(input: $input) {
      checkout {
        invoiceLink
        deliverySlipLink
        shippingLabelLink
        cancellationInvoiceLink
        productLabelLink
        shipmentId
        status
        pharmacy {
          useOrderConfirmationInsteadOfInvoice
        }
      }
    }
  }
`;
export const FilterCheckoutGroupsDocument = gql`
  query filterCheckoutGroups($input: FilterCheckoutsInput!) {
    filterCheckoutGroups(input: $input) {
      results {
        id
        checkouts {
          ...checkout
        }
        groupStatus
        isLocked
        createdAt
        updatedAt
        ordersCount
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const ProcessBulkESignatureDocument = gql`
  mutation processBulkESignature($input: BulkESignatureInput!) {
    processBulkESignature(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const DeleteCheckoutDocument = gql`
  mutation deleteCheckout($input: DeleteCheckoutInput!) {
    deleteCheckout(input: $input) {
      success
    }
  }
`;
export const UpdateCheckoutPaymentMethodDocument = gql`
  mutation updateCheckoutPaymentMethod($input: CheckoutUpdatePaymentMethodAdminInput!) {
    updateCheckoutPaymentMethod(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CheckoutCapturePaymentDocument = gql`
  mutation checkoutCapturePayment($input: CheckoutCapturePaymentInput!) {
    checkoutCapturePayment(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutShippingWeightDocument = gql`
  mutation updateCheckoutShippingWeight($input: CheckoutShippingWeightInGramsUpdateInput!) {
    updateCheckoutShippingWeight(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const FilterCustomersDocument = gql`
  query filterCustomers($input: FilterCustomersInput!) {
    filterCustomers(input: $input) {
      results {
        insuranceType
        id
        firstName
        lastName
        email
        billingAddress {
          state
          street
          houseNumber
          city
          country
          createdAt
          updatedAt
          zip
          metaFields {
            ...metaFields
          }
        }
        shippingAddress {
          state
          street
          houseNumber
          city
          country
          createdAt
          updatedAt
          zip
          metaFields {
            ...metaFields
          }
        }
        isAlgeaCare
        insuranceType
        coverage
        numOrders
        dateOfBirth
        outstandingOrders {
          status
          subtotal
          total
          totalNet
          trackingLink
          userEmail
          userId
          userInsuranceType
          userName
          productsStatus
          outstandingAmount
          orderNumber
          nextStep
          paymentStatus
          id
          dateOrderedOn
          dateShippedOn
          dateDeliveredOn
          createdAt
          prescriptionOfflineStatus
          prescriptionOnlineStatus
          isWaitingForDoctorApproval
          areProductsOrdered
          documentationStatus
          deliveryStatus
          shipmentId
        }
        createdAt
      }
      pageInfo {
        hasNextPage
        total
        nextCursor
      }
    }
  }
  ${MetaFieldsFragmentDoc}
`;
export const GetDashboardDataDocument = gql`
  query getDashboardData($input: DashboardDataInput!) {
    dashboardData(input: $input) {
      productData {
        data {
          amountOfOrders
          averageOrderQuantity
          averageOrderRevenue
          revenue
          totalVolume
        }
        previousData {
          amountOfOrders
          averageOrderQuantity
          averageOrderRevenue
          revenue
          totalVolume
        }
        product {
          ...publicProduct
        }
      }
      data {
        pharmacy {
          name
          status
          pharmacyCapacityStatus
          mediumCapacity
          heavyCapacity
        }
        data {
          amountOfOrders {
            ...amountOfOrders
          }
          revenue {
            ...revenue
          }
          flowersSold {
            ...flowersSold
          }
          customers {
            ...customers
          }
        }
      }
      total {
        amountOfOrders {
          ...amountOfOrders
        }
        revenue {
          ...revenue
        }
        flowersSold {
          ...flowersSold
        }
        customers {
          ...customers
        }
      }
      previousTotal {
        amountOfOrders {
          ...amountOfOrders
        }
        revenue {
          ...revenue
        }
        flowersSold {
          ...flowersSold
        }
        customers {
          ...customers
        }
      }
    }
  }
  ${PublicProductFragmentDoc}
  ${AmountOfOrdersFragmentDoc}
  ${RevenueFragmentDoc}
  ${FlowersSoldFragmentDoc}
  ${CustomersFragmentDoc}
`;
export const GetEventsDocument = gql`
  query getEvents($request: EventsInput!) {
    events(request: $request) {
      result {
        id
        status
        date
        description
      }
      nextCursor
    }
  }
`;
export const InfoDocument = gql`
  query info($input: InfoInput!) {
    info(input: $input) {
      version
      name
      description
      lastSupportedAppVersion
    }
  }
`;
export const AuditLogsDocument = gql`
  query auditLogs($input: AuditLogInput!) {
    auditLogs(input: $input) {
      results {
        title
        details
        itemId
        newValues
        itemType
        date
        userId
        userName
        userEmail
        newValues
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
`;
export const LabelsDocument = gql`
  query labels($input: FilterLabelInput) {
    labels(input: $input) {
      results {
        ...label
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${LabelFragmentDoc}
`;
export const CreateLabelDocument = gql`
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      label {
        ...label
      }
    }
  }
  ${LabelFragmentDoc}
`;
export const UpdateLabelDocument = gql`
  mutation updateLabel($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      label {
        ...label
      }
    }
  }
  ${LabelFragmentDoc}
`;
export const DeleteLabelDocument = gql`
  mutation deleteLabel($input: DeleteLabelInput!) {
    deleteLabel(input: $input) {
      success
      message
    }
  }
`;
export const CreateManualCheckoutDocument = gql`
  mutation createManualCheckout($input: CreateManualCheckoutInput!) {
    createManualCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateManualCheckoutDocument = gql`
  mutation updateManualCheckout($input: UpdateManualCheckoutInput!) {
    updateManualCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const SubmitManualCheckoutDocument = gql`
  mutation submitManualCheckout($input: SubmitManualCheckoutInput!) {
    submitManualCheckout(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const CreateNcmDocument = gql`
  mutation createNcm($input: CreateNcmInput!) {
    createNcm(input: $input) {
      result
    }
  }
`;
export const UpdateNcmDocument = gql`
  mutation updateNcm($input: UpdateNcmInput!) {
    updateNcm(input: $input) {
      result {
        ...ncmsDetail
      }
    }
  }
  ${NcmsDetailFragmentDoc}
`;
export const CompleteNcmDocument = gql`
  mutation completeNCM($input: CompleteNCMInput!) {
    completeNCM(input: $input) {
      result {
        ...ncmsDetail
      }
    }
  }
  ${NcmsDetailFragmentDoc}
`;
export const DeleteNcmDocument = gql`
  mutation deleteNCM($input: DeleteNCMInput!) {
    deleteNCM(input: $input) {
      result {
        ...ncmsDetail
      }
    }
  }
  ${NcmsDetailFragmentDoc}
`;
export const RejectNcmDocument = gql`
  mutation rejectNCM($input: RejectNCMInput!) {
    rejectNCM(input: $input) {
      result {
        ...ncmsDetail
      }
    }
  }
  ${NcmsDetailFragmentDoc}
`;
export const NcmDocument = gql`
  query ncm($input: NcmInput!) {
    ncm(input: $input) {
      result {
        id
        products {
          product {
            ...publicProduct
          }
          quantity
        }
      }
    }
  }
  ${PublicProductFragmentDoc}
`;
export const FilterNcmsDocument = gql`
  query filterNcms($input: FilterNcmsInput!) {
    filterNcms(input: $input) {
      results {
        ...ncmsDetail
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${NcmsDetailFragmentDoc}
`;
export const NotificationsDocument = gql`
  query notifications($input: NotificationsInput!) {
    notifications(input: $input) {
      results {
        id
        type
        name
        relatedObjects {
          type
          id
        }
        date
        status
        task
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
`;
export const UpdateNotificationDocument = gql`
  mutation updateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      success
    }
  }
`;
export const CancelWorkflowRequestDocument = gql`
  mutation cancelWorkflowRequest($input: CancelWorkflowRequestInput!) {
    cancelWorkflowRequest(input: $input) {
      success
    }
  }
`;
export const UpdateCheckoutDeliveryStatusDocument = gql`
  mutation updateCheckoutDeliveryStatus($input: CheckoutDeliveryStatusUpdateInput!) {
    updateCheckoutDeliveryStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutPaymentStatusDocument = gql`
  mutation updateCheckoutPaymentStatus($input: CheckoutPaymentStatusUpdateInput!) {
    updateCheckoutPaymentStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateInternalInvoiceNumberDocument = gql`
  mutation updateInternalInvoiceNumber($input: InternalInvoiceNumberInput!) {
    updateInternalInvoiceNumber(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutPrescriptionStatusDocument = gql`
  mutation updateCheckoutPrescriptionStatus($input: CheckoutPrescriptionStatusUpdateInput!) {
    updateCheckoutPrescriptionStatus(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const AcceptCheckoutChangeDocument = gql`
  mutation acceptCheckoutChange($input: AcceptCheckoutChangesInput!) {
    acceptCheckoutChanges(input: $input) {
      ... on CheckoutPaymentRedirectResponse {
        redirectUrl
      }
      ... on AcceptCheckoutChangesSuccessResponse {
        success
      }
    }
  }
`;
export const UpdateCheckoutProposeLateProductChangeDocument = gql`
  mutation updateCheckoutProposeLateProductChange($input: CheckoutProposeLateProductChangeInput!) {
    updateCheckoutProposeLateProductChange(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const UpdateCheckoutDeliveryOptionDocument = gql`
  mutation updateCheckoutDeliveryOption($input: CheckoutUpdateDeliveryOptionAdminInput!) {
    updateCheckoutDeliveryOption(input: $input) {
      checkout {
        ...checkout
      }
    }
  }
  ${CheckoutFragmentDoc}
`;
export const FetchSignedPrescriptionDocument = gql`
  query fetchSignedPrescription($input: FetchSignedPrescriptionInput!) {
    fetchSignedPrescription(input: $input) {
      signedPrescription
    }
  }
`;
export const FilterPharmaciesDocument = gql`
  query filterPharmacies($input: FilterPharmacyInput!) {
    filterPharmacies(input: $input) {
      results {
        ...pharmacy
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${PharmacyFragmentDoc}
`;
export const PharmacyDetailDocument = gql`
  query pharmacyDetail($input: PharmacyDetailInput!) {
    pharmacyDetail(input: $input) {
      result {
        ...singlePharmacy
      }
    }
  }
  ${SinglePharmacyFragmentDoc}
`;
export const FilterPublicPharmaciesDocument = gql`
  query filterPublicPharmacies($input: FilterPublicPharmacyInput!) {
    filterPublicPharmacies(input: $input) {
      results {
        id
        name
        website
        subDomain
        email
        phoneNumber
        agb
        customShippingDetails {
          minPriceToFreeShipment
          flatRate
          pharmacyID
        }
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
`;
export const CreatePharmacyDocument = gql`
  mutation createPharmacy($input: CreatePharmacyInput!) {
    createPharmacy(input: $input) {
      result {
        ...pharmacy
      }
    }
  }
  ${PharmacyFragmentDoc}
`;
export const UpdatePharmacyDocument = gql`
  mutation updatePharmacy($input: UpdatePharmacyInput!) {
    updatePharmacy(input: $input) {
      result {
        ...singlePharmacy
      }
    }
  }
  ${SinglePharmacyFragmentDoc}
`;
export const UpdatePharmacyStatusDocument = gql`
  mutation updatePharmacyStatus($input: UpdatePharmacyStatusInput!) {
    updatePharmacyStatus(input: $input) {
      result {
        ...pharmacy
      }
    }
  }
  ${PharmacyFragmentDoc}
`;
export const UpdatePharmacyDeliveryOptionsDocument = gql`
  mutation updatePharmacyDeliveryOptions($input: UpdatePharmacyDeliveryOptionsInput!) {
    updatePharmacyDeliveryOptions(input: $input) {
      result {
        ...pharmacyDeliveryOption
      }
    }
  }
  ${PharmacyDeliveryOptionFragmentDoc}
`;
export const UpdatePharmacyPaymentOptionsDocument = gql`
  mutation updatePharmacyPaymentOptions($input: UpdatePharmacyPaymentOptionsInput!) {
    updatePharmacyPaymentOptions(input: $input) {
      result {
        ...pharmacyPaymentOption
      }
    }
  }
  ${PharmacyPaymentOptionFragmentDoc}
`;
export const UpdatePharmacyDhlSettingsDocument = gql`
  mutation updatePharmacyDHLSettings($input: UpdatePharmacyDHLSettingsInput!) {
    updatePharmacyDHLSettings(input: $input) {
      result {
        ...pharmacyDHLSettings
      }
    }
  }
  ${PharmacyDhlSettingsFragmentDoc}
`;
export const UpdatePharmacyStockManagementDocument = gql`
  mutation updatePharmacyStockManagement($input: UpdatePharmacyStockManagementInput!) {
    updatePharmacyStockManagement(input: $input) {
      result {
        manual
        winApoDetails {
          default
        }
        ixosDetails {
          idf
          password {
            ...password
          }
          pharmacyNr
          pharmacyEmail
        }
      }
    }
  }
  ${PasswordFragmentDoc}
`;
export const UpdatePharmacyNotificationPreferencesDocument = gql`
  mutation updatePharmacyNotificationPreferences($input: PharmacyNotificationPreferencesInput!) {
    updatePharmacyNotificationPreferences(input: { notificationPreferences: $input }) {
      success
    }
  }
`;
export const GetUserPharmacyDocument = gql`
  query getUserPharmacy($input: FilterUserPharmacyInput!) {
    getUserPharmacy(input: $input) {
      result {
        ...singlePharmacy
        missingProperties
      }
    }
  }
  ${SinglePharmacyFragmentDoc}
`;
export const GetAvailableDeliveryOptionsDocument = gql`
  query getAvailableDeliveryOptions($input: GetAvailableDeliveryOptionsInput!) {
    getAvailableDeliveryOptions(input: $input) {
      results {
        id
        name
        type
        price
      }
    }
  }
`;
export const CreateProductDocument = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      result {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export const UpdateProductStatusDocument = gql`
  mutation updateProductStatus($input: UpdateProductStatusInput!) {
    updateProductStatus(input: $input) {
      result {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export const HandleDeleteProductDocument = gql`
  mutation handleDeleteProduct($input: DeleteProductInput!) {
    handleDeleteProduct(input: $input) {
      success
    }
  }
`;
export const UpdateProductDocument = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      result {
        __typename
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export const UpdateProductBulkDocument = gql`
  mutation updateProductBulk($input: UpdateProductBulkInput!) {
    updateProductBulk(input: $input) {
      success
    }
  }
`;
export const FilterProductDocument = gql`
  query filterProduct($input: FilterProductInput!) {
    filterProducts(input: $input) {
      results {
        ...product
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${ProductFragmentDoc}
`;
export const FilterPublicProductsDocument = gql`
  query filterPublicProducts($input: FilterPublicProductInput!) {
    filterPublicProducts(input: $input) {
      results {
        ...publicProduct
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
  ${PublicProductFragmentDoc}
`;
export const FilterPharmacyProductDetailsDocument = gql`
  query filterPharmacyProductDetails($input: FilterPharmacyProductDetailsInput!) {
    filterPharmacyProductDetails(input: $input) {
      results {
        ...pharmacyProductDetails
      }
      pageInfo {
        total
        hasNextPage
        nextCursor
      }
    }
  }
  ${PharmacyProductDetailsFragmentDoc}
`;
export const UpdatePharmacyProductDetailsDocument = gql`
  mutation updatePharmacyProductDetails($input: PharmacyProductDetailsInput!) {
    updatePharmacyProductDetails(input: $input) {
      result {
        ...pharmacyProductDetails
      }
    }
  }
  ${PharmacyProductDetailsFragmentDoc}
`;
export const UpdatePharmacyProductBulkDocument = gql`
  mutation updatePharmacyProductBulk($input: UpdatePharmacyProductBulkInput!) {
    updatePharmacyProductBulk(input: $input) {
      success
    }
  }
`;
export const ImportPharmacyProductsDocument = gql`
  mutation importPharmacyProducts($input: PharmacyProductImportInput!) {
    importPharmacyProducts(input: $input) {
      updated
      messages
    }
  }
`;
export const TicketsDocument = gql`
  query tickets($input: TicketsInput!) {
    tickets(input: $input) {
      pageInfo {
        hasNextPage
        total
        nextCursor
      }
      results {
        ...ticket
      }
    }
  }
  ${TicketFragmentDoc}
`;
export const SendTicketMessageDocument = gql`
  mutation sendTicketMessage($input: SendTicketMessageInput!) {
    sendTicketMessage(input: $input) {
      result {
        ...ticket
      }
    }
  }
  ${TicketFragmentDoc}
`;
export const CloseSupportTicketDocument = gql`
  mutation closeSupportTicket($input: CloseTicketInput!) {
    closeTicket(input: $input) {
      success
    }
  }
`;
export const AddCheckoutNoteDocument = gql`
  mutation addCheckoutNote($input: AddCheckoutNoteInput!) {
    addCheckoutNote(input: $input) {
      success
    }
  }
`;
export const AddNoteDocument = gql`
  mutation addNote($input: AddNoteInput!) {
    addNote(input: $input) {
      success
    }
  }
`;
export const GetCheckoutNoteDocument = gql`
  query getCheckoutNote($input: CheckoutNotesInput!) {
    getCheckoutNotes(input: $input) {
      results {
        attachments {
          expiresAt
          extension
          height
          name
          url
        }
        sender {
          id
          name
        }
        createdAt
        id
        updatedAt
        richText
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
`;
export const GetNotesDocument = gql`
  query getNotes($input: NotesInput!) {
    getNotes(input: $input) {
      results {
        attachments {
          expiresAt
          extension
          height
          name
          url
        }
        sender {
          id
          name
        }
        createdAt
        id
        updatedAt
        richText
      }
      pageInfo {
        hasNextPage
        nextCursor
        total
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createAccount(
      variables: CreateAccountMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateAccountMutation>(CreateAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createAccount",
        "mutation",
      );
    },
    login(
      variables: LoginMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<LoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginMutation>(LoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "login",
        "mutation",
      );
    },
    userDetails(
      variables: UserDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UserDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserDetailsQuery>(UserDetailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "userDetails",
        "query",
      );
    },
    logout(
      variables: LogoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<LogoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LogoutMutation>(LogoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "logout",
        "mutation",
      );
    },
    updateMyAccount(
      variables: UpdateMyAccountMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateMyAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMyAccountMutation>(UpdateMyAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateMyAccount",
        "mutation",
      );
    },
    deleteUserAccount(
      variables: DeleteUserAccountMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<DeleteUserAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserAccountMutation>(DeleteUserAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteUserAccount",
        "mutation",
      );
    },
    updateNotificationPreferences(
      variables: UpdateNotificationPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateNotificationPreferencesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateNotificationPreferencesMutation>(
            UpdateNotificationPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateNotificationPreferences",
        "mutation",
      );
    },
    changePassword(
      variables: ChangePasswordMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ChangePasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangePasswordMutation>(ChangePasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "changePassword",
        "mutation",
      );
    },
    refresh(
      variables: RefreshMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<RefreshMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RefreshMutation>(RefreshDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "refresh",
        "mutation",
      );
    },
    availableOffers(
      variables: AvailableOffersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AvailableOffersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AvailableOffersQuery>(AvailableOffersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "availableOffers",
        "query",
      );
    },
    availableDeliveryOptions(
      variables: AvailableDeliveryOptionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AvailableDeliveryOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AvailableDeliveryOptionsQuery>(
            AvailableDeliveryOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "availableDeliveryOptions",
        "query",
      );
    },
    availablePaymentMethods(
      variables: AvailablePaymentMethodsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AvailablePaymentMethodsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AvailablePaymentMethodsQuery>(AvailablePaymentMethodsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "availablePaymentMethods",
        "query",
      );
    },
    updateCheckout(
      variables: UpdateCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutMutation>(UpdateCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateCheckout",
        "mutation",
      );
    },
    preliminaryCheckout(
      variables: PreliminaryCheckoutQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<PreliminaryCheckoutQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PreliminaryCheckoutQuery>(PreliminaryCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "preliminaryCheckout",
        "query",
      );
    },
    cancelCheckout(
      variables: CancelCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CancelCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelCheckoutMutation>(CancelCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "cancelCheckout",
        "mutation",
      );
    },
    createCheckout(
      variables: CreateCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateCheckoutMutation>(CreateCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createCheckout",
        "mutation",
      );
    },
    checkoutUpdateAddresses(
      variables: CheckoutUpdateAddressesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutUpdateAddressesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutUpdateAddressesMutation>(
            CheckoutUpdateAddressesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "checkoutUpdateAddresses",
        "mutation",
      );
    },
    checkoutUpdatPrescriptionData(
      variables: CheckoutUpdatPrescriptionDataMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutUpdatPrescriptionDataMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutUpdatPrescriptionDataMutation>(
            CheckoutUpdatPrescriptionDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "checkoutUpdatPrescriptionData",
        "mutation",
      );
    },
    checkoutUpdateDeliveryOption(
      variables: CheckoutUpdateDeliveryOptionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutUpdateDeliveryOptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutUpdateDeliveryOptionMutation>(
            CheckoutUpdateDeliveryOptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "checkoutUpdateDeliveryOption",
        "mutation",
      );
    },
    updateCheckoutCustomerDetailsStatus(
      variables: UpdateCheckoutCustomerDetailsStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutCustomerDetailsStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutCustomerDetailsStatusMutation>(
            UpdateCheckoutCustomerDetailsStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutCustomerDetailsStatus",
        "mutation",
      );
    },
    updateCheckoutProductStatus(
      variables: UpdateCheckoutProductStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutProductStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutProductStatusMutation>(
            UpdateCheckoutProductStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutProductStatus",
        "mutation",
      );
    },
    updateCheckoutDocumentationStatus(
      variables: UpdateCheckoutDocumentationStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutDocumentationStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutDocumentationStatusMutation>(
            UpdateCheckoutDocumentationStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutDocumentationStatus",
        "mutation",
      );
    },
    updateCheckoutWaitingForDoctorApproval(
      variables: UpdateCheckoutWaitingForDoctorApprovalMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutWaitingForDoctorApprovalMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutWaitingForDoctorApprovalMutation>(
            UpdateCheckoutWaitingForDoctorApprovalDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutWaitingForDoctorApproval",
        "mutation",
      );
    },
    updateUpdateCheckoutProductsOrdered(
      variables: UpdateUpdateCheckoutProductsOrderedMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateUpdateCheckoutProductsOrderedMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUpdateCheckoutProductsOrderedMutation>(
            UpdateUpdateCheckoutProductsOrderedDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateUpdateCheckoutProductsOrdered",
        "mutation",
      );
    },
    checkoutPayment(
      variables: CheckoutPaymentMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutPaymentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutPaymentMutation>(CheckoutPaymentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "checkoutPayment",
        "mutation",
      );
    },
    calculatePharmacyCapacity(
      variables: CalculatePharmacyCapacityMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CalculatePharmacyCapacityMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CalculatePharmacyCapacityMutation>(
            CalculatePharmacyCapacityDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "calculatePharmacyCapacity",
        "mutation",
      );
    },
    filterCheckouts(
      variables: FilterCheckoutsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterCheckoutsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterCheckoutsQuery>(FilterCheckoutsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterCheckouts",
        "query",
      );
    },
    filterWebshopCheckouts(
      variables: FilterWebshopCheckoutsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterWebshopCheckoutsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterWebshopCheckoutsQuery>(FilterWebshopCheckoutsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterWebshopCheckouts",
        "query",
      );
    },
    filterSingleCheckout(
      variables: FilterSingleCheckoutQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterSingleCheckoutQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterSingleCheckoutQuery>(FilterSingleCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterSingleCheckout",
        "query",
      );
    },
    filterCheckoutsCount(
      variables: FilterCheckoutsCountQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterCheckoutsCountQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterCheckoutsCountQuery>(FilterCheckoutsCountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterCheckoutsCount",
        "query",
      );
    },
    checkout(
      variables: CheckoutQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutQuery>(CheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "checkout",
        "query",
      );
    },
    checkoutDownload(
      variables: CheckoutDownloadQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutDownloadQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutDownloadQuery>(CheckoutDownloadDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "checkoutDownload",
        "query",
      );
    },
    filterCheckoutGroups(
      variables: FilterCheckoutGroupsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterCheckoutGroupsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterCheckoutGroupsQuery>(FilterCheckoutGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterCheckoutGroups",
        "query",
      );
    },
    processBulkESignature(
      variables: ProcessBulkESignatureMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ProcessBulkESignatureMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProcessBulkESignatureMutation>(ProcessBulkESignatureDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "processBulkESignature",
        "mutation",
      );
    },
    deleteCheckout(
      variables: DeleteCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<DeleteCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteCheckoutMutation>(DeleteCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteCheckout",
        "mutation",
      );
    },
    updateCheckoutPaymentMethod(
      variables: UpdateCheckoutPaymentMethodMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutPaymentMethodMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutPaymentMethodMutation>(
            UpdateCheckoutPaymentMethodDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutPaymentMethod",
        "mutation",
      );
    },
    checkoutCapturePayment(
      variables: CheckoutCapturePaymentMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CheckoutCapturePaymentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutCapturePaymentMutation>(
            CheckoutCapturePaymentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "checkoutCapturePayment",
        "mutation",
      );
    },
    updateCheckoutShippingWeight(
      variables: UpdateCheckoutShippingWeightMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutShippingWeightMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutShippingWeightMutation>(
            UpdateCheckoutShippingWeightDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutShippingWeight",
        "mutation",
      );
    },
    filterCustomers(
      variables: FilterCustomersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterCustomersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterCustomersQuery>(FilterCustomersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterCustomers",
        "query",
      );
    },
    getDashboardData(
      variables: GetDashboardDataQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetDashboardDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetDashboardDataQuery>(GetDashboardDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getDashboardData",
        "query",
      );
    },
    getEvents(
      variables: GetEventsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetEventsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEventsQuery>(GetEventsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEvents",
        "query",
      );
    },
    info(
      variables: InfoQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<InfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InfoQuery>(InfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "info",
        "query",
      );
    },
    auditLogs(
      variables: AuditLogsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AuditLogsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuditLogsQuery>(AuditLogsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "auditLogs",
        "query",
      );
    },
    labels(
      variables?: LabelsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<LabelsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LabelsQuery>(LabelsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "labels",
        "query",
      );
    },
    createLabel(
      variables: CreateLabelMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateLabelMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateLabelMutation>(CreateLabelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createLabel",
        "mutation",
      );
    },
    updateLabel(
      variables: UpdateLabelMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateLabelMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateLabelMutation>(UpdateLabelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateLabel",
        "mutation",
      );
    },
    deleteLabel(
      variables: DeleteLabelMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<DeleteLabelMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteLabelMutation>(DeleteLabelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteLabel",
        "mutation",
      );
    },
    createManualCheckout(
      variables: CreateManualCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateManualCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateManualCheckoutMutation>(CreateManualCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createManualCheckout",
        "mutation",
      );
    },
    updateManualCheckout(
      variables: UpdateManualCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateManualCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateManualCheckoutMutation>(UpdateManualCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateManualCheckout",
        "mutation",
      );
    },
    submitManualCheckout(
      variables: SubmitManualCheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<SubmitManualCheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubmitManualCheckoutMutation>(SubmitManualCheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "submitManualCheckout",
        "mutation",
      );
    },
    createNcm(
      variables: CreateNcmMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateNcmMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateNcmMutation>(CreateNcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createNcm",
        "mutation",
      );
    },
    updateNcm(
      variables: UpdateNcmMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateNcmMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateNcmMutation>(UpdateNcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateNcm",
        "mutation",
      );
    },
    completeNCM(
      variables: CompleteNcmMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CompleteNcmMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CompleteNcmMutation>(CompleteNcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "completeNCM",
        "mutation",
      );
    },
    deleteNCM(
      variables: DeleteNcmMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<DeleteNcmMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteNcmMutation>(DeleteNcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteNCM",
        "mutation",
      );
    },
    rejectNCM(
      variables: RejectNcmMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<RejectNcmMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectNcmMutation>(RejectNcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "rejectNCM",
        "mutation",
      );
    },
    ncm(
      variables: NcmQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<NcmQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NcmQuery>(NcmDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ncm",
        "query",
      );
    },
    filterNcms(
      variables: FilterNcmsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterNcmsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterNcmsQuery>(FilterNcmsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterNcms",
        "query",
      );
    },
    notifications(
      variables: NotificationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<NotificationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NotificationsQuery>(NotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "notifications",
        "query",
      );
    },
    updateNotification(
      variables: UpdateNotificationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateNotificationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateNotificationMutation>(UpdateNotificationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateNotification",
        "mutation",
      );
    },
    cancelWorkflowRequest(
      variables: CancelWorkflowRequestMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CancelWorkflowRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelWorkflowRequestMutation>(CancelWorkflowRequestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "cancelWorkflowRequest",
        "mutation",
      );
    },
    updateCheckoutDeliveryStatus(
      variables: UpdateCheckoutDeliveryStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutDeliveryStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutDeliveryStatusMutation>(
            UpdateCheckoutDeliveryStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutDeliveryStatus",
        "mutation",
      );
    },
    updateCheckoutPaymentStatus(
      variables: UpdateCheckoutPaymentStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutPaymentStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutPaymentStatusMutation>(
            UpdateCheckoutPaymentStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutPaymentStatus",
        "mutation",
      );
    },
    updateInternalInvoiceNumber(
      variables: UpdateInternalInvoiceNumberMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateInternalInvoiceNumberMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateInternalInvoiceNumberMutation>(
            UpdateInternalInvoiceNumberDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateInternalInvoiceNumber",
        "mutation",
      );
    },
    updateCheckoutPrescriptionStatus(
      variables: UpdateCheckoutPrescriptionStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutPrescriptionStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutPrescriptionStatusMutation>(
            UpdateCheckoutPrescriptionStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutPrescriptionStatus",
        "mutation",
      );
    },
    acceptCheckoutChange(
      variables: AcceptCheckoutChangeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AcceptCheckoutChangeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AcceptCheckoutChangeMutation>(AcceptCheckoutChangeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "acceptCheckoutChange",
        "mutation",
      );
    },
    updateCheckoutProposeLateProductChange(
      variables: UpdateCheckoutProposeLateProductChangeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutProposeLateProductChangeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutProposeLateProductChangeMutation>(
            UpdateCheckoutProposeLateProductChangeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutProposeLateProductChange",
        "mutation",
      );
    },
    updateCheckoutDeliveryOption(
      variables: UpdateCheckoutDeliveryOptionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateCheckoutDeliveryOptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCheckoutDeliveryOptionMutation>(
            UpdateCheckoutDeliveryOptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateCheckoutDeliveryOption",
        "mutation",
      );
    },
    fetchSignedPrescription(
      variables: FetchSignedPrescriptionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchSignedPrescriptionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchSignedPrescriptionQuery>(FetchSignedPrescriptionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "fetchSignedPrescription",
        "query",
      );
    },
    filterPharmacies(
      variables: FilterPharmaciesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterPharmaciesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterPharmaciesQuery>(FilterPharmaciesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterPharmacies",
        "query",
      );
    },
    pharmacyDetail(
      variables: PharmacyDetailQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<PharmacyDetailQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PharmacyDetailQuery>(PharmacyDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "pharmacyDetail",
        "query",
      );
    },
    filterPublicPharmacies(
      variables: FilterPublicPharmaciesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterPublicPharmaciesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterPublicPharmaciesQuery>(FilterPublicPharmaciesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterPublicPharmacies",
        "query",
      );
    },
    createPharmacy(
      variables: CreatePharmacyMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreatePharmacyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePharmacyMutation>(CreatePharmacyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createPharmacy",
        "mutation",
      );
    },
    updatePharmacy(
      variables: UpdatePharmacyMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyMutation>(UpdatePharmacyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updatePharmacy",
        "mutation",
      );
    },
    updatePharmacyStatus(
      variables: UpdatePharmacyStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyStatusMutation>(UpdatePharmacyStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updatePharmacyStatus",
        "mutation",
      );
    },
    updatePharmacyDeliveryOptions(
      variables: UpdatePharmacyDeliveryOptionsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyDeliveryOptionsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyDeliveryOptionsMutation>(
            UpdatePharmacyDeliveryOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyDeliveryOptions",
        "mutation",
      );
    },
    updatePharmacyPaymentOptions(
      variables: UpdatePharmacyPaymentOptionsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyPaymentOptionsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyPaymentOptionsMutation>(
            UpdatePharmacyPaymentOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyPaymentOptions",
        "mutation",
      );
    },
    updatePharmacyDHLSettings(
      variables: UpdatePharmacyDhlSettingsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyDhlSettingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyDhlSettingsMutation>(
            UpdatePharmacyDhlSettingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyDHLSettings",
        "mutation",
      );
    },
    updatePharmacyStockManagement(
      variables: UpdatePharmacyStockManagementMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyStockManagementMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyStockManagementMutation>(
            UpdatePharmacyStockManagementDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyStockManagement",
        "mutation",
      );
    },
    updatePharmacyNotificationPreferences(
      variables: UpdatePharmacyNotificationPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyNotificationPreferencesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyNotificationPreferencesMutation>(
            UpdatePharmacyNotificationPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyNotificationPreferences",
        "mutation",
      );
    },
    getUserPharmacy(
      variables: GetUserPharmacyQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetUserPharmacyQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserPharmacyQuery>(GetUserPharmacyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getUserPharmacy",
        "query",
      );
    },
    getAvailableDeliveryOptions(
      variables: GetAvailableDeliveryOptionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetAvailableDeliveryOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAvailableDeliveryOptionsQuery>(
            GetAvailableDeliveryOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getAvailableDeliveryOptions",
        "query",
      );
    },
    createProduct(
      variables: CreateProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CreateProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateProductMutation>(CreateProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createProduct",
        "mutation",
      );
    },
    updateProductStatus(
      variables: UpdateProductStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateProductStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProductStatusMutation>(UpdateProductStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateProductStatus",
        "mutation",
      );
    },
    handleDeleteProduct(
      variables: HandleDeleteProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<HandleDeleteProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<HandleDeleteProductMutation>(HandleDeleteProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "handleDeleteProduct",
        "mutation",
      );
    },
    updateProduct(
      variables: UpdateProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProductMutation>(UpdateProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateProduct",
        "mutation",
      );
    },
    updateProductBulk(
      variables: UpdateProductBulkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdateProductBulkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProductBulkMutation>(UpdateProductBulkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateProductBulk",
        "mutation",
      );
    },
    filterProduct(
      variables: FilterProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterProductQuery>(FilterProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterProduct",
        "query",
      );
    },
    filterPublicProducts(
      variables: FilterPublicProductsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterPublicProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterPublicProductsQuery>(FilterPublicProductsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filterPublicProducts",
        "query",
      );
    },
    filterPharmacyProductDetails(
      variables: FilterPharmacyProductDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FilterPharmacyProductDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FilterPharmacyProductDetailsQuery>(
            FilterPharmacyProductDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "filterPharmacyProductDetails",
        "query",
      );
    },
    updatePharmacyProductDetails(
      variables: UpdatePharmacyProductDetailsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyProductDetailsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyProductDetailsMutation>(
            UpdatePharmacyProductDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyProductDetails",
        "mutation",
      );
    },
    updatePharmacyProductBulk(
      variables: UpdatePharmacyProductBulkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<UpdatePharmacyProductBulkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePharmacyProductBulkMutation>(
            UpdatePharmacyProductBulkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updatePharmacyProductBulk",
        "mutation",
      );
    },
    importPharmacyProducts(
      variables: ImportPharmacyProductsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ImportPharmacyProductsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ImportPharmacyProductsMutation>(
            ImportPharmacyProductsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "importPharmacyProducts",
        "mutation",
      );
    },
    tickets(
      variables: TicketsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<TicketsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TicketsQuery>(TicketsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "tickets",
        "query",
      );
    },
    sendTicketMessage(
      variables: SendTicketMessageMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<SendTicketMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendTicketMessageMutation>(SendTicketMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "sendTicketMessage",
        "mutation",
      );
    },
    closeSupportTicket(
      variables: CloseSupportTicketMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<CloseSupportTicketMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CloseSupportTicketMutation>(CloseSupportTicketDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "closeSupportTicket",
        "mutation",
      );
    },
    addCheckoutNote(
      variables: AddCheckoutNoteMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AddCheckoutNoteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddCheckoutNoteMutation>(AddCheckoutNoteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addCheckoutNote",
        "mutation",
      );
    },
    addNote(
      variables: AddNoteMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<AddNoteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddNoteMutation>(AddNoteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addNote",
        "mutation",
      );
    },
    getCheckoutNote(
      variables: GetCheckoutNoteQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetCheckoutNoteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCheckoutNoteQuery>(GetCheckoutNoteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCheckoutNote",
        "query",
      );
    },
    getNotes(
      variables: GetNotesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetNotesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNotesQuery>(GetNotesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getNotes",
        "query",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
