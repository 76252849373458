import { ComparatorValue, FilterComparatorBuilder, FilterComparatorFactory } from "../api/filter";

const filterComparatorFactoryStaticMethods = [
  "isEqual",
  "isNotEqual",
  "isGreaterThan",
  "isGreaterThanOrEqual",
  "isLessThan",
  "isLessThanOrEqual",
  "isLike",
  "isNotLike",
  "isIn",
  "isNotIn",
  "isNull",
  "isNotNull",
] as const;

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never;

export type OptionType<T> = {
  options?: {
    label: string;
    value: string;
    id: string;
    overrideField?: keyof T;
  }[];
  field?: keyof T;
  filterFactory: ElementType<typeof filterComparatorFactoryStaticMethods>;
  valueOverwrite?: (value?: ComparatorValue) => string;
};

export type FilterOptionProps<T, TvalueProps> = {
  [filterName in keyof TvalueProps]?: OptionType<T>;
};

/**
 *
 * @param filterOptions {FilterOptionProps}
 * @param values {object}
 * @description This is a generic helper function which takes two argument, filterOptions and values gotten from your filter state,
 * builds a filter comparator for every non-nullible value that exists in your filter state,
 * using the provided filter properties for that particular filter field
 * @returns { FilterComparatorBuilder[]}
 */
export const curateFilterComparators = <T, TValueProps extends Record<string, ComparatorValue>>(
  filterOptions: FilterOptionProps<T, TValueProps>,
  values: TValueProps,
): FilterComparatorBuilder<T>[] => {
  const comparators: FilterComparatorBuilder<T>[] = [];

  Object.keys(filterOptions).forEach((filter: keyof TValueProps) => {
    const value = values[filter];
    const filterName = filterOptions[filter] as OptionType<T>;
    if (value) {
      const field = (filterName.options?.find((option) => value === option.value)?.overrideField ??
        filterName.field) as keyof T;
      const comparator = FilterComparatorFactory[filterName?.filterFactory]<T>(
        field,
        filterName?.valueOverwrite?.(value) ?? value,
      );
      comparators.push(comparator);
    }
  });
  return comparators;
};
