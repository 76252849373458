import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { CgExpand } from "react-icons/cg";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import FullscreenLoadingIndicator from "../LoadingIndicators/Fullscreen";

export * from "./DrawerSubtitle";

export interface DrawerProps {
  /**
   * @memberof DrawerProps
   */
  title: string | React.ReactNode;
  /**
   * @memberof DrawerProps
   */
  toggleDrawer: () => void;
  /**
   * @memberof DrawerProps
   */
  isOpen?: boolean;
  doNotUseFooterClass?: boolean;

  header?: JSX.Element;
  isLoading?: boolean;

  footer?: JSX.Element;
  onClose?(): void;
  onOpen?(): void;
  disableHorizontalPadding?: boolean;

  titleClassName?: string;
  wrapperClassName?: string;

  containerClassName?: string;
  showHeaderToolbar?: boolean;
  isExpanded?: boolean;
}

/**
 *
 * @param title {String}
 * @param toggleDrawer {DrawerProps['toggleDrawer']}
 * @param isOpen {DrawerProps['isOpen']}
 * @param header {DrawerProps['header']}
 * @param footer {DrawerProps['footer'}
 * @param isExpanded  {DrawerProps['isExpanded'}
 * @description A simple sidebar (right) drawer with flexible dimensions (min-width:350px, max-width: 767px)
 * @example  <Drawer
 *             title="Drawer title"
 *             isOpen={true}
 *             toggleDrawer={() => setIsOpen(!isOpen)}>
 *             {children}
 *           </Drawer>
 * @returns {JSX.Element}
 */
export const Drawer = ({
  title,
  toggleDrawer,
  isOpen,
  children,
  header,
  footer,
  titleClassName,
  containerClassName,
  isLoading,
  disableHorizontalPadding,
  doNotUseFooterClass,
  wrapperClassName,
  showHeaderToolbar = true,
  onClose,
  ...props
}: PropsWithChildren<DrawerProps>): JSX.Element => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(props?.isExpanded ?? false);

  const onCloseDrawer = () => {
    if (isExpanded && !props?.isExpanded) setIsExpanded(false);
    toggleDrawer();
  };
  const handleOverlayClick = (e: any) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target as Node)) {
      onCloseDrawer();
    }
  };

  const isOpenRef = useRef<boolean>(isOpen ?? false);
  const prevIsOpenRef = useRef<boolean>(false);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    prevIsOpenRef.current = isOpenRef.current;
    isOpenRef.current = isOpen ?? false;
  }, [isOpen]);

  useEffect(() => {
    if (prevIsOpenRef.current && !isOpenRef.current) {
      onClose?.();
    }
  }, [prevIsOpenRef, isOpenRef]);

  useEffect(() => {
    (() => {
      if (!isOpen) {
        onClose?.();
      }
    })();
  }, [isOpen]);

  const horizontalPadding = "px-4 sm:px-8";
  const bodyMarginBottom = `${(footerRef.current?.scrollHeight || 100) + 5}px`;

  const width = isExpanded
    ? "w-[80%] max-w-full sm:max-w-[80%]"
    : "w-[100%] min-width-full sm:min-w-[400px] max-w-full md:max-w-[1000px]";
  const classValue = `transition-all duration-500 drawer h-[100vh] flex flex-col  ${
    !isOpen ? "inactive" : ""
  } fixed z-[40] top-0 right-0 overflow-y-auto overflow-x-hidden bg-white ${width} rounded-tl-lg rounded-bl-lg ${
    containerClassName ?? ""
  }`;

  return (
    <div
      onClick={handleOverlayClick}
      className={`${
        isOpen ? `fixed top-0 left-0 z-[30] bg-primary-dark/30 w-screen h-screen` : ""
      } flex justify-end transition-[background]`}
    >
      <aside ref={drawerRef} data-test-expanded={isExpanded ? "yes" : "no"} className={classValue}>
        <div
          className={`py-6 px-4 sm:px-8 bg-primary text-white flex flex-row items-center justify-between ${
            titleClassName ?? ""
          }`}
        >
          <div className='text-inherit font-medium text-2xl'>{title}</div>
          <button
            onClick={onCloseDrawer}
            className='w-[24px] h-[24px] text-inherit flex items-center justify-center rounded-[8px]'
          >
            <Cross width={"15px"} height='15px' className='text-inherit' />
          </button>
        </div>
        <div
          className={`${!disableHorizontalPadding ? horizontalPadding : ""} ${
            wrapperClassName ?? "py-5 flex flex-col flex-1 h-full overflow-hidden"
          }  `}
        >
          {showHeaderToolbar && (
            <div
              className={`header flex items-center justify-end ${
                disableHorizontalPadding ? horizontalPadding : ""
              } pb-3`}
            >
              {header && <div className='header-body flex-1'>{header}</div>}
              {!props?.isExpanded && (
                <button className='expandable ml-2' onClick={() => setIsExpanded(!isExpanded)}>
                  <CgExpand className='text-primary-50 text-2xl leading-none' />
                </button>
              )}
            </div>
          )}

          <div className='flex flex-col w-full h-full overflow-scroll scrollbar-hide'>
            <div
              className={`w-full h-[90%] flex-1 overflow-auto overflow-x-hidden`}
              style={{ paddingBottom: bodyMarginBottom }}
            >
              {isOpen && children}
            </div>
          </div>
        </div>
        {footer && (
          <div
            ref={footerRef}
            className={`${!disableHorizontalPadding ? horizontalPadding : ""} ${
              !doNotUseFooterClass &&
              "absolute bottom-0 left-0 right-0 z-[10]  footer flex flex-col justify-end py-6 bg-white"
            }`}
          >
            {footer}
          </div>
        )}
      </aside>

      <FullscreenLoadingIndicator
        wrapperClassName={`fixed top-0 bottom-0 min-h-[350px] right-0 ${width} bg-[#00000036]  z-[2000]`}
        isLoading={isLoading ?? false}
      />
    </div>
  );
};
