import { PaginationProps } from "libs/data-access/src/lib/hooks/usePaginationState";

import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

/**
 *
 * @param initialPage {number} - the least possible value is 1
 * @param pageInfo {PaginationProps['pageInfo]}
 * @param pageSize {PaginationProps['pageSize]}
 * @param onPageChange {PaginationProps['onPageChange]}
 * @returns {JSX.Element}
 */
const Pagination: React.FC<PaginationProps> = ({
  pageInfo,
  pageSize,
  onPageChange,
  currentPage,
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ReactPaginate
      pageCount={Math.ceil(pageInfo.total / pageSize)}
      pageRangeDisplayed={1}
      marginPagesDisplayed={2}
      onPageChange={(p) => onPageChange(p.selected)}
      containerClassName='inline-flex items-center justify-center list-none gap-2'
      activeClassName='items-center justify-center bg-primary-grey text-white !border-primary-grey'
      activeLinkClassName='items-center justify-center bg-primary-grey text-white !border-primary-grey'
      disabledClassName='cursor-not-allowed text-primary-lightgrey'
      disabledLinkClassName='cursor-not-allowed text-primary-lightgrey'
      previousClassName={prevNextStyle}
      previousLabel={t("Prev")}
      nextClassName={classNames({
        [prevNextStyle]: pageInfo.hasNextPage,
        hidden: !pageInfo.hasNextPage,
      })}
      nextLabel={t("Next")}
      forcePage={+currentPage}
      breakClassName={pageStyle}
      pageClassName={pageStyle}
      pageLinkClassName={pageStyle}
      disableInitialCallback
    />
  );
};

const prevNextStyle =
  "flex items-center justify-center border-none rounded-[8px] h-[24px] w-[37px] bg-white p-1";
const pageStyle =
  "flex items-center justify-center rounded-full bg-white border border-solid border-[#F1F1F1] w-[24px] h-[24px] text-base font-normal text-black font-gellix cursor-pointer";

export default Pagination;
