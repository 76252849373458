import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  sub,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export enum DateRangeEnum {
  ALL_TIME = "all_time",
  THIS_WEEK = "this_week",
  LAST_MONTH = "last_month",
  YESTERDAY = "yesterday",
  TODAY = "today",
  LAST_WEEK = "last_week",
  LAST_TWO_WEEKS = "last_two_weeks",
  THIS_MONTH = "this_month",
  THIS_YEAR = "this_year",
  LAST_YEAR = "last_year",
  CUSTOM = "custom",
}

export const useCustomDates = () => {
  const { t } = useTranslation();
  const customDateRanges = {
    [DateRangeEnum.TODAY]: {
      value: DateRangeEnum.TODAY,
      label: t("Today"),
      id: uuidv4(),
    },
    [DateRangeEnum.YESTERDAY]: {
      value: DateRangeEnum.YESTERDAY,
      label: t("Yesterday"),
      id: uuidv4(),
    },
    [DateRangeEnum.THIS_WEEK]: {
      value: DateRangeEnum.THIS_WEEK,
      label: t("This week"),
      id: uuidv4(),
    },
    [DateRangeEnum.LAST_WEEK]: {
      value: DateRangeEnum.LAST_WEEK,
      label: t("Last week"),
      id: uuidv4(),
    },
    [DateRangeEnum.LAST_TWO_WEEKS]: {
      value: DateRangeEnum.LAST_TWO_WEEKS,
      label: t("Last two weeks"),
      id: uuidv4(),
    },
    [DateRangeEnum.THIS_MONTH]: {
      value: DateRangeEnum.THIS_MONTH,
      label: t("This month"),
      id: uuidv4(),
    },
    [DateRangeEnum.LAST_MONTH]: {
      value: DateRangeEnum.LAST_MONTH,
      label: t("Last month"),
      id: uuidv4(),
    },
    [DateRangeEnum.THIS_YEAR]: {
      value: DateRangeEnum.THIS_YEAR,
      label: t("This year"),
      id: uuidv4(),
    },
    [DateRangeEnum.LAST_YEAR]: {
      value: DateRangeEnum.LAST_YEAR,
      label: t("Last year"),
      id: uuidv4(),
    },
    [DateRangeEnum.ALL_TIME]: {
      value: DateRangeEnum.ALL_TIME,
      label: t("All time"),
      id: uuidv4(),
    },
  };
  return customDateRanges;
};

export const createDateRangesFromCustomInterval = (
  dateRange: DateRangeEnum,
  defaultRange?: DateRangeEnum,
): { fromDate?: Date; toDate?: Date } => {
  const dateNow = new Date(Date.now());
  const intervals: {
    [key in DateRangeEnum]?: { from_date: Date; to_date: Date };
  } = {
    [DateRangeEnum.TODAY]: {
      from_date: startOfDay(dateNow),
      to_date: dateNow,
    },
    [DateRangeEnum.YESTERDAY]: {
      from_date: startOfYesterday(),
      to_date: endOfYesterday(),
    },
    [DateRangeEnum.THIS_WEEK]: {
      from_date: startOfWeek(dateNow, { weekStartsOn: 1 }),
      to_date: dateNow,
    },
    [DateRangeEnum.LAST_WEEK]: {
      from_date: startOfWeek(sub(dateNow, { weeks: 1 }), { weekStartsOn: 1 }),
      to_date: endOfWeek(sub(dateNow, { weeks: 1 }), { weekStartsOn: 1 }),
    },
    [DateRangeEnum.LAST_TWO_WEEKS]: {
      from_date: startOfWeek(sub(dateNow, { weeks: 2 }), { weekStartsOn: 1 }),
      to_date: endOfWeek(sub(dateNow, { weeks: 1 }), { weekStartsOn: 1 }),
    },
    [DateRangeEnum.THIS_MONTH]: {
      from_date: startOfMonth(dateNow),
      to_date: dateNow,
    },
    [DateRangeEnum.LAST_MONTH]: {
      from_date: startOfMonth(sub(dateNow, { months: 1 })),
      to_date: endOfMonth(sub(dateNow, { months: 1 })),
    },
    [DateRangeEnum.THIS_YEAR]: {
      from_date: startOfYear(dateNow),
      to_date: dateNow,
    },
    [DateRangeEnum.LAST_YEAR]: {
      from_date: startOfYear(sub(dateNow, { years: 1 })),
      to_date: endOfYear(sub(dateNow, { years: 1 })),
    },
    [DateRangeEnum.ALL_TIME]: {
      from_date: set(dateNow, {
        year: 1990,
        month: 0,
        date: 1,
        hours: 0,
        minutes: 0,
      }),
      to_date: dateNow,
    },
  };

  const dateRanges = intervals[dateRange ?? defaultRange];
  return {
    fromDate: dateRanges?.from_date,
    toDate: dateRanges?.to_date,
  };
};
