import React from "react";

const CheckmarkIcon = ({ stroke = "white" }: { stroke?: string }) => {
  return (
    <svg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.16797 4.72743L4.19827 6.66683L7.83464 1.3335'
        stroke={stroke}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckmarkIcon;
