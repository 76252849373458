import { isUndefined } from "lodash";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { ProductSelectionItem, useCartState } from "../state/useCartState";

/**
 * Manages the interaction between the store and user events
 *.
 You can addProduct, removeProduct, see all product in cart, open the cart sidebar
 * 
 * @returns object
 */
const useCartManager = () => {
  const { addProduct, removeProduct, products, setCartOpen, isCartOpen, clearProduct } =
    useCartState();
  const { query, replace, pathname } = useRouter();

  const removeQuery = () => {
    delete query?.["success"]; // Replace 'myQueryParam' with your actual query param

    const newUrl = {
      pathname,
      query,
    };

    replace(newUrl, newUrl, { shallow: true });
  };

  const addProductToCart = useCallback((product: ProductSelectionItem) => {
    if (!product) return;
    const add = () => {
      addProduct(product);
      removeQuery(); //Remove query that clears cart
    };

    add();
  }, []);

  const removeProductFromCart = useCallback((product: ProductSelectionItem) => {
    if (!product) return;
    removeQuery();
    removeProduct(product);
  }, []);

  const isItemAlreadyInCart = useCallback(
    (productId: string) => {
      return !isUndefined(products[productId]);
    },
    [products],
  );

  return {
    addProductToCart,
    removeProductFromCart,
    isItemAlreadyInCart,
    isCartOpen,
    emptyCart: clearProduct,
    toogleCart: (open: boolean) => setCartOpen(open),
  };
};

export default useCartManager;
