/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable-next-line */

import { ReactComponent as CaretUp } from "@gruene-brise/common-ui/assets/icons/caret-up.svg";
import { debounce } from "debounce";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CaretDown } from "../../assets/icons/caret-down-solid.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Checkbox } from "../form/Checkbox";
import styles from "./dropdown.module.css";
import { DropdownProps, OptionProps, ValueProps } from "./types";
import { Tooltip } from "@chakra-ui/react";
import { uniqWith } from "lodash";

export * from "./DropdownAccordion";
export * from "./GeneralDropdown";
export * from "./hooks";
export * from "./types";

type DropdownStyles = {
  width: {
    [key in DropdownProps<false>["size"]]: string;
  };
};
const dropdownStyles: DropdownStyles = {
  width: {
    md: "min-w-[200px]",
    sm: "min-w-[150px]",
  },
};

function areObjectsEqual(firstObject: ValueProps, secondObject: ValueProps) {
  // Implement your logic to compare objects here
  // For simplicity, let's assume objects are equal if their string representations are the same
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
}

/**
 *
 * @param size {sm | md}
 * @param isMulti {Boolean}
 * @param disabled {Boolean}
 * @param options {Array<ValueProps> | undefined}
 * @param onChange {((e: unknown) => void) | undefined}
 * @param placeholder {String}
 * @param heightClassName {String}
 * @example
 * <Dropdown
    *  size='md'
    *  options={options}
    *  isMulti
    *  isSearchable={false}
    *  onChange={handleChange}
    * heightClassName="h-[40px]"
 * />
    @description A searchable (optional) dropdown component with multichoice option.
 * @returns {JSX.Element}
 */

export const Dropdown = <T extends boolean = false>({
  size,
  isMulti,
  disabled,
  options,
  onChange,
  onMenuToggle,
  isSearchable,
  isLoading,
  heightClassName,
  value,
  containerClassName,
  menuClassName,
  buttonClassName,
  removeButtonBorder,
  name,
  handleExternalSearch,
  menuPosition = "absolute",
  placeholder = "Choose...",
  initialMenuOpen,
  ignoreClickOutside,
  onSearch,
  onEditClick,
  onDeleteOption,
  isEdittable,
  canDeleteOption,
  errors,
  externalControl,
  arrowIconUp,
  arrowIconDown,
  listItemClassName,
  valueClassName,
  hasButtonTooltip,
  buttonTooltipMessage,
  setExternalSelectedValues,
}: DropdownProps<T>): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState<OptionProps>(isMulti ? [] : null);
  const [previousOptions, setPreviousOptions] = useState<ValueProps[]>();

  useEffect(() => {
    if (externalControl) {
      setShowMenu(externalControl?.showMenu);
    }
  }, [externalControl?.showMenu]);

  useEffect(() => {
    if (initialMenuOpen) {
      handleMenuToggle();
    }
  }, [initialMenuOpen]);

  const getOptions = () => {
    if (!searchValue) {
      return options as Array<ValueProps>;
    }

    return (options as Array<ValueProps>).filter(
      (option) => option.label?.toLowerCase()?.indexOf(searchValue.toLowerCase()) >= 0,
    );
  };

  useEffect(() => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        if (searchValue) {
          const allOptions = uniqWith(
            [...(options as Array<ValueProps>), ...(previousOptions || [])],
            areObjectsEqual,
          );
          const selectedOptions = allOptions.filter((options) => value.includes(options.value));
          setSelectedValue(selectedOptions);
          return;
        }
        const selectedOptions = getOptions().filter((options) => value.includes(options.value));
        setSelectedValue(selectedOptions);
        setExternalSelectedValues?.(selectedOptions);
      } else {
        const selectedOption = getOptions().find((i) => {
          return i.value === value;
        }) as ValueProps;
        setSelectedValue(selectedOption);
        setExternalSelectedValues?.(selectedOption);
      }
    } else {
      setSelectedValue(isMulti ? [] : null);
      setExternalSelectedValues?.(isMulti ? [] : null);
    }
  }, [JSON.stringify(value), isMulti, JSON.stringify(options)]);

  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
      searchRef.current.value = "";
      setPreviousOptions(undefined);
    }
  }, [showMenu]);

  useEffect(() => {
    if (ignoreClickOutside) return;

    const handler = (e: MouseEvent) => {
      if (dropdownBtnRef.current && dropdownBtnRef.current.contains(e.target as Node)) {
        return;
      }

      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(e.target as Node)) {
        setShowMenu(false);
        externalControl?.setShowMenu?.(false);
      }
    };

    window.addEventListener("click", handler, true);
    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, [isMulti]);

  const handleSearch = (value: string) => {
    if (onSearch) {
      if (!searchValue) {
        setPreviousOptions(options);
      }
      debounce((val: string) => onSearch(val), 500)(value);
    }
    setSearchValue(value);
  };

  const selectedValueIsEmptyArray =
    isMulti && Array.isArray(selectedValue) && selectedValue.length === 0;

  const getDisplay = () => {
    if (!selectedValue || selectedValueIsEmptyArray) {
      return <span className={`text-inherit`}>{placeholder}</span>;
    }
    if (isMulti) {
      return (
        <div className='flex items-center space-x-1 text-inherit'>
          <span className='text-inherit font-normal'>{name || placeholder}</span>
          <div className='flex items-center justify-center w-[20px] h-[20px] rounded-full bg-secondary text-white font-semibold m-0 p-0'>
            {(selectedValue as Array<ValueProps>).length}
          </div>
        </div>
      );
    }
    return <span>{(selectedValue as unknown as ValueProps).label}</span>;
  };

  const getIcon = () => {
    if (showMenu) {
      if (arrowIconUp) {
        return arrowIconUp;
      } else {
        return <CaretUp title='Caret up' className='text-inherit font-bold text-base' />;
      }
    } else {
      if (arrowIconDown) {
        return arrowIconDown;
      } else {
        return <CaretDown title='Caret down' className='text-inherit' />;
      }
    }
  };

  const handleMenuToggle = () => {
    setShowMenu((prev) => {
      if (!prev) {
        onMenuToggle?.(true);
        externalControl?.setShowMenu?.(true);
      }
      externalControl?.setShowMenu?.(!prev);

      return !prev;
    });
  };

  const removeOption = (option: ValueProps) => {
    return (selectedValue as Array<ValueProps>)?.filter((o) => o.value !== option.value);
  };

  const onItemClick = (option: ValueProps) => {
    let newValue: ValueProps | ValueProps[];
    if (isMulti) {
      if ((selectedValue as Array<ValueProps>).findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...(selectedValue as Array<ValueProps>), option];
      }
    } else {
      newValue = option;
      handleMenuToggle();
    }
    setSelectedValue(newValue);
    onChange?.(newValue);
  };

  const isSelected = (option: ValueProps) => {
    if (isMulti) {
      return (
        (selectedValue as Array<ValueProps>)?.filter((o) => o.value === option.value).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return (selectedValue as ValueProps).value === option.value;
  };

  const { t } = useTranslation();

  return (
    <div className={`${dropdownStyles.width[size]} ${containerClassName ?? ""} relative`}>
      <div className='relative w-full'>
        <Tooltip
          label={buttonTooltipMessage}
          placement='top'
          hasArrow
          bg={"#121F0B"}
          isDisabled={!hasButtonTooltip}
        >
          <button
            type='button'
            data-testid='dropdown-btn'
            id='dropdown-btn'
            ref={dropdownBtnRef}
            disabled={disabled}
            onClick={handleMenuToggle}
            className={`${
              heightClassName ? heightClassName : "h-[40px]"
            } border-grey py-4 px-4 flex justify-between items-center bg-white active:bg-tertiary-25  w-full
        text-md placeholder:text-md disabled:cursor-not-allowed
         ${!removeButtonBorder ? "border-[0.5px] border-solid " : ""} ${buttonClassName ?? ""} ${
              showMenu ? "!border-[0.5px] !border-primary !border-solid" : ""
            } rounded-md sm transition ${
              (Array.isArray(selectedValue) && selectedValue.length === 0) || !selectedValue
                ? "text-grey"
                : "text-black"
            }
        `}
          >
            <div className=' flex-1 truncate flex pr-5 text-inherit'>{getDisplay()}</div>
            <div className='text-inherit mr-[1px]'>
              {isLoading && (
                <Spinner className='w-[20px] h-[20px] animate-spin text-primary-alpha' />
              )}
            </div>
            <div className='text-inherit'>{getIcon()}</div>
          </button>
        </Tooltip>
      </div>

      <ul
        ref={dropdownMenuRef}
        className={`p-0 bg-tertiary-10 mt-1.5 m-0 cursor-pointer max-h-[250px]
        text-[10px] xl:text-md lg:text-md md:text-md list-none
         w-full transition-all ${menuPosition} rounded-md z-10 border-[0.5px] border-grey border-solid ${
          !showMenu ? "hidden" : ""
        } ${menuClassName ?? ""}`}
      >
        {isSearchable && (
          <li className='relative'>
            <div className='absolute top-[50%] left-4 translate-y-[-50%]'>
              <Search className='text-grey w-[15px] h-[15px]' />
            </div>
            <input
              ref={searchRef}
              data-testid='dropdown-search'
              onChange={
                handleExternalSearch
                  ? debounce((event: any) => handleExternalSearch(event), 300)
                  : (event) => handleSearch(event.target.value)
              }
              placeholder={t("Search") as string}
              type='text'
              className='placeholder:text-grey !text-base !placeholder:text-base appearance-none outline-0 bg-primary-25 rounded-md w-full pl-11 pt-2.5 pb-[11px] px-4 border-b border-grey focus:border-none'
            />
          </li>
        )}

        {isLoading && (
          <li
            data-testid='dropdown-loading'
            className={`flex items-center justify-center ${styles["option"]}`}
          >
            <Spinner className='w-[20px] h-[20px] animate-spin text-primary-alpha' />
          </li>
        )}

        <div className='w-full overflow-auto max-h-[200px]'>
          {getOptions()?.map((option) => {
            return (
              <li
                data-testid={`option-${option.label}`}
                key={option.id || uuidv4()}
                onClick={() => {
                  if (option?.view) return;
                  onItemClick(option);
                }}
                className={`${styles["option"]} ${listItemClassName}`}
              >
                {isMulti && (
                  <div className='mr-3'>
                    <Checkbox
                      borderColorClassname='grey'
                      checked={isSelected(option)}
                      onChange={() => onItemClick(option)}
                    />
                  </div>
                )}
                <div
                  className={`
                  w-[100%] ${listItemClassName}
                    ${isSelected(option) ? "text-black" : "text-grey"}`}
                >
                  {option?.view ? (
                    <div className={"w-full flex items-start"}>{option?.view}</div>
                  ) : (
                    option.label
                  )}
                </div>
                {isEdittable && !option?.view && (
                  <EditIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onEditClick?.(option);
                    }}
                  />
                )}
                {canDeleteOption && !option?.view && (
                  <RiDeleteBin6Line
                    className='text-fail'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDeleteOption?.(option);
                    }}
                  />
                )}
              </li>
            );
          })}
        </div>
      </ul>
      {errors && (
        <span className=' text-fail text-xs w-full h-full mt-2 font-gellix'>{errors ?? ""}</span>
      )}
    </div>
  );
};

export default Dropdown;
