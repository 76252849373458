/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import environment from "@gruene-brise/data-access/config/environment";
import Head from "next/head";
import { useTranslation } from "react-i18next";

const SeoHeader = () => {
  const { t } = useTranslation();

  return (
    <Head>
      <title>{t("Bloomwell. The marketplace for Cannabis pharmacies")}</title>
      <meta
        name='description'
        content={
          t(
            "Bloomwell is the marketplace for Cannabis pharmacies. We offer a wide range of products from the best Cannabis pharmacies in Germany.",
          )!
        }
      />
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      {/* <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' /> */}
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#233918' />

      {environment.enableRobotNoIndex === "true" && <meta name='robots' content='noindex' />}

      <link rel='stylesheet' href='https://static.unzer.com/v1/unzer.css' />
      {/* <script async src='https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'></script> */}
      {/* <script async type='text/javascript' src='https://js.stripe.com/v2/'></script> */}
      <meta name='google' content='notranslate'></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1'
      ></meta>

      <script async src='https://www.googletagmanager.com/gtag/js?id=G-221B8PTJVY'></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-221B8PTJVY');
            `,
        }}
      ></script>
    </Head>
  );
};

export default SeoHeader;
