import ReactSlider, { ReactSliderProps } from "react-slider";
import cn from "classnames";

const RangeSlider = <T extends number | readonly number[]>(props: ReactSliderProps<T>) => {
  const { orientation } = props;
  const isVertical = orientation === "vertical";

  return (
    <ReactSlider
      {...props}
      renderThumb={(props, state) => (
        <div
          {...(props as any)}
          className={`
            ${isVertical ? "w-full" : "h-5 w-4.5"}
            aspect-square bg-primary-10 text-xs border-[1px] border-primary rounded-full text-white flex items-center justify-center cursor-grab
          `}
        ></div>
      )}
      renderTrack={(props, state) => {
        //check if there are multiple values
        const points = Array.isArray(state.value) ? state.value.length : null;
        const isMulti = points && points > 0;
        const isLast = isMulti ? state.index === points : state.index === 1;
        const isFirst = state.index === 0;
        return (
          <div
            {...(props as any)}
            className={cn({
              //use 1/4 height or width depending on the orientation and make sure to center it.
              "h-[3px] top-1/2 -translate-y-1/2": !isVertical,
              "w-1.5 left-1/2 -translate-x-1/2": isVertical,
              "rounded-full": true,
              "!bg-primary-50": isMulti ? isFirst || isLast : isLast,
              "bg-primary": isMulti ? !isFirst || !isLast : isFirst,
            })}
          ></div>
        );
      }}
    />
  );
};
export default RangeSlider;
